
import { useStore } from "@/store";
import { WorkerActionTypes } from "@/store/worker";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import AccountSetting from "./AccountSetting.vue";
import { AccountActionTypes } from "@/store/account";
import { useRoute } from "vue-router";

export default defineComponent({
  components: { AccountSetting },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const isShownAccountDropdown = ref(false);
    watch(
      () => route.fullPath,
      () => (isShownAccountDropdown.value = false)
    );
    onMounted(() => {
      store.dispatch(AccountActionTypes.FETCH_Account, undefined);
      store.dispatch(WorkerActionTypes.BIND_Worker, undefined);
    });

    return {
      isShownAccountDropdown,
      maxOfWorkers: computed(() => store.state.account?.maxWorkers),
      countOfWorkers: computed(
        () => store.state.workers.filter((w) => w.isVerified).length
      ),
      dismissDropdown: () => (isShownAccountDropdown.value = false),
      toggleAccountDropdown: () =>
        (isShownAccountDropdown.value = !isShownAccountDropdown.value),
    };
  },
});
