
import { AuthActionTypes } from "@/store/auth";
import {
  defineComponent,
  onMounted,
  computed,
  reactive,
  onBeforeUpdate,
  ref,
} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "../../store/index";
import { useModal } from "@/components/shared/useModal";
import logger from "@/loogger";

interface State {
  email: string;
  password: string;
  isPersistence: boolean;
}

export default defineComponent({
  setup(props, { emit }) {
    const { showModal } = useModal();
    const store = useStore();
    const router = useRouter();
    const errorMessage = ref();
    const state = reactive<State>({
      email: null,
      password: null,
      isPersistence: false,
    });

    onMounted(() => {
      // const redirectPath = store.state.redirectToPath;
      // if (redirectPath === null) {
      //   console.log("setted redirect to / in Login.vue 1", redirectPath);
      //   store.dispatch(ActionTypes.RECORD_REDIRECT_PATH, {
      //     path: "/",
      //     query: store.state.redirectQuery,
      //   });
      // }
      if (store.state.adminAuth) {
        router.replace({
          path: store.state.redirectToPath ?? "/",
          query: store.state.redirectQuery,
        });
      }
    });
    return {
      state,
      errorMessage,
      isAdminAuth: computed(() => store.state.adminAuth),
      onClickPasswordReset: () => {
        showModal("reset-password-modal");
      },
      onSubmit: async () => {
        errorMessage.value = "";
        try {
          await store.dispatch(AuthActionTypes.LOGIN, {
            email: state.email,
            password: state.password,
            isPersistence: state.isPersistence,
          });
        } catch (error) {
          logger.error("onError", error);
          errorMessage.value = "メールアドレスもしくはパスワードが一致しません";
        }
      },
    };
  },
});
