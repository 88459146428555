import { ActionTree } from "vuex";
import { database, db } from "../api/firebase";
import { WorkPlace } from "@/models/WorkPlace";
import { AugmentedActionContext } from "./types/actions";
import { State } from ".";
import { MachineActionTypes, MachineMutationTypes } from "@/store/machine";
import { Machine } from "@/models/Machine";
import firebase from "firebase";
import QuerySnapshot = firebase.firestore.QuerySnapshot;
import DocumentData = firebase.firestore.DocumentData;

export interface WorkPlaceState {
  workPlaces: WorkPlace[];
  selectedWorkPlace: WorkPlace | null;
  unsubscribeWorkPlaces: () => void | null;

  place_RelatedReportWorkingTimes: { [machineId: string]: number[] };
  place_RelatedReportIds: { [placeId: string]: string[] };
  place_RelatedMachineIds: { [placeId: string]: string[] };
  place_RelatedWorkTypeIds: { [placeId: string]: string[] };
}

/** Mutations */

export enum WorkPlaceMutationTypes {
  SET_WORK_PLACE = "WorkPlaceMutationTypes/SET_WORK_PLACE",
  SET_SELECT_WORK_PLACE = "WorkPlaceMutationTypes/SET_SELECT_WORK_PLACE",
  SET_UN_SUBSCRIBE = "WorkPlaceMutationTypes/SET_UN_SUBSCRIBE",
}
export type WorkPlaceMutations<S = State> = {
  [WorkPlaceMutationTypes.SET_WORK_PLACE](
    state: S,
    payload: { workPlaces: WorkPlace[] }
  ): void;
  [WorkPlaceMutationTypes.SET_SELECT_WORK_PLACE](
    state: S,
    payload: { workPlace: WorkPlace | null }
  ): void;
  [WorkPlaceMutationTypes.SET_UN_SUBSCRIBE](
    state: S,
    payload: { unSubscribe: () => void }
  ): void;
};

export const workPlaceMutations: WorkPlaceMutations = {
  [WorkPlaceMutationTypes.SET_WORK_PLACE](state, { workPlaces }): void {
    state.workPlaces = workPlaces;
  },
  [WorkPlaceMutationTypes.SET_SELECT_WORK_PLACE](state, { workPlace }): void {
    state.selectedWorkPlace = workPlace;
  },
  [WorkPlaceMutationTypes.SET_UN_SUBSCRIBE](state, { unSubscribe }) {
    state.unsubscribeWorkPlaces = unSubscribe;
  },
};

/** Actions  */

export enum WorkPlaceActionTypes {
  BIND_WorkPlace = "WorkPlace/BIND_WorkPlace",
  FETCH_WorkPlace = "WorkPlace/FETCH_WorkPlace",
  CREATE_WorkPlace = "WorkPlace/CREATE_WorkPlace",
  SELECT_WorkPlace = "WorkPlace/SELECT_WorkPlace",
  DE_SELECT_WorkPlace = "WorkPlace/DE_SELECT_WorkPlace",
  CHANGE_NAME_WorkPlace = "WorkPlace/CHANGE_NAME_WorkPlace",
  CLOSE_WorkPlace = "WorkPlace/CLOSE_WorkPlace",
  FETCH_Statistics_Place_Report = "WorkPlace/FETCH_Statistics_Place_Report",
  FETCH_Statistics_Place_Machine = "WorkPlace/FETCH_Statistics_Place_Machine",
  FETCH_Statistics_Place_WorkType = "WorkPlace/FETCH_Statistics_Place_WorkType",
}

export interface WorkPlaceActions {
  [WorkPlaceActionTypes.BIND_WorkPlace](
    { commit }: AugmentedActionContext,
    payload: { isFetchRelatedStat } | undefined
  ): Promise<void>;
  [WorkPlaceActionTypes.FETCH_WorkPlace]({
    commit,
  }: AugmentedActionContext): Promise<void>;
  [WorkPlaceActionTypes.CREATE_WorkPlace](
    { commit }: AugmentedActionContext,
    payload: { workPlaceName: string }
  ): Promise<void>;
  [WorkPlaceActionTypes.SELECT_WorkPlace](
    { commit }: AugmentedActionContext,
    payload: { workPlace: WorkPlace }
  ): void;
  [WorkPlaceActionTypes.DE_SELECT_WorkPlace]({
    commit,
  }: AugmentedActionContext): void;

  [WorkPlaceActionTypes.FETCH_Statistics_Place_Report](
    { commit }: AugmentedActionContext,
    payload: { placeId: string }
  ): void;
  [WorkPlaceActionTypes.FETCH_Statistics_Place_Machine](
    { commit }: AugmentedActionContext,
    payload: { placeId: string }
  ): void;
  [WorkPlaceActionTypes.FETCH_Statistics_Place_WorkType](
    { commit }: AugmentedActionContext,
    payload: { placeId: string }
  ): void;
  [WorkPlaceActionTypes.CHANGE_NAME_WorkPlace](
    { commit }: AugmentedActionContext,
    payload: { placeId: string; newName: string }
  ): Promise<WorkPlace>;
  [WorkPlaceActionTypes.CLOSE_WorkPlace](
    { commit }: AugmentedActionContext,
    payload: { placeId: string }
  ): Promise<void>;
}

const obj2keyArray = (obj: any): string[] => {
  const array: string[] = [];
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== null && obj[key] !== undefined) array.push(key);
  });
  return array;
};

const obj2valueArray = (obj: any): number[] => {
  const array: number[] = [];
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== null && obj[key] !== undefined)
      array.push(Number.parseFloat(obj[key]));
  });
  return array;
};

const Dao = {
  findPlace: (
    organizationId: string,
    newName: string
  ): Promise<QuerySnapshot<DocumentData>> =>
    db
      .collection(`organizations/${organizationId}/workplaces`)
      .where("name", "==", newName)
      .get({ source: "server" }),
};

export const workPlaceAction: ActionTree<State, State> & WorkPlaceActions = {
  async [WorkPlaceActionTypes.BIND_WorkPlace](
    { state, commit, dispatch },
    payload
  ) {
    const organizationId = state.organizationId;
    const fetchStat = (newPlaces: WorkPlace[]): void => {
      newPlaces.forEach((m) => {
        const placeId: string = m.id;
        dispatch(WorkPlaceActionTypes.FETCH_Statistics_Place_Report, {
          placeId,
        });
        dispatch(WorkPlaceActionTypes.FETCH_Statistics_Place_Machine, {
          placeId,
        });
        dispatch(WorkPlaceActionTypes.FETCH_Statistics_Place_WorkType, {
          placeId,
        });
      });
    };
    if (!organizationId) return;
    if (state.unsubscribeWorkPlaces) {
      if (payload?.isFetchRelatedStat === true) fetchStat(state.machines);
      return;
    }
    const unSubscribe = db
      .collection("organizations")
      .doc(organizationId)
      .collection("workplaces")
      .orderBy("name")
      .onSnapshot((result) => {
        const newWorkPlaces = result.docs.map(
          (d) => new WorkPlace(d.id, d.data())
        );
        fetchStat(newWorkPlaces);
        commit(WorkPlaceMutationTypes.SET_WORK_PLACE, {
          workPlaces: newWorkPlaces,
        });
      });
    commit(WorkPlaceMutationTypes.SET_UN_SUBSCRIBE, { unSubscribe });
  },
  async [WorkPlaceActionTypes.FETCH_WorkPlace]({ state, commit }) {
    const organizationId = state.organizationId;
    if (!organizationId) return;
    return db
      .collection("organizations")
      .doc(organizationId)
      .collection("workplaces")
      .orderBy("name")
      .get()
      .then((result) => {
        const newWorkPlaces = result.docs.map(
          (d) => new WorkPlace(d.id, d.data())
        );
        commit(WorkPlaceMutationTypes.SET_WORK_PLACE, {
          workPlaces: newWorkPlaces,
        });
      });
  },
  async [WorkPlaceActionTypes.CREATE_WorkPlace](
    { state, commit },
    { workPlaceName }
  ) {
    const organizationId = state.organizationId;
    if (!organizationId) return;
    if (!workPlaceName) return;

    if (state.workPlaces.some((m) => m.name === workPlaceName))
      return Promise.reject("duplicated.name");

    const existsPlace = await Dao.findPlace(organizationId, workPlaceName);
    if (existsPlace.size !== 0) return Promise.reject("duplicated.name");

    const collection = db
      .collection("organizations")
      .doc(organizationId)
      .collection("workplaces");
    const tmpId = collection.doc().id;
    const newWorkPlace = { id: tmpId, name: workPlaceName };
    return collection.doc(tmpId).set(newWorkPlace, { merge: true });
  },
  [WorkPlaceActionTypes.SELECT_WorkPlace]({ commit }, { workPlace }) {
    commit(WorkPlaceMutationTypes.SET_SELECT_WORK_PLACE, { workPlace });
  },
  [WorkPlaceActionTypes.DE_SELECT_WorkPlace]({ commit }) {
    commit(WorkPlaceMutationTypes.SET_SELECT_WORK_PLACE, { workPlace: null });
  },
  async [WorkPlaceActionTypes.CHANGE_NAME_WorkPlace](
    { state, commit },
    { placeId, newName }
  ): Promise<WorkPlace> {
    const organizationId = state.organizationId;
    if (!organizationId) return;
    if (state.workPlaces.some((m) => m.name === newName && m.id !== placeId))
      return Promise.reject("duplicated.name");
    const existsPlace = (
      await Dao.findPlace(organizationId, newName)
    ).docs.filter((d) => d.id !== placeId);
    if (existsPlace.length > 0) {
      return Promise.reject("duplicated.name");
    } else {
      await db
        .doc(`organizations/${organizationId}/workplaces/${placeId}`)
        .set({ name: newName }, { merge: true });
      return new WorkPlace(placeId, { name: newName });
    }
  },
  async [WorkPlaceActionTypes.CLOSE_WorkPlace](
    { state, commit },
    { placeId }
  ): Promise<void> {
    const organizationId = state.organizationId;
    if (!organizationId) return;
    await db
      .doc(`organizations/${organizationId}/workplaces/${placeId}`)
      .set({ closed: true }, { merge: true });
    return;
  },
  [WorkPlaceActionTypes.FETCH_Statistics_Place_Report](
    { commit, state },
    { placeId }
  ) {
    if (!state.organizationId) return;
    database
      .ref(`place/${state.organizationId}/${placeId}/reports`)
      .once("value")
      .then((snapshot): void => {
        state.place_RelatedReportIds[placeId] = obj2keyArray(
          snapshot.val() ?? {}
        );
        state.place_RelatedReportWorkingTimes[placeId] = obj2valueArray(
          snapshot.val() ?? {}
        );
      });
  },
  [WorkPlaceActionTypes.FETCH_Statistics_Place_Machine](
    { commit, state },
    { placeId }
  ) {
    if (!state.organizationId) return;
    database
      .ref(`place/${state.organizationId}/${placeId}/machines`)
      .once("value")
      .then((snapshot): void => {
        state.place_RelatedMachineIds[placeId] = obj2keyArray(
          snapshot.val() ?? {}
        );
      });
  },
  [WorkPlaceActionTypes.FETCH_Statistics_Place_WorkType](
    { commit, state },
    { placeId }
  ) {
    if (!state.organizationId) return;
    database
      .ref(`place/${state.organizationId}/${placeId}/workTypes`)
      .once("value")
      .then((snapshot): void => {
        state.place_RelatedWorkTypeIds[placeId] = obj2keyArray(
          snapshot.val() ?? {}
        );
      });
  },
};
