
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "@/store";
import WorkPlace from "./WorkPlace.vue";
import WorkType from "./WorkType.vue";
import Machine from "./Machine.vue";
import { useRoute, useRouter } from "vue-router";
import CreateWorkManagementForm from "@/components/management/CreateWorkManagementForm.vue";
import { WorkTypeActionTypes } from "@/store/workType";
import { WorkPlaceActionTypes } from "@/store/workplace";
import { MachineActionTypes } from "@/store/machine";

export default defineComponent({
  components: { CreateWorkManagementForm, WorkPlace, Machine, WorkType },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const tabItems = computed(() => [
      {
        id: "places",
        label: "現場別",
        value: store.state.workPlaces.length,
        suffix: "箇所",
        component: "work-place",
      },
      {
        id: "machines",
        label: "機材別",
        value: store.state.machines.length,
        suffix: "種類",
        component: "machine",
      },
      {
        id: "types",
        label: "作業種別",
        value: store.state.workTypes.length,
        suffix: "項目",
        component: "work-type",
      },
    ]);
    const activeTab = ref(0);
    onMounted(() => {
      store.dispatch(WorkTypeActionTypes.BIND_WorkType, undefined);
      store.dispatch(WorkPlaceActionTypes.BIND_WorkPlace, undefined);
      store.dispatch(MachineActionTypes.BIND_Machine, undefined);
      const tabId: string = route.params.tabId as string;
      const foundTabItem = tabItems.value.map((i) => i.id).indexOf(tabId);
      activeTab.value = foundTabItem;
    });
    watch(
      () => route.params,
      (newParams) => {
        const tabId: string = newParams.tabId as string;
        const foundTabItem = tabItems.value.map((i) => i.id).indexOf(tabId);
        activeTab.value = foundTabItem;
      }
    );
    return {
      tabItems,
      isActive: (index: number) => index === activeTab.value,
      select: (index: number) =>
        router.replace(`/work/${tabItems.value[index].id}`),
      selectedComponent: computed(
        () => tabItems.value[activeTab.value].component
      ),
    };
  },
});
