import formatDate from "@/components/shared/DateFormatter";
import { Report } from "./Report";

export class ReportCsvRow {
  id: string;
  workedDate: string;
  weather: string;
  reporter: string;
  workPlaceName: string;
  workTypeName: string;
  workOutPut: number | null;
  spendTime: string;
  machines: string | null;
  workers: string | null;
  memo: string | null;

  constructor(obj: Report) {
    this.id = obj.reportId;
    this.workedDate = formatDate(obj.workedDate, "YYYY/MM/DD");
    this.weather = obj.weatherAsJapanese;
    this.reporter = obj.workerName;
    this.workPlaceName = obj.workPlace.name;
    this.workTypeName = obj.workOutPut.workType.name;
    this.workOutPut = obj.workOutPut.outcome ?? null;
    this.spendTime = obj.workingTime;
    this.machines = obj.machines?.map((m) => m.name)?.join(",") ?? null;
    this.workers = obj.workers?.map((w) => w.name)?.join(",") ?? null;
    this.memo = obj.body ?? null;
    // this.memo = obj.body ? escapeForCSV(obj.body) : null;
  }
}
