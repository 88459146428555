<template>
  <ul class="dropdown-list">
    <li>
      <a href="#" class="openMenu" v-on:click.prevent.stop="onClickContract">
        契約内容表示
      </a>
    </li>
    <li>
      <a href="#" v-on:click.prevent.stop="onClickSupport">サポート案内</a>
    </li>
    <li>
      <a href="#" v-on:click.prevent.stop="onClickTerms">規約</a>
    </li>
    <li>
      <a href="#" v-on:click.prevent.stop="onClickContact">お問い合わせ</a>
    </li>
    <li>
      <a href="#" class="outlink" v-on:click.prevent.stop="onClickCompany">
        運営会社
      </a>
    </li>
    <li>
      <a href="#" class="alert-link" v-on:click.prevent.stop="onClickLogout">
        ログアウト
      </a>
    </li>
  </ul>
</template>

<script lang="ts">
import { useStore } from "@/store";
import { useModal } from "../shared/useModal";
import { WorkerActionTypes } from "@/store/worker";
import { defineComponent, onBeforeUpdate, onMounted, watch } from "vue";
import { AuthActionTypes } from "@/store/auth";

export default defineComponent({
  components: {},
  props: {
    dismiss: {
      type: Function,
    },
  },
  setup(props) {
    const store = useStore();
    const { showModal, hideModal, isVisible } = useModal();
    onBeforeUpdate(() => {});
    onMounted(() => {
      store.dispatch(WorkerActionTypes.BIND_Worker, undefined);
    });
    watch(isVisible, (newValue) => {
      if (!newValue) props.dismiss();
    });
    return {
      hideModal: () => hideModal(),
      onClickContract: () => showModal("account-contract-modal"),
      onClickSupport: () => showModal("account-support-modal"),
      onClickTerms: () => showModal("account-terms-modal"),
      onClickContact: () => showModal("account-contact-modal"),
      onClickCompany: () => window.open("https://www.hyakumori.com/"), //showModal("account-company-modal"),
      onClickLogout: () => store.dispatch(AuthActionTypes.LOGOUT, undefined),
    };
  },
});
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
