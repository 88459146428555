<template>
  <span class="container" id="dashbord">
    <header>
      <section class="content-title">
        <h1>ダッシュボード</h1>
      </section>
    </header>

    <aside>
      <section class="aside-head">
        <div class="aside-inner">
          <h2>稼働状況</h2>
          <!--          <p>2021/02/12 〜 2021/02/12</p>-->
        </div>
      </section>

      <ul class="aside-summary">
        <li>
          <h3>累計日報投稿数</h3>
          <p>
            <span>{{ totalReportCount }}</span>
            投稿
          </p>
        </li>
        <li>
          <h3>累計稼働時間</h3>
          <p>
            <span>{{ totalWorkingTime }}</span>
            時間
          </p>
        </li>
        <li>
          <h3>稼働人員数</h3>
          <p>
            <span>45</span>
            人
          </p>
        </li>
        <li>
          <h3>天候</h3>
          <p>
            <span>
              <img src="../../assets/img/icon-sun.svg" class="mr-10" />
              {{ sunnyPercentage }}
            </span>
            %
            <span>
              <img src="../../assets/img/icon-cloud.svg" class="mr-10" />
              {{ cloudyPercentage }}
            </span>
            %
            <span>
              <img src="../../assets/img/icon-rain.svg" class="mr-10" />
              {{ rainyPercentage }}
            </span>
            %
            <span>
              <img src="../../assets/img/icon-snow.svg" class="mr-10" />
              {{ snowPercentage }}
            </span>
            %
          </p>
        </li>
      </ul>
      <section class="aside-head">
        <div class="aside-inner">
          <h2>登録状況一覧</h2>
        </div>
      </section>
      <ul class="aside-summary">
        <li>
          <h3>作業現場</h3>
          <p>
            <span>{{ workPlaceCount }}</span>
            種類
          </p>
        </li>
        <li>
          <h3>登録機材</h3>
          <p>
            <span>{{ machineCount }}</span>
            種類
          </p>
        </li>
        <li>
          <h3>登録作業</h3>
          <p>
            <span>{{ workTypeCount }}</span>
            種類
          </p>
        </li>
        <li>
          <h3>作業員数</h3>
          <p>
            <a
              href="#"
              class="alert-link"
              v-on:click.prevent.stop="pushMemberPage"
              v-show="existsWaitingValidWorker"
            >
              承認待ちの作業員が {{ waitingValidWorkersCount }} 名います！
            </a>
            <span>{{ verifiedWorkersCount }}</span>
            名
          </p>
        </li>
      </ul>
    </aside>
    <main>
      <div class="main-inner">
        <h2 class="sub-title">新着日報一覧</h2>
        <table class="data-table" cellspacing="0" cellpadding="0">
          <thead>
            <tr>
              <th>日付</th>
              <th>報告者</th>
              <th>現場</th>
              <th>使用機材</th>
              <th>作業種別</th>
              <th class="cell-nw">詳細</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in reports" :key="item.reportId">
              <td>{{ format(item.workedDate) }}</td>
              <td>{{ resolveWorkerNameAndUpdateIfNeeded(item) }}</td>
              <td>{{ item.workPlace.name }}</td>
              <td>
                {{ item.machines?.map((m) => m.name).join(",") ?? "未登録" }}
              </td>
              <td>{{ item.workOutPut.workType?.name }}</td>
              <td class="cell-nw">
                <a
                  href="#"
                  class="detail-link"
                  v-on:click.prevent.stop="onClickReportDetail(item)"
                >
                  詳細
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </span>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType } from "vue";
import { Report } from "@/models/Report";
import formatDate from "../shared/DateFormatter";
import { useStore } from "@/store";
import { ReportActionTypes } from "@/store/report";
import { WorkerActionTypes } from "@/store/worker";
import { WorkTypeActionTypes } from "@/store/workType";
import { WorkPlaceActionTypes } from "@/store/workplace";
import { MachineActionTypes } from "@/store/machine";
import { useRouter } from "vue-router";
import { StatisticsActionTypes } from "@/store/statistics";

export default defineComponent({
  // TODO: ダミーデータを埋める
  props: {
    // reports: {
    // type : Array as PropType<Report[]>,
    // }
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const totalWeatherCount = computed(
      () =>
        (store.state.snowDaysCount +
          store.state.rainyDaysCount +
          store.state.sunnyDaysCount +
          store.state.cloudyDaysCount) /
        100
    );
    const changeCoWorkersNameIfNeeded = (report: Report): string => {
      if (!report.workers) return;
      report.workers.forEach((worker) => {
        const recordedName = worker.name;
        const collectName = store.state.workers.find(
          (w) => w.uid === worker.uid
        )?.name;
        if (collectName && recordedName !== collectName) {
          store.dispatch(ReportActionTypes.CHANGE_CO_WORKER_NAME, {
            report,
            workerId: worker.uid,
            workerName: collectName,
          });
        }
      });
    };

    onMounted(() => {
      store.dispatch(ReportActionTypes.BIND_Report, undefined);
      store.dispatch(WorkerActionTypes.BIND_Worker, undefined);
      store.dispatch(WorkerActionTypes.BIND_PreWorker, undefined);
      store.dispatch(WorkTypeActionTypes.BIND_WorkType, undefined);
      store.dispatch(WorkPlaceActionTypes.BIND_WorkPlace, undefined);
      store.dispatch(MachineActionTypes.BIND_Machine, undefined);
      store.dispatch(StatisticsActionTypes.BIND_Statistics, undefined);
    });
    return {
      reports: computed(() => store.state.reports.slice(0, 10)),
      totalReportCount: computed(() => store.state.totalReportCount),
      totalWorkingTime: computed(() => store.state.totalWorkingTime),
      workPlaceCount: computed(() => store.state.workPlaces.length),
      machineCount: computed(() => store.state.machines.length),
      workTypeCount: computed(() => store.state.workTypes.length),

      snowPercentage: computed(() =>
        Math.round(
          store.state.snowDaysCount
            ? store.state.snowDaysCount / totalWeatherCount.value
            : 0
        )
      ),
      rainyPercentage: computed(() =>
        Math.round(
          store.state.rainyDaysCount
            ? store.state.rainyDaysCount / totalWeatherCount.value
            : 0
        )
      ),
      sunnyPercentage: computed(() =>
        Math.round(
          store.state.sunnyDaysCount
            ? store.state.sunnyDaysCount / totalWeatherCount.value
            : 0
        )
      ),
      cloudyPercentage: computed(() =>
        Math.round(
          store.state.cloudyDaysCount
            ? store.state.cloudyDaysCount / totalWeatherCount.value
            : 0
        )
      ),

      verifiedWorkersCount: computed(
        () => store.state.workers.filter((w) => w.isVerified).length
      ),
      waitingValidWorkersCount: computed(() => store.state.preWorkers.length),
      existsWaitingValidWorker: computed(
        () => store.state.preWorkers.length > 0
      ),
      format: (date: Date) => formatDate(date, "YYYY/MM/DD"),
      resolveWorkerNameAndUpdateIfNeeded: (report: Report): string => {
        const recordedName = report.workerName;
        const collectName = store.state.workers.find(
          (w) => w.uid === report.workerId
        )?.name;
        if (collectName && recordedName !== collectName) {
          store.dispatch(ReportActionTypes.CHANGE_WORKER_NAME, {
            reportId: report.reportId,
            workerName: collectName,
          });
        }
        changeCoWorkersNameIfNeeded(report);
        return collectName ?? recordedName;
      },
      pushMemberPage: () => router.push("/members"),
      onClickReportDetail: (report: Report) =>
        store.dispatch(ReportActionTypes.SELECT_REPORT, { report: report }),
    };
  },
});
</script>
