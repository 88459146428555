import { ActionTree } from "vuex";
import { database, db } from "../api/firebase";
import { AugmentedActionContext } from "./types/actions";
import { State } from ".";

export interface StatisticsState {
  totalReportCount: number;
  unSubscribeTotalReportCount: () => void | null;

  totalWorkingTime: number;
  unSubscribeTotalWorkingTime: () => void | null;

  snowDaysCount: number;
  unSubscribeSnowDaysCount: () => void | null;
  rainyDaysCount: number;
  unSubscribeRainyDaysCount: () => void | null;
  sunnyDaysCount: number;
  unSubscribeSunnyDaysCount: () => void | null;
  cloudyDaysCount: number;
  unSubscribeCloudyDaysCount: () => void | null;

  unsubscribeStatistics: () => void | null;
}

/** Mutations */

export enum StatisticsMutationTypes {
  SET_TOTAL_REPORT_COUNT = "StatisticsMutationTypes/SET_TOTAL_REPORT_COUNT",
}

export type StatisticsMutations<S = State> = {
  [StatisticsMutationTypes.SET_TOTAL_REPORT_COUNT](
    state: S,
    payload: { totalReportCount: number | null }
  ): void;
};

export const statisticsMutation: StatisticsMutations = {
  [StatisticsMutationTypes.SET_TOTAL_REPORT_COUNT](
    state,
    { totalReportCount }
  ): void {
    state.totalReportCount = totalReportCount ?? 0;
  },
};

/** Actions  */

export enum StatisticsActionTypes {
  BIND_Statistics = "StatisticsActionTypes/BIND_Statistics",
  FETCH_Statistics = "StatisticsActionTypes/FETCH_Statistics",
}

export interface StatisticsActions {
  [StatisticsActionTypes.BIND_Statistics]({
    commit,
  }: AugmentedActionContext): void;
  [StatisticsActionTypes.FETCH_Statistics]({
    commit,
  }: AugmentedActionContext): void;
}

export const statisticsAction: ActionTree<State, State> & StatisticsActions = {
  async [StatisticsActionTypes.BIND_Statistics]({ commit, state }) {
    if (!state.organizationId) return;
    const onValue = (
      path: string,
      onFetch: (value: number) => void
    ): (() => void) => {
      const ref = database.ref(path);
      ref.on("value", (snapshot) => {
        onFetch(snapshot.val() ?? 0);
      });
      return () => {
        ref.off("value");
      };
    };

    if (!state.unSubscribeTotalReportCount) {
      state.unSubscribeTotalReportCount = onValue(
        `report/${state.organizationId}/sum`,
        (value) => (state.totalReportCount = value)
      );
    }
    if (!state.unSubscribeTotalWorkingTime) {
      state.unSubscribeTotalReportCount = onValue(
        `workingTime/${state.organizationId}/sum`,
        (value) => (state.totalWorkingTime = value)
      );
    }

    // 天気 - 雪
    if (!state.unSubscribeSnowDaysCount) {
      state.unSubscribeSnowDaysCount = onValue(
        `weather/${state.organizationId}/snow/sum`,
        (value) => (state.snowDaysCount = value)
      );
    }
    // 天気 - 雨
    if (!state.unSubscribeRainyDaysCount) {
      state.unSubscribeRainyDaysCount = onValue(
        `weather/${state.organizationId}/rainy/sum`,
        (value) => (state.rainyDaysCount = value)
      );
    }
    // 天気 - はれ
    if (!state.unSubscribeSunnyDaysCount) {
      state.unSubscribeSunnyDaysCount = onValue(
        `weather/${state.organizationId}/sunny/sum`,
        (value) => (state.sunnyDaysCount = value)
      );
    }
    // 天気 - 雪
    if (!state.unSubscribeCloudyDaysCount) {
      state.unSubscribeCloudyDaysCount = onValue(
        `weather/${state.organizationId}/cloudy/sum`,
        (value) => (state.cloudyDaysCount = value)
      );
    }
  },
  [StatisticsActionTypes.FETCH_Statistics]({ commit, state }) {
    if (!state.organizationId) return;
    const once = (path: string, onSuccess: (val: number) => void): any =>
      database
        .ref(path)
        .once("value", (snapshot) => onSuccess(snapshot.val() ?? 0))
        .finally();

    once(
      `report/${state.organizationId}/sum`,
      (value) => (state.totalReportCount = value)
    );
    once(
      `workingTime/${state.organizationId}/sum`,
      (value) => (state.totalWorkingTime = value)
    );

    // 天気 - 雪
    once(
      `weather/${state.organizationId}/snow/sum`,
      (value) => (state.snowDaysCount = value)
    );
    // 天気 - 雨
    once(
      `weather/${state.organizationId}/rainy/sum`,
      (value) => (state.rainyDaysCount = value)
    );
    // 天気 - はれ
    once(
      `weather/${state.organizationId}/sunny/sum`,
      (value) => (state.sunnyDaysCount = value)
    );
    // 天気 - 雪
    once(
      `weather/${state.organizationId}/cloudy/sum`,
      (value) => (state.cloudyDaysCount = value)
    );
  },
};
