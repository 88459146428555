<template>
  <div id="closeModal" class="closeIconModal closeModal" v-on:click="hideModal">
    ×
  </div>
  <div class="modalContents" v-if="!isChangePasswordMode">
    <h3>契約内容</h3>
    <ul class="form-area">
      <li>
        <span>組織ID：</span>
        <div class="body-inline">{{ organizationId }}</div>
      </li>
      <li>
        <span>組織名：</span>
        <div class="body-inline">{{ organizationName }}</div>
      </li>
      <li>
        <span>管理アカウント：</span>
        <div class="body-inline">{{ loginId }}</div>
      </li>
      <li>
        <span>契約期限：</span>
        <div class="body-inline">{{ expiredDate }}</div>
      </li>
      <li>
        <span>作業員の登録上限：</span>
        <div class="body-inline">{{ maxWorkers }}</div>
      </li>
      <li>
        <span>パスワード：</span>
        <div class="body-inline">*******</div>
      </li>
    </ul>
    <button
      class="closeModal mt-40 submit-button"
      @click="toChangePasswordMode"
    >
      パスワードを変更する
    </button>
  </div>

  <div class="modalContents" v-else>
    <h3>パスワード変更</h3>

    <form @submit.prevent="onClickSubmit">
      <div class="password-changer">
        <div class="password-changer-item">
          <span>現在のパスワード：</span>
          <input
            type="password"
            class="input-long"
            id="currentPassword"
            v-model="form.currentPassword"
            required
          />
        </div>
        <div class="password-changer-item">
          <span>新パスワード：</span>
          <input
            type="password"
            class="input-long"
            id="password"
            v-model="form.password"
            required
          />
        </div>
        <div class="password-changer-item">
          <span>新パスワード（確認）：</span>
          <input
            type="password"
            class="input-long"
            id="passwordConfirmed"
            v-model="form.passwordConfirmed"
            required
          />
        </div>
        <div class="form-error">{{ formErrorMessage }}</div>
        <button type="submit" class="closeModal mt-40 submit-button">
          パスワードを変更する
        </button>
        <div class="light-text">
          パスワードを忘れた場合は、
          <br />
          <a
            href="#"
            class="openMenu"
            v-on:click.prevent.stop="onClickPasswordReset"
          >
            パスワードリセット
          </a>
          が可能です。
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { useStore } from "@/store";
import { useModal } from "../shared/useModal";
import { computed, defineComponent, onMounted, ref } from "vue";
import { AccountActionTypes } from "@/store/account";
import formatDate from "../shared/DateFormatter";
import { AuthActionTypes } from "@/store/auth";

export default defineComponent({
  setup(props) {
    const store = useStore();
    const { hideModal, showModal } = useModal();
    const isChangePasswordMode = ref(false);
    const formErrorMessage = ref<string>();
    const form = ref({
      currentPassword: null,
      password: null,
      passwordConfirmed: null,
    });
    const checkEqualPassword = (
      newPass: string | null,
      confirm: string | null
    ): boolean => {
      if (newPass === null || newPass.length < 1) return false;
      if (confirm === null || confirm.length < 1) return false;
      return newPass === confirm;
    };
    onMounted(async () => {
      await store.dispatch(AccountActionTypes.FETCH_Account, undefined);
    });
    return {
      form,
      formErrorMessage,
      isChangePasswordMode,
      organizationId: computed(() => store.state.organizationId),
      organizationName: computed(() => store.state.account?.organizationName),
      expiredDate: computed(() =>
        formatDate(store.state.account?.expiredDate, "YYYY/MM/DD")
      ),
      loginId: computed(() => store.state.account?.loginId),
      maxWorkers: computed(() => store.state.account?.maxWorkers),
      toChangePasswordMode: () => (isChangePasswordMode.value = true),
      onClickPasswordReset: () => showModal("reset-password-modal"),
      onClickSubmit: () => {
        const currentPassword = form.value.currentPassword;
        const newPassword = form.value.password;
        if (
          checkEqualPassword(form.value.password, form.value.passwordConfirmed)
        ) {
          store
            .dispatch(AuthActionTypes.CHANGE_PASSWORD, {
              currentPassword,
              newPassword,
            })
            .then(() => {
              formErrorMessage.value = null;
              hideModal();
            })
            .catch((error) => {
              if (error.code === "auth/wrong-password") {
                formErrorMessage.value = "現在のパスワードが間違っています";
              } else {
                formErrorMessage.value = "パスワード再設定に失敗しました";
              }
            });
        } else {
          formErrorMessage.value = "確認用パスワードが一致しません";
        }
      },
      hideModal: () => hideModal(),
    };
  },
});
</script>
