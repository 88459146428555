import firebase from "firebase";
import { ActionTree } from "vuex";
import { AugmentedActionContext } from "./types/actions";
import { MutationTypes, State } from ".";
import { auth } from "@/api/firebase";
import logger from "@/loogger";

export interface AuthState {
  // user: firebase.User | null;
  currentUser: firebase.User | null;
  organizationId: string;
  adminAuth: boolean;
  unsubscribeUser: () => void | null;
}

const authGetters = {
  isAuth(state: AuthState) {
    return state.currentUser?.isAnonymous;
  },
};

export enum AuthMutationTypes {
  CLEAR = "AuthMutationTypes/CLEAR",
  SET_CURRENT_USER = "AuthMutationTypes/SET_CURRENT_USER",
  SET_ORGANIZATION_ID = "AuthMutationTypes/SET_ORGANIZATION_ID",
  SET_ADMIN_AUTH = "AuthMutationTypes/SET_ADMIN_AUTH",
  SET_UN_SUBSCRIBE = "AuthMutationTypes/SET_UN_SUBSCRIBE",
}

export const authMutations: AuthMutations = {
  [AuthMutationTypes.CLEAR](state) {
    state.currentUser = null;
    state.adminAuth = false;
    state.organizationId = null;
  },
  [AuthMutationTypes.SET_CURRENT_USER](state, { user }) {
    state.currentUser = user;
  },
  [AuthMutationTypes.SET_ADMIN_AUTH](state, { adminAuth }) {
    state.adminAuth = adminAuth;
  },
  [AuthMutationTypes.SET_ORGANIZATION_ID](state, { organizationId }) {
    state.organizationId = organizationId;
  },
  [AuthMutationTypes.SET_UN_SUBSCRIBE](state, { unSubscribe }) {
    state.unsubscribeUser = unSubscribe;
  },
};

export type AuthMutations<S = State> = {
  [AuthMutationTypes.CLEAR](state: S): void;
  [AuthMutationTypes.SET_CURRENT_USER](
    state: S,
    payload: { user: firebase.User }
  ): void;
  [AuthMutationTypes.SET_ADMIN_AUTH](
    state: S,
    payload: { adminAuth: boolean }
  ): void;
  [AuthMutationTypes.SET_ORGANIZATION_ID](
    state: S,
    payload: { organizationId: string | null }
  ): void;
  [AuthMutationTypes.SET_UN_SUBSCRIBE](
    state: S,
    payload: { unSubscribe: () => void }
  ): void;
};

export enum AuthActionTypes {
  LOGIN = "AUTH/LOGIN",
  LOGOUT = "AUTH/LOGOUT",
  REFRESH_ADMIN_AUTH = "AUTH/REFRESH_ADMIN_AUTH",
  BIND_AUTH_SUBSCRIBE = "AUTH/BIND_AUTH_SUBSCRIBE",
  CHANGE_PASSWORD = "AUTH/CHANGE_PASSWORD",
  RESET_PASSWORD = "AuthActionTypes/RESET_PASSWORD",
}

export interface AuthActions {
  [AuthActionTypes.LOGIN](
    { commit }: AugmentedActionContext,
    payload: { email: string; password: string; isPersistence: boolean }
  ): Promise<void>;
  [AuthActionTypes.LOGOUT]({ commit }: AugmentedActionContext): void;
  [AuthActionTypes.REFRESH_ADMIN_AUTH]({
    commit,
  }: AugmentedActionContext): Promise<boolean>;
  [AuthActionTypes.BIND_AUTH_SUBSCRIBE]({
    commit,
  }: AugmentedActionContext): Promise<void>;
  [AuthActionTypes.CHANGE_PASSWORD](
    { commit }: AugmentedActionContext,
    payload: { currentPassword: string; newPassword: string }
  ): Promise<void>;
  [AuthActionTypes.RESET_PASSWORD](
    { commit }: AugmentedActionContext,
    payload: { email: string | undefined | null }
  ): Promise<void>;
}

export const authAction: ActionTree<State, State> & AuthActions = {
  async [AuthActionTypes.LOGIN](
    { state, commit },
    { email, password, isPersistence }
  ) {
    try {
      if (isPersistence) {
        await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      } else {
        await auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
      }
      const userCredential = await auth
        .signInWithEmailAndPassword(email, password)
        .catch((error) => {
          return null;
        });
      const user = userCredential?.user;
      logger.log("userCredential", userCredential);
      if (!user) {
        logger.error("onError", "login failed");
        return Promise.reject("authentication.failed");
      }
      const idToken = await user.getIdTokenResult();
      const adminAuth = idToken.claims.admin == true;
      commit(AuthMutationTypes.SET_CURRENT_USER, { user });
      commit(AuthMutationTypes.SET_ADMIN_AUTH, { adminAuth });
    } catch (error) {
      logger.log(AuthActionTypes.LOGIN, "onError", error);
      return Promise.reject(error);
    }
  },
  async [AuthActionTypes.LOGOUT]({ commit }) {
    await auth.signOut();
    commit(AuthMutationTypes.CLEAR, undefined);
  },
  async [AuthActionTypes.REFRESH_ADMIN_AUTH]({ state, commit }) {
    const currentUser = state.currentUser ?? auth.currentUser;
    if (!currentUser) return false;
    try {
      const idToken = await currentUser.getIdTokenResult(true);
      const organizationId = idToken.claims.organizationId;
      const adminAuth = idToken.claims.admin == true;
      commit(AuthMutationTypes.SET_CURRENT_USER, { user: currentUser });
      commit(AuthMutationTypes.SET_ORGANIZATION_ID, { organizationId });
      commit(AuthMutationTypes.SET_ADMIN_AUTH, { adminAuth });
      return adminAuth;
    } catch {
      return false;
    }
  },
  async [AuthActionTypes.BIND_AUTH_SUBSCRIBE]({ state, commit }) {
    if (auth.currentUser) {
      const idToken = await auth.currentUser.getIdTokenResult();
      const adminAuth = idToken?.claims?.admin == true;
      const organizationId = idToken?.claims?.organizationId;
      commit(AuthMutationTypes.SET_CURRENT_USER, { user: auth.currentUser });
      commit(AuthMutationTypes.SET_ADMIN_AUTH, { adminAuth });
      commit(AuthMutationTypes.SET_ORGANIZATION_ID, { organizationId });
    }

    if (state.unsubscribeUser) return;
    const unSubscribe: () => void = auth.onAuthStateChanged(async (user) => {
      if (!user) {
        commit(MutationTypes.SET_INITIAL_LOADING, { initialLoading: false });
        return;
      }
      const idToken = await user.getIdTokenResult(false);
      const adminAuth = idToken?.claims?.admin == true;
      const organizationId = idToken?.claims?.organizationId;
      commit(AuthMutationTypes.SET_CURRENT_USER, { user });
      commit(AuthMutationTypes.SET_ADMIN_AUTH, { adminAuth });
      commit(AuthMutationTypes.SET_ORGANIZATION_ID, { organizationId });
      commit(MutationTypes.SET_INITIAL_LOADING, { initialLoading: false });
    });
    commit(AuthMutationTypes.SET_UN_SUBSCRIBE, { unSubscribe });
    return;
  },
  async [AuthActionTypes.CHANGE_PASSWORD](
    { state, commit },
    { currentPassword, newPassword }
  ): Promise<void> {
    if (!state.organizationId) return;
    if (!state.currentUser) return;
    const credential = await auth.signInWithEmailAndPassword(
      state.currentUser.email,
      currentPassword
    );
    await credential.user.updatePassword(newPassword);
    commit(AuthMutationTypes.SET_CURRENT_USER, { user: credential.user });
  },
  async [AuthActionTypes.RESET_PASSWORD]({ state, commit }, payload) {
    const email = payload.email ?? state.currentUser?.email;
    if (!email || email.length < 1) return Promise.reject("email.empty");
    await auth.sendPasswordResetEmail(email);
  },
};
