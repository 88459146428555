import { functions, auth } from "./firebase";
import logger from "@/loogger";

// バックエンドのapi
export class FunctionsAPI {
  // region指定したfunction
  static async upgradePreworkerToRealWorker(workerId: string) {
    const token = await auth.currentUser?.getIdTokenResult();
    const organizationId = token?.claims["organizationId"];
    if (!organizationId) return Promise.reject("ログインしていません");
    logger.log(
      "onRequest",
      "#upgradePreworkerToRealWorker",
      organizationId,
      workerId
    );
    await functions
      .httpsCallable("upgradePreworkerToRealWorker")({
        organizationId: organizationId,
        workerId: workerId,
      })
      .then((result) => {
        logger.log("onSuccess", "#upgradePreworkerToRealWorker", result);
      })
      .catch((error) => {
        logger.error("onError#upgradePreworkerToRealWorker", error);
        throw error;
      });
    return;
  }

  static async rejectPreworker(workerId: string) {
    const token = await auth.currentUser?.getIdTokenResult();
    const organizationId = token?.claims["organizationId"];
    if (!organizationId) return Promise.reject("ログインしていません");
    logger.log("onRequest", "#rejectPreworker", organizationId, workerId);
    await functions
      .httpsCallable("rejectPreworker")({
        organizationId: organizationId,
        workerId: workerId,
      })
      .then((result) => {
        logger.log("onSuccess#rejectPreworker", result);
      })
      .catch((error) => {
        logger.error("onError#rejectPreworker", error);
        throw error;
      });
    return;
  }

  static async enableWorker(workerId: string) {
    const token = await auth.currentUser?.getIdTokenResult();
    const organizationId = token?.claims["organizationId"];
    if (!organizationId) return Promise.reject("ログインしていません");
    logger.log("onRequest", "#enableWorker", organizationId, workerId);
    await functions
      .httpsCallable("enableWorker")({
        organizationId: organizationId,
        workerId: workerId,
      })
      .then((result) => {
        logger.log("onSuccess#enableWorker", result);
      })
      .catch((error) => {
        logger.error("onError#enableWorker", error);
        throw error;
      });
    return;
  }

  static async disableWorker(workerId: string) {
    const token = await auth.currentUser?.getIdTokenResult();
    const organizationId = token?.claims["organizationId"];
    if (!organizationId) return Promise.reject("ログインしていません");
    logger.log("onRequest", "#disableWorker", organizationId, workerId);
    await functions
      .httpsCallable("disableWorker")({
        organizationId: organizationId,
        workerId: workerId,
      })
      .then((result) => {
        logger.log("onSuccess#disableWorker", result);
      })
      .catch((error) => {
        logger.error("onError#disableWorker", error);
        throw error;
      });
    return;
  }

  static async mergeWorkPlace(fromPlaceId: string, toPlaceId: string) {
    const token = await auth.currentUser?.getIdTokenResult();
    const organizationId = token?.claims["organizationId"];
    if (!organizationId) return Promise.reject("ログインしていません");
    logger.log(
      "onRequest",
      "#mergeWorkPlace",
      organizationId,
      fromPlaceId,
      toPlaceId
    );
    await functions
      .httpsCallable("mergeWorkPlace")({
        organizationId,
        fromPlaceId,
        toPlaceId,
      })
      .then((result) => {
        logger.log("onSuccess#mergeWorkPlace", result);
      })
      .catch((error) => {
        logger.error("onError#mergeWorkPlace", error);
        throw error;
      });
    return;
  }

  static async mergeMachine(fromMachineId: string, toMachineId: string) {
    const token = await auth.currentUser?.getIdTokenResult();
    const organizationId = token?.claims["organizationId"];
    if (!organizationId) return Promise.reject("ログインしていません");
    logger.log("onRequest", "#mergeMachine", [
      organizationId,
      fromMachineId,
      toMachineId,
    ]);
    try {
      const result = await functions.httpsCallable("mergeMachine")({
        organizationId,
        fromMachineId,
        toMachineId,
      });
      logger.log("onSuccess#mergeMachine", result);
    } catch (error) {
      logger.error("onError#mergeMachine", error);
      return Promise.reject(error);
    }
    return;
  }

  static async mergeWorkType(fromWorkTypeId: string, toWorkTypeId: string) {
    const token = await auth.currentUser?.getIdTokenResult();
    const organizationId = token?.claims["organizationId"];
    if (!organizationId) return Promise.reject("ログインしていません");
    logger.log("onRequest", "#mergeWorkType", [
      organizationId,
      fromWorkTypeId,
      toWorkTypeId,
    ]);
    try {
      const result = await functions.httpsCallable("mergeWorkType")({
        organizationId,
        fromWorkTypeId,
        toWorkTypeId,
      });
      logger.log("onSuccess#mergeWorkType", result);
    } catch (error) {
      logger.error("onError#mergeWorkType", error);
      return Promise.reject(error);
    }
    return;
  }
}
