<template>
  <div class="data-header">
    <h2>作業現場別一覧</h2>
  </div>
  <table class="data-table" cellspacing="0" cellpadding="0">
    <thead>
      <tr>
        <th>現場名</th>
        <th>作業時間</th>
        <th>日報数</th>
        <th>使用機材</th>
        <th>実施作業</th>
        <!--        登録日時は保持していなかったのでコメントアウト       -->
        <!--        <th>登録日時</th>-->
        <th>操作</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="place in visibleItems" :key="place.id">
        <td>{{ place.name }}</td>
        <td>
          {{
            relatedReportWorkingTime[place.id]?.reduce(
              (acc, x) => acc + x,
              0
            ) ?? 0
          }}時間
        </td>
        <td>
          <a href="" v-on:click.prevent.stop="moveToReport(place.id)">
            {{ relatedReport[place.id]?.length ?? 0 }} 件
          </a>
        </td>
        <td>
          <a href="" v-on:click.prevent.stop="moveToMachine(place.id)">
            {{ relatedMachine[place.id]?.length ?? 0 }} 種類
          </a>
        </td>
        <td>
          <a href="" v-on:click.prevent.stop="moveToWorkType(place.id)">
            {{ relatedWorkType[place.id]?.length ?? 0 }} 項目
          </a>
        </td>
        <!--        <td>2021/3/{{ k + 1 }}</td>-->
        <td>
          <a
            href="#"
            class="openModal"
            v-on:click.prevent.stop="onClickDetail(place)"
          >
            編集
          </a>
        </td>
      </tr>
    </tbody>
  </table>
  <pagination
    v-show="isNeededPagination"
    :max-length="maxLength"
    :items-limit-per-page="itemsLimitPerPage"
    :on-change-page-index="onChangePageIndex"
  />
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "@/store";
import { WorkPlaceActionTypes } from "@/store/workplace";
import { useRouter } from "vue-router";
import { WorkPlace } from "@/models/WorkPlace";
import { useModal } from "../shared/useModal";
import Pagination, {
  DEFAULT_ITEMS_LIMIT_PER_PAGE,
} from "@/components/shared/Pagination.vue";

export default defineComponent({
  components: { Pagination },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const { showModal } = useModal();

    const currentPage = ref(0);
    const visibleFirstOffset = ref(0);
    const visibleItems = computed(() =>
      store.state.workPlaces.slice(
        visibleFirstOffset.value,
        visibleFirstOffset.value + DEFAULT_ITEMS_LIMIT_PER_PAGE
      )
    );
    const isNeededPagination = computed(
      () => store.state.workPlaces.length > DEFAULT_ITEMS_LIMIT_PER_PAGE
    );

    onMounted(() => {
      store.dispatch(WorkPlaceActionTypes.BIND_WorkPlace, {
        isFetchRelatedStat: true,
      });
    });
    return {
      visibleItems,
      isNeededPagination,
      maxLength: computed(() => store.state.workPlaces.length),
      itemsLimitPerPage: computed(() => DEFAULT_ITEMS_LIMIT_PER_PAGE),
      onChangePageIndex: (newIndex, newOffset) => {
        currentPage.value = newIndex;
        visibleFirstOffset.value = newOffset;
      },

      relatedReportWorkingTime: computed(
        () => store.state.place_RelatedReportWorkingTimes
      ),
      relatedReport: computed(() => store.state.place_RelatedReportIds),
      relatedMachine: computed(() => store.state.place_RelatedMachineIds),
      relatedWorkType: computed(() => store.state.place_RelatedWorkTypeIds),

      moveToReport: (placeId: string) =>
        router.push({ path: "/reports", query: { placeIds: [placeId] } }),
      moveToMachine: (placeId: string) =>
        router.push({
          path: "/reports",
          query: {
            placeIds: [placeId],
            machineIds: store.state.place_RelatedMachineIds[placeId] ?? [],
          },
        }),
      moveToWorkType: (placeId: string) =>
        router.push({
          path: "/reports",
          query: {
            placeIds: [placeId],
            workTypeIds: store.state.place_RelatedWorkTypeIds[placeId] ?? [],
          },
        }),
      onClickDetail: (place: WorkPlace) => {
        store.dispatch(WorkPlaceActionTypes.SELECT_WorkPlace, {
          workPlace: place,
        });
        showModal("WorkPlaceDetailModal");
      },
    };
  },
});
</script>
