
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "@/store";
import { WorkTypeActionTypes } from "@/store/workType";
import { WorkType } from "@/models/WorkType";
import { useModal } from "../shared/useModal";
import Pagination, {
  DEFAULT_ITEMS_LIMIT_PER_PAGE,
} from "@/components/shared/Pagination.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: { Pagination },

  setup(props) {
    const store = useStore();
    const router = useRouter();
    const { showModal } = useModal();

    const currentPage = ref(0);
    const visibleFirstOffset = ref(0);
    const visibleItems = computed(() =>
      store.state.workTypes.slice(
        visibleFirstOffset.value,
        visibleFirstOffset.value + DEFAULT_ITEMS_LIMIT_PER_PAGE
      )
    );
    const isNeededPagination = computed(
      () => store.state.workTypes.length > DEFAULT_ITEMS_LIMIT_PER_PAGE
    );
    onMounted(() => {
      store.dispatch(WorkTypeActionTypes.BIND_WorkType, {
        isFetchRelatedStat: true,
      });
    });
    return {
      visibleItems,
      isNeededPagination,
      maxLength: computed(() => store.state.workTypes.length),
      itemsLimitPerPage: computed(() => DEFAULT_ITEMS_LIMIT_PER_PAGE),
      onChangePageIndex: (newIndex, newOffset) => {
        currentPage.value = newIndex;
        visibleFirstOffset.value = newOffset;
      },
      onClickDetail: (workType: WorkType) => {
        store.dispatch(WorkTypeActionTypes.SELECT_WorkType, { workType });
        showModal("WorkTypeDetailModal");
      },
      relatedReportOutPut: computed(
        () => store.state.workType_RelatedReportOutPutAmount
      ),
      relatedReportWorkingTime: computed(
        () => store.state.workType_RelatedReportWorkingTimes
      ),
      relatedReport: computed(() => store.state.workType_RelatedReportIds),
      relatedMachine: computed(() => store.state.workType_RelatedMachineIds),
      relatedPlace: computed(() => store.state.workType_RelatedPlaceIds),
      moveToReport: (workTypeId: string) =>
        router.push({ path: "/reports", query: { workTypeIds: [workTypeId] } }),
      moveToMachine: (workTypeId: string) =>
        router.push({
          path: "/reports",
          query: {
            workTypeIds: [workTypeId],
            machineIds:
              store.state.workType_RelatedMachineIds[workTypeId] ?? [],
          },
        }),
      moveToPlace: (workTypeId: string) =>
        router.push({
          path: "/reports",
          query: {
            workTypeIds: [workTypeId],
            placeIds: store.state.workType_RelatedPlaceIds[workTypeId] ?? [],
          },
        }),
    };
  },
});
