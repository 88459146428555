<template>
  <span class="container" id="rightside">
    <header>
      <section class="content-title">
        <h1>日報一覧</h1>
      </section>
    </header>

    <aside>
      <report-filter
        :checked-values="checkedValues"
        :apply-filters="applyFilters"
      />
    </aside>
    <main>
      <div class="main-inner">
        <div class="data-header">
          <h2>最新の日報</h2>

          <div class="date-selector">
            <v-date-picker
              class="date-picker"
              v-model="range"
              @click="onClickDate"
              is-range
            >
              <template v-slot="{ inputValue, inputEvents }">
                <button
                  v-show="inputValue.start !== null || inputValue.end !== null"
                  class="clear-button"
                  @click="clearDateSelection"
                />
                <div class="flex justify-center items-center">
                  開始
                  <input
                    :value="inputValue.start"
                    v-on="inputEvents.start"
                    class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                  />
                  　→　 終了
                  <input
                    :value="inputValue.end"
                    v-on="inputEvents.end"
                    class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                  />
                  <csv-download-button
                    :fileName="csvFileName"
                    :selectedReports="csvRangedReport"
                  />
                </div>
              </template>
            </v-date-picker>
          </div>
        </div>
        <table class="data-table" cellspacing="0" cellpadding="0">
          <thead>
            <tr>
              <th>日付</th>
              <th>報告者</th>
              <th>現場</th>
              <th>使用機材</th>
              <th>作業種別</th>
              <th>成果</th>
              <th class="cell-nw">共同作業員</th>
              <th class="cell-nw">作業時間</th>
              <th class="cell-nw">画像</th>
              <th class="cell-nw">詳細</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in visibleItems" :key="item.reportId">
              <td>{{ format(item.workedDate) }}</td>
              <!--      作業員が自ら名前を変えた時に変更が追従できていないので撮り直す。変更されていれば更新する        -->
              <td>{{ resolveWorkerNameAndUpdateIfNeeded(item) }}</td>
              <td>{{ item.workPlace.name }}</td>
              <td>
                {{
                  item.machines && item.machines.length > 0
                    ? item.machines?.map((m) => m.name).join(",")
                    : "-"
                }}
              </td>
              <td>
                {{ item.workOutPut?.workType?.name }}
              </td>
              <td>
                {{ item.workOutPut?.outcome ?? "-" }}
                {{
                  item.workOutPut?.workType?.unit
                    ? ` ${item.workOutPut.workType.unit}`
                    : ""
                }}
              </td>
              <td class="cell-nw">{{ item.workers?.length ?? 0 }}名</td>
              <td class="cell-nw">{{ item.workingTime }}時間</td>
              <td class="cell-nw">{{ item.imagePathes.length }}件</td>

              <td class="cell-nw">
                <a
                  href="#"
                  class="detail-link openModal"
                  v-on:click.prevent.stop="onClickReportDetail(item)"
                >
                  詳細
                </a>
              </td>
            </tr>
          </tbody>
          <tfoot v-show="existsNextReport" class="report-table-footer">
            <tr>
              <td colspan="9">
                <button
                  v-if="!nextReportLoading"
                  class="form-btn additional-loading-btn"
                  @click="onClickLoadNext"
                >
                  追加読み込み
                </button>
                <div v-else class="loader">Loading...</div>
              </td>
            </tr>
          </tfoot>
        </table>
        <pagination
          ref="pagination"
          v-show="false"
          :max-length="maxLength"
          :items-limit-per-page="itemsLimitPerPage"
          :on-change-page-index="onChangePageIndex"
        />
      </div>
    </main>

    <footer></footer>
  </span>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import formatDate from "../shared/DateFormatter";
import CsvDownloadButton from "./CsvDownloadButton.vue";
import ReportFilter from "./ReportFilter.vue";
import { useStore } from "@/store";
import { ReportActionTypes, ReportSearchQuery } from "@/store/report";
import { Report } from "@/models/Report";
import { ReportCsvRow } from "@/models/ReportCsvRow";
import { useRoute, useRouter } from "vue-router";
import Pagination, {
  DEFAULT_ITEMS_LIMIT_PER_PAGE,
} from "@/components/shared/Pagination.vue";
import { WorkerActionTypes } from "@/store/worker";

export default defineComponent({
  components: { CsvDownloadButton, ReportFilter, Pagination },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const asArrStr = (arr: string[] | string | undefined | null): string[] => {
      if (typeof arr === "string") return [arr];
      if (Array.isArray(arr) && arr.length > 0) return arr;
      return null;
    };
    const checkedValues = ref({
      workerIds: asArrStr(route.query.workerIds),
      placeIds: asArrStr(route.query.placeIds),
      machineIds: asArrStr(route.query.machineIds),
      workTypeIds: asArrStr(route.query.workTypeIds),
    });
    // let checkedWorkers: string[] | null = asArrStr(route.query.workerIds);
    // let checkedPlaces: string[] | null = asArrStr(route.query.placeIds);
    // let checkedMachines: string[] | null = asArrStr(route.query.machineIds);
    // let checkedWorkTypes: string[] | null = asArrStr(route.query.workTypeIds);

    let query = new ReportSearchQuery(
      route.query,
      checkedValues.value.workerIds,
      checkedValues.value.placeIds,
      checkedValues.value.machineIds,
      checkedValues.value.workTypeIds
    );
    const range = ref({ start: query.start, end: query.end });
    const state = ref({ isShowStartPicker: true, isShowEndPicker: true });
    const startDateString = () =>
      range.value.start ? `_${formatDate(range.value.start, "YYYYMMDD")}` : "";
    const endDateString = () =>
      range.value.end ? `_${formatDate(range.value.end, "YYYYMMDD")}` : "";
    const existElem = (arr: any[] | undefined | null): boolean =>
      arr ? arr.length > 0 : false;
    const currentPage = ref(0);
    const visibleFirstOffset = ref(0);
    const visibleItems = computed(
      () => store.state.rangedReport ?? store.state.reports
    );
    const maxLength = computed(
      () => (store.state.rangedReport ?? store.state.reports).length
    );
    const isNeededPagination = computed(
      () => maxLength.value > DEFAULT_ITEMS_LIMIT_PER_PAGE
    );
    // paginationのref。clearを呼ぶため。
    const pagination = ref(null);

    const onClickLoadNext = () => {
      if (!store.state.existsNextReport) return;
      store.dispatch(ReportActionTypes.FETCH_Next_Report, query);
    };

    const changeCoWorkersNameIfNeeded = (report: Report): string => {
      if (!report.workers) return;
      report.workers.forEach((worker) => {
        const recordedName = worker.name;
        const collectName = store.state.workers.find(
          (w) => w.uid === worker.uid
        )?.name;
        if (collectName && recordedName !== collectName) {
          store.dispatch(ReportActionTypes.CHANGE_CO_WORKER_NAME, {
            report,
            workerId: worker.uid,
            workerName: collectName,
          });
        }
      });
    };

    onMounted(() => {
      store.dispatch(ReportActionTypes.BIND_Report, query);
      store.dispatch(WorkerActionTypes.FETCH_Worker_IF_NEEDED, undefined);
    });

    watch(
      () => route.query,
      (newQuery) => {
        const workerIds = asArrStr(route.query.workerIds);
        const placeIds = asArrStr(route.query.placeIds);
        const machineIds = asArrStr(route.query.machineIds);
        const workTypeIds = asArrStr(route.query.workTypeIds);
        checkedValues.value = {
          workerIds,
          placeIds,
          machineIds,
          workTypeIds,
        };
        query = new ReportSearchQuery(
          newQuery,
          workerIds,
          placeIds,
          machineIds,
          workTypeIds
        );
        range.value = { start: query.start, end: query.end };
        store.dispatch(ReportActionTypes.BIND_Report, query);
      }
    );
    const pushQueryToRoute = (newQuery: ReportSearchQuery) => {
      query = newQuery;
      router.push({
        path: route.fullPath,
        query: newQuery.asQueryObj,
      });
      pagination.value?.clear();
    };
    return {
      range,
      state,
      checkedValues,
      pagination,
      visibleItems,
      maxLength,
      isNeededPagination,
      onClickLoadNext,
      nextReportLoading: computed(() => store.state.nextReportLoading),
      existsNextReport: computed(() => store.state.existsNextReport),
      // reports: computed(() => store.state.rangedReport ?? store.state.reports),
      // rangedReport: computed(
      //   () => store.state.rangedReport ?? store.state.reports
      // ),
      resolveWorkerNameAndUpdateIfNeeded: (report: Report): string => {
        const recordedName = report.workerName;
        const collectName = store.state.workers.find(
          (w) => w.uid === report.workerId
        )?.name;
        if (collectName && recordedName !== collectName) {
          store.dispatch(ReportActionTypes.CHANGE_WORKER_NAME, {
            reportId: report.reportId,
            workerName: collectName,
          });
        }
        changeCoWorkersNameIfNeeded(report);
        return collectName ?? recordedName;
      },
      csvRangedReport: (): Promise<ReportCsvRow[]> =>
        store
          .dispatch(ReportActionTypes.FETCH_Report, query)
          .then((reports) => reports.map((r) => new ReportCsvRow(r))),
      csvFileName: computed(() =>
        store.state.rangedReport
          ? `reports${startDateString()}${endDateString()}`
          : `reports_all`
      ),
      itemsLimitPerPage: computed(() => DEFAULT_ITEMS_LIMIT_PER_PAGE),
      onChangePageIndex: (newIndex, newOffset) => {
        currentPage.value = newIndex;
        visibleFirstOffset.value = newOffset;
      },
      clearDateSelection: () => {
        pushQueryToRoute(
          new ReportSearchQuery(
            {},
            checkedValues.value.workerIds,
            checkedValues.value.placeIds,
            checkedValues.value.machineIds,
            checkedValues.value.workTypeIds
          )
        );
      },
      applyFilters: (
        workerIds: string[],
        placeIds: string[],
        machineIds: string[],
        workTypeIds: string[]
      ) => {
        const checkedWorkers =
          workerIds.length !== store.state.workers.length ? workerIds : null;
        const checkedPlaces =
          placeIds.length !== store.state.workPlaces.length ? placeIds : null;
        const checkedMachines =
          machineIds.length !== store.state.machines.length ? machineIds : null;
        const checkedWorkTypes =
          workTypeIds.length !== store.state.workTypes.length
            ? workTypeIds
            : null;
        pushQueryToRoute(
          new ReportSearchQuery(
            route.query,
            checkedWorkers,
            checkedPlaces,
            checkedMachines,
            checkedWorkTypes
          )
        );
      },
      showStartPicker: () =>
        (state.value = { ...state.value, isShowStartPicker: true }),
      hideStartPicker: () => {
        state.value = { ...state.value, isShowStartPicker: false };
      },
      onClickDate: () => {
        if (!range.value.start) return;
        if (!range.value.end) return;

        if (range.value.start && isNaN(range.value.start.getTime())) return;
        if (range.value.end && isNaN(range.value.end.getTime())) return;
        const startDate = formatDate(range.value.start, "YYYY-MM-DD");
        const endDate = formatDate(range.value.end, "YYYY-MM-DD");
        router.push({
          path: route.fullPath,
          query: {
            ...(startDate ? { start: startDate } : {}),
            ...(endDate ? { end: endDate } : {}),
          },
        });
      },
      format: (date: Date) => formatDate(date, "YYYY/MM/DD"),
      onClickReportDetail: (report: Report) =>
        store.dispatch(ReportActionTypes.SELECT_REPORT, { report: report }),
    };
  },
});
</script>

<style lang="scss" scoped>
.report-table-footer {
  padding: 0px;
  margin: 0px;
}

.additional-loading-indicator {
  height: 44px;
  width: 180px;
  display: block;
  margin: 20px auto 20px auto;
}
.additional-loading-btn {
  height: 44px;
  width: 180px;
  display: block;
  margin: 20px auto 20px auto;

  background: #ceebe9;
  cursor: pointer;

  min-width: 200px;
  padding: 0px 15px;
  box-sizing: border-box;
  border-radius: 30px;
}
.clear-selected-date-button {
}
</style>
