<template>
  <div id="closeModal" class="closeIconModal closeModal" v-on:click="hideModal">
    ×
  </div>
  <div class="modalContents" v-if="deletingMode">
    <div class="modalContents">
      <h3>削除する：{{ selectedWorkPlace?.name }}</h3>
      <!--      <ul class="radio-list-modal">-->
      <!--        <label for="select">他の現場に振り分けて削除する</label>-->
      <!--      <ul class="radio-list-modal">-->
      <!--        <li>-->
      <!--    // TODO: CloseするためにはUIを色々触る必要がある      -->
      <!--          <input-->
      <!--            type="radio"-->
      <!--            id="check01"-->
      <!--            v-model="isMoveToOtherPlace"-->
      <!--            :value="true"-->
      <!--          />-->
      <div class="target-select-container">
        <span>他の現場に振り分けて削除する</span>
        <select
          class="target-select"
          v-model="selectedMoveToPlace"
          :disabled="!isMoveToOtherPlace"
          id="select"
        >
          <option disabled value="">選択して下さい</option>
          <option v-for="place in workPlaces" :value="place" :key="place.id">
            {{ place.name }}
          </option>
        </select>
      </div>
      <!--        </li>-->
      <!--        <li>-->
      <!--          <input-->
      <!--            type="radio"-->
      <!--            id="check02"-->
      <!--            v-model="isMoveToOtherPlace"-->
      <!--            :value="false"-->
      <!--          />-->
      <!--          <label for="check02" class="radio">-->
      <!--            他の現場に振り分けない（現場をクローズする）-->
      <!--          </label>-->
      <!--        </li>-->
      <!--      </ul>-->
      <div v-show="errorMessage" class="form-error-left">
        {{ errorMessage }}
      </div>
      <button
        v-if="!isLoading"
        class="closeModal mt-40 submit-button"
        @click="onClickDelete"
      >
        {{ isMoveToOtherPlace ? "削除" : "クローズ" }}
      </button>
      <div v-else class="loader-small">Loading...</div>
    </div>
  </div>

  <div class="modalContents" v-else>
    <div class="modalContents">
      <h3>編集：{{ selectedWorkPlace?.name }}</h3>
      <p>
        機材名：
        <input type="text" name="" v-model="editingNewPlaceName" />
      </p>
      <div v-show="errorMessage" class="form-error-left">
        {{ errorMessage }}
      </div>
      <button
        class="closeModal mt-40 submit-button"
        @click="applyChanges"
        :disabled="isLoading"
      >
        変更
      </button>
      <p>
        <a
          href="#"
          class="deleteModal"
          v-on:click.prevent.stop="toDeletingMode"
        >
          削除
        </a>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from "@/store";
import { useModal } from "../shared/useModal";
import { computed, defineComponent, onMounted, ref } from "vue";
import { WorkPlaceActionTypes } from "@/store/workplace";
import { FunctionsAPI } from "@/api/functions";
import logger from "@/loogger";

export default defineComponent({
  setup(props) {
    const store = useStore();
    const isLoading = ref(false);
    const errorMessage = ref();
    const isMoveToOtherPlace = ref(true);
    const deletingMode = ref(false);
    const selectedMoveToPlace = ref();
    const editingNewPlaceName = ref<string>();
    const { hideModal } = useModal();

    const execClosePlace = () => {
      // TODO: Close処理
    };
    const execBindOtherPlace = async () => {
      if (!selectedMoveToPlace.value || !selectedMoveToPlace.value.id) {
        errorMessage.value = "選択されていません";
        return;
      }
      errorMessage.value = "";
      isLoading.value = true;
      const selectedPlaceId = store.state.selectedWorkPlace.id;
      const moveToPlaceId = selectedMoveToPlace.value.id;
      await FunctionsAPI.mergeWorkPlace(selectedPlaceId, moveToPlaceId)
        .then(() => {
          isLoading.value = false;
          hideModal();
        })
        .catch(() => {
          isLoading.value = false;
          errorMessage.value = "紐付けに失敗しました。";
        });
    };
    onMounted(() => {
      editingNewPlaceName.value = store.state.selectedWorkPlace?.name;
    });
    return {
      deletingMode,
      isLoading,
      errorMessage,
      isMoveToOtherPlace,
      selectedMoveToPlace,
      editingNewPlaceName,
      selectedWorkPlace: computed(() => store.state.selectedWorkPlace),
      workPlaces: computed(() =>
        store.state.workPlaces.filter(
          (w) => w.id !== store.state.selectedWorkPlace.id
        )
      ),
      toDeletingMode: () => {
        errorMessage.value = "";
        isLoading.value = false;
        deletingMode.value = true;
      },
      onClickDelete: () => {
        if (isMoveToOtherPlace.value) {
          execBindOtherPlace();
        } else {
          execClosePlace();
        }
      },
      applyChanges: async () => {
        if (
          !editingNewPlaceName.value ||
          editingNewPlaceName.value.length < 1
        ) {
          errorMessage.value = "入力されていません。";
          return;
        }
        errorMessage.value = "";
        isLoading.value = true;
        const placeId = store.state.selectedWorkPlace.id;
        const newName = editingNewPlaceName.value;
        try {
          await store.dispatch(WorkPlaceActionTypes.CHANGE_NAME_WorkPlace, {
            placeId,
            newName,
          });
          isLoading.value = false;
          hideModal();
        } catch (error) {
          logger.error("名前の変更に失敗しました", error);
          isLoading.value = false;
          if (error === "duplicated.name") {
            errorMessage.value = "名前が重複しています。";
          } else {
            errorMessage.value = "名前の変更に失敗しました。";
          }
        }
      },
      hideModal: () => {
        store.dispatch(WorkPlaceActionTypes.DE_SELECT_WorkPlace, undefined);
        hideModal();
      },
    };
  },
});
</script>
