
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "@/store";
import { WorkerActionTypes } from "@/store/worker";
import { useModal } from "../shared/useModal";
import { OrganizationWorker } from "@/models/OrganizationWorker";
import CreateWorkerForm from "@/components/member/CreateWorkerForm.vue";
import Pagination, {
  DEFAULT_ITEMS_LIMIT_PER_PAGE,
} from "@/components/shared/Pagination.vue";

export default defineComponent({
  components: { CreateWorkerForm, Pagination },
  setup(props) {
    const store = useStore();
    const { showModal } = useModal();
    const onlyVerifiedWorkers = ref(false);
    const currentPage = ref(0);
    const visibleFirstOffset = ref(0);
    const visibleItems = computed(() =>
      store.state.preWorkers
        .filter((w) => !onlyVerifiedWorkers.value)
        .concat(
          store.state.workers.filter((w) =>
            onlyVerifiedWorkers.value ? w.isVerified : true
          )
        )
        .slice(
          visibleFirstOffset.value,
          visibleFirstOffset.value + DEFAULT_ITEMS_LIMIT_PER_PAGE
        )
    );
    const isNeededPagination = computed(
      () => store.state.workers.length > DEFAULT_ITEMS_LIMIT_PER_PAGE
    );

    onMounted(() => {
      store.dispatch(WorkerActionTypes.BIND_Worker, undefined);
    });
    return {
      visibleItems,
      isNeededPagination,
      maxLength: computed(() => store.state.workers.length),
      itemsLimitPerPage: computed(() => DEFAULT_ITEMS_LIMIT_PER_PAGE),
      onChangePageIndex: (newIndex, newOffset) => {
        currentPage.value = newIndex;
        visibleFirstOffset.value = newOffset;
      },

      isPreWorker: (worker: OrganizationWorker) =>
        store.state.preWorkers.some((x) => x.workerId === worker.workerId),
      onChangeFilter: (onlyVerified: boolean) => {
        onlyVerifiedWorkers.value = onlyVerified;
      },
      onClickDetail: (worker: OrganizationWorker) => {
        store.dispatch(WorkerActionTypes.SELECT_Worker, { worker: worker });
        showModal("MemberDetailModal");
      },
    };
  },
});
