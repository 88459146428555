<template>
  <div id="closeModal" class="closeIconModal closeModal" v-on:click="hideModal">
    ×
  </div>
  <div class="modalContents" v-if="isConfirmedMode">
    <h3>パスワードリセット</h3>
    <!--    <p  class="form-area">-->
    <p v-if="email">
      {{ email }}
    </p>
    <p v-else>
      <label for="email" class="input-label">メールアドレス：</label>
      <!--        class="input-long"-->
      <input
        class="input-normal"
        type="email"
        id="email"
        v-model="emailInput"
        required
      />
    </p>
    <p>こちらのメールアドレスにリセット用のURLを送信します。</p>
    <button
      class="closeModal mt-40 submit-button"
      @click="onClickPasswordReset"
    >
      送信
    </button>
  </div>

  <div class="modalContents" v-else>
    <h3>パスワードリセット</h3>

    <p class="body1">
      パスワードリセット用のURLを送信しました。
      <br />
      メールボックスをご確認ください。
    </p>

    <div class="light-text">
      メールが届くまで、時間のかかる場合がございます。
      <br />
      しばらく待っても届かない場合は、迷惑メールボックスをご確認いただくか、
      <br />
      時間をおいてもう一度パスワードリセットをお試しください。
    </div>
    <button class="closeModal mt-40 submit-button" @click="hideModal">
      閉じる
    </button>
  </div>
</template>

<script lang="ts">
import { useStore } from "@/store";
import { useModal } from "../shared/useModal";
import { computed, defineComponent, onMounted, ref } from "vue";
import { AccountActionTypes } from "@/store/account";
import { AuthActionTypes } from "@/store/auth";

export default defineComponent({
  setup(props) {
    const store = useStore();
    const { hideModal } = useModal();
    const isConfirmedMode = ref(true);
    const emailInput = ref();
    onMounted(async () => {
      await store.dispatch(AccountActionTypes.FETCH_Account, undefined);
    });
    return {
      isConfirmedMode,
      email: computed(() => store.state.currentUser?.email),
      emailInput,
      onClickPasswordReset: async () => {
        if (emailInput.value || store.state.currentUser?.email) {
          await store.dispatch(AuthActionTypes.RESET_PASSWORD, {
            email: emailInput.value,
          });
          isConfirmedMode.value = false;
        }
      },
      hideModal: () => hideModal(),
    };
  },
});
</script>
