
import { useStore } from "@/store";
import { useModal } from "../shared/useModal";
import { computed, defineComponent, onMounted, ref } from "vue";
import { WorkPlaceActionTypes } from "@/store/workplace";
import { FunctionsAPI } from "@/api/functions";
import logger from "@/loogger";

export default defineComponent({
  setup(props) {
    const store = useStore();
    const isLoading = ref(false);
    const errorMessage = ref();
    const isMoveToOtherPlace = ref(true);
    const deletingMode = ref(false);
    const selectedMoveToPlace = ref();
    const editingNewPlaceName = ref<string>();
    const { hideModal } = useModal();

    const execClosePlace = () => {
      // TODO: Close処理
    };
    const execBindOtherPlace = async () => {
      if (!selectedMoveToPlace.value || !selectedMoveToPlace.value.id) {
        errorMessage.value = "選択されていません";
        return;
      }
      errorMessage.value = "";
      isLoading.value = true;
      const selectedPlaceId = store.state.selectedWorkPlace.id;
      const moveToPlaceId = selectedMoveToPlace.value.id;
      await FunctionsAPI.mergeWorkPlace(selectedPlaceId, moveToPlaceId)
        .then(() => {
          isLoading.value = false;
          hideModal();
        })
        .catch(() => {
          isLoading.value = false;
          errorMessage.value = "紐付けに失敗しました。";
        });
    };
    onMounted(() => {
      editingNewPlaceName.value = store.state.selectedWorkPlace?.name;
    });
    return {
      deletingMode,
      isLoading,
      errorMessage,
      isMoveToOtherPlace,
      selectedMoveToPlace,
      editingNewPlaceName,
      selectedWorkPlace: computed(() => store.state.selectedWorkPlace),
      workPlaces: computed(() =>
        store.state.workPlaces.filter(
          (w) => w.id !== store.state.selectedWorkPlace.id
        )
      ),
      toDeletingMode: () => {
        errorMessage.value = "";
        isLoading.value = false;
        deletingMode.value = true;
      },
      onClickDelete: () => {
        if (isMoveToOtherPlace.value) {
          execBindOtherPlace();
        } else {
          execClosePlace();
        }
      },
      applyChanges: async () => {
        if (
          !editingNewPlaceName.value ||
          editingNewPlaceName.value.length < 1
        ) {
          errorMessage.value = "入力されていません。";
          return;
        }
        errorMessage.value = "";
        isLoading.value = true;
        const placeId = store.state.selectedWorkPlace.id;
        const newName = editingNewPlaceName.value;
        try {
          await store.dispatch(WorkPlaceActionTypes.CHANGE_NAME_WorkPlace, {
            placeId,
            newName,
          });
          isLoading.value = false;
          hideModal();
        } catch (error) {
          logger.error("名前の変更に失敗しました", error);
          isLoading.value = false;
          if (error === "duplicated.name") {
            errorMessage.value = "名前が重複しています。";
          } else {
            errorMessage.value = "名前の変更に失敗しました。";
          }
        }
      },
      hideModal: () => {
        store.dispatch(WorkPlaceActionTypes.DE_SELECT_WorkPlace, undefined);
        hideModal();
      },
    };
  },
});
