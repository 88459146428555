<template>
  <div class="data-header">
    <h2>作業種類別一覧</h2>
  </div>
  <table class="data-table" cellspacing="0" cellpadding="0">
    <thead>
      <tr>
        <th>作業名（単位）</th>
        <!--        <th>単位</th>-->
        <th>成果</th>
        <th>作業時間</th>
        <th>日報数</th>
        <th>使用現場</th>
        <th>機材</th>
        <!--        <th>登録日時</th>-->
        <th>操作</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="workType in visibleItems" :key="workType.id">
        <td>
          {{ workType.name }}{{ workType.unit ? ` （${workType.unit}）` : "" }}
        </td>
        <td>
          {{
            relatedReportOutPut[workType.id]?.reduce((acc, x) => acc + x, 0) ??
            0
          }}
          {{ workType.unit ?? "" }}
        </td>
        <td>
          {{
            relatedReportWorkingTime[workType.id]?.reduce(
              (acc, x) => acc + x,
              0
            ) ?? 0
          }}時間
        </td>
        <td>
          <a href="" v-on:click.prevent.stop="moveToReport(workType.id)">
            {{ relatedReport[workType.id]?.length ?? 0 }} 件
          </a>
        </td>
        <td>
          <a href="" v-on:click.prevent.stop="moveToMachine(workType.id)">
            {{ relatedMachine[workType.id]?.length ?? 0 }} 種類
          </a>
        </td>
        <td>
          <a href="" v-on:click.prevent.stop="moveToPlace(workType.id)">
            {{ relatedPlace[workType.id]?.length ?? 0 }} 項目
          </a>
        </td>

        <td>
          <a
            href="#"
            class="openModal"
            v-on:click.prevent.stop="onClickDetail(workType)"
          >
            編集
          </a>
        </td>
      </tr>
    </tbody>
  </table>
  <pagination
    v-show="isNeededPagination"
    :max-length="maxLength"
    :items-limit-per-page="itemsLimitPerPage"
    :on-change-page-index="onChangePageIndex"
  />
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "@/store";
import { WorkTypeActionTypes } from "@/store/workType";
import { WorkType } from "@/models/WorkType";
import { useModal } from "../shared/useModal";
import Pagination, {
  DEFAULT_ITEMS_LIMIT_PER_PAGE,
} from "@/components/shared/Pagination.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: { Pagination },

  setup(props) {
    const store = useStore();
    const router = useRouter();
    const { showModal } = useModal();

    const currentPage = ref(0);
    const visibleFirstOffset = ref(0);
    const visibleItems = computed(() =>
      store.state.workTypes.slice(
        visibleFirstOffset.value,
        visibleFirstOffset.value + DEFAULT_ITEMS_LIMIT_PER_PAGE
      )
    );
    const isNeededPagination = computed(
      () => store.state.workTypes.length > DEFAULT_ITEMS_LIMIT_PER_PAGE
    );
    onMounted(() => {
      store.dispatch(WorkTypeActionTypes.BIND_WorkType, {
        isFetchRelatedStat: true,
      });
    });
    return {
      visibleItems,
      isNeededPagination,
      maxLength: computed(() => store.state.workTypes.length),
      itemsLimitPerPage: computed(() => DEFAULT_ITEMS_LIMIT_PER_PAGE),
      onChangePageIndex: (newIndex, newOffset) => {
        currentPage.value = newIndex;
        visibleFirstOffset.value = newOffset;
      },
      onClickDetail: (workType: WorkType) => {
        store.dispatch(WorkTypeActionTypes.SELECT_WorkType, { workType });
        showModal("WorkTypeDetailModal");
      },
      relatedReportOutPut: computed(
        () => store.state.workType_RelatedReportOutPutAmount
      ),
      relatedReportWorkingTime: computed(
        () => store.state.workType_RelatedReportWorkingTimes
      ),
      relatedReport: computed(() => store.state.workType_RelatedReportIds),
      relatedMachine: computed(() => store.state.workType_RelatedMachineIds),
      relatedPlace: computed(() => store.state.workType_RelatedPlaceIds),
      moveToReport: (workTypeId: string) =>
        router.push({ path: "/reports", query: { workTypeIds: [workTypeId] } }),
      moveToMachine: (workTypeId: string) =>
        router.push({
          path: "/reports",
          query: {
            workTypeIds: [workTypeId],
            machineIds:
              store.state.workType_RelatedMachineIds[workTypeId] ?? [],
          },
        }),
      moveToPlace: (workTypeId: string) =>
        router.push({
          path: "/reports",
          query: {
            workTypeIds: [workTypeId],
            placeIds: store.state.workType_RelatedPlaceIds[workTypeId] ?? [],
          },
        }),
    };
  },
});
</script>
