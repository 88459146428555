
import { computed, defineComponent, onMounted, PropType } from "vue";
import { Report } from "@/models/Report";
import formatDate from "../shared/DateFormatter";
import { useStore } from "@/store";
import { ReportActionTypes } from "@/store/report";
import { WorkerActionTypes } from "@/store/worker";
import { WorkTypeActionTypes } from "@/store/workType";
import { WorkPlaceActionTypes } from "@/store/workplace";
import { MachineActionTypes } from "@/store/machine";
import { useRouter } from "vue-router";
import { StatisticsActionTypes } from "@/store/statistics";

export default defineComponent({
  // TODO: ダミーデータを埋める
  props: {
    // reports: {
    // type : Array as PropType<Report[]>,
    // }
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const totalWeatherCount = computed(
      () =>
        (store.state.snowDaysCount +
          store.state.rainyDaysCount +
          store.state.sunnyDaysCount +
          store.state.cloudyDaysCount) /
        100
    );
    const changeCoWorkersNameIfNeeded = (report: Report): string => {
      if (!report.workers) return;
      report.workers.forEach((worker) => {
        const recordedName = worker.name;
        const collectName = store.state.workers.find(
          (w) => w.uid === worker.uid
        )?.name;
        if (collectName && recordedName !== collectName) {
          store.dispatch(ReportActionTypes.CHANGE_CO_WORKER_NAME, {
            report,
            workerId: worker.uid,
            workerName: collectName,
          });
        }
      });
    };

    onMounted(() => {
      store.dispatch(ReportActionTypes.BIND_Report, undefined);
      store.dispatch(WorkerActionTypes.BIND_Worker, undefined);
      store.dispatch(WorkerActionTypes.BIND_PreWorker, undefined);
      store.dispatch(WorkTypeActionTypes.BIND_WorkType, undefined);
      store.dispatch(WorkPlaceActionTypes.BIND_WorkPlace, undefined);
      store.dispatch(MachineActionTypes.BIND_Machine, undefined);
      store.dispatch(StatisticsActionTypes.BIND_Statistics, undefined);
    });
    return {
      reports: computed(() => store.state.reports.slice(0, 10)),
      totalReportCount: computed(() => store.state.totalReportCount),
      totalWorkingTime: computed(() => store.state.totalWorkingTime),
      workPlaceCount: computed(() => store.state.workPlaces.length),
      machineCount: computed(() => store.state.machines.length),
      workTypeCount: computed(() => store.state.workTypes.length),

      snowPercentage: computed(() =>
        Math.round(
          store.state.snowDaysCount
            ? store.state.snowDaysCount / totalWeatherCount.value
            : 0
        )
      ),
      rainyPercentage: computed(() =>
        Math.round(
          store.state.rainyDaysCount
            ? store.state.rainyDaysCount / totalWeatherCount.value
            : 0
        )
      ),
      sunnyPercentage: computed(() =>
        Math.round(
          store.state.sunnyDaysCount
            ? store.state.sunnyDaysCount / totalWeatherCount.value
            : 0
        )
      ),
      cloudyPercentage: computed(() =>
        Math.round(
          store.state.cloudyDaysCount
            ? store.state.cloudyDaysCount / totalWeatherCount.value
            : 0
        )
      ),

      verifiedWorkersCount: computed(
        () => store.state.workers.filter((w) => w.isVerified).length
      ),
      waitingValidWorkersCount: computed(() => store.state.preWorkers.length),
      existsWaitingValidWorker: computed(
        () => store.state.preWorkers.length > 0
      ),
      format: (date: Date) => formatDate(date, "YYYY/MM/DD"),
      resolveWorkerNameAndUpdateIfNeeded: (report: Report): string => {
        const recordedName = report.workerName;
        const collectName = store.state.workers.find(
          (w) => w.uid === report.workerId
        )?.name;
        if (collectName && recordedName !== collectName) {
          store.dispatch(ReportActionTypes.CHANGE_WORKER_NAME, {
            reportId: report.reportId,
            workerName: collectName,
          });
        }
        changeCoWorkersNameIfNeeded(report);
        return collectName ?? recordedName;
      },
      pushMemberPage: () => router.push("/members"),
      onClickReportDetail: (report: Report) =>
        store.dispatch(ReportActionTypes.SELECT_REPORT, { report: report }),
    };
  },
});
