export class WorkType {
  id: string;
  name: string;
  unit?: string;
  constructor(id: string, obj: any) {
    this.id = id;
    this.name = obj.name;
    this.unit = obj.unit;
  }
}
