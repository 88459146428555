import { ActionTree } from "vuex";
import { database, db } from "@/api/firebase";
import { Machine } from "@/models/Machine";
import { AugmentedActionContext } from "./types/actions";
import { State } from ".";
import { StatisticsActionTypes } from "@/store/statistics";
import { WorkPlace } from "@/models/WorkPlace";

export interface MachineState {
  machines: Machine[];
  selectedMachine: Machine | null;
  unsubscribeMachines: () => void | null;

  machine_RelatedReportIds: { [machineId: string]: string[] };
  machine_RelatedReportWorkingTimes: { [machineId: string]: number[] };
  machine_RelatedPlaceIds: { [machineId: string]: string[] };
  machine_RelatedWorkTypeIds: { [machineId: string]: string[] };
}

/** Mutations */

export enum MachineMutationTypes {
  SET_MACHINE = "MachineMutationTypes/SET_MACHINE",
  SET_SELECTED_Machine = "MachineMutationTypes/SET_SELECTED_Machine",
  SET_UN_SUBSCRIBE = "MachineMutationTypes/SET_UN_SUBSCRIBE",
}

export type MachineMutations<S = State> = {
  [MachineMutationTypes.SET_MACHINE](
    state: S,
    payload: { machines: Machine[] }
  ): void;

  [MachineMutationTypes.SET_SELECTED_Machine](
    state: S,
    payload: { machine: Machine | null }
  ): void;
  [MachineMutationTypes.SET_UN_SUBSCRIBE](
    state: S,
    payload: { unSubscribe: () => void }
  ): void;
};

export const machineMutations: MachineMutations = {
  [MachineMutationTypes.SET_MACHINE](state, { machines }): void {
    state.machines = machines;
  },
  [MachineMutationTypes.SET_SELECTED_Machine](state: State, { machine }): void {
    state.selectedMachine = machine;
  },
  [MachineMutationTypes.SET_UN_SUBSCRIBE](state, { unSubscribe }) {
    state.unsubscribeMachines = unSubscribe;
  },
};

/** Actions  */

export enum MachineActionTypes {
  BIND_Machine = "Machine/BIND_Machine",
  FETCH_Machine = "Machine/FETCH_Machine",
  SELECT_Machine = "Machine/SELECT_Machine",
  DE_SELECT_Machine = "Machine/DE_SELECT_Machine",
  CREATE_Machine = "Machine/CREATE_Machine",
  CHANGE_NAME_Machine = "Machine/CHANGE_NAME_Machine",
  FETCH_Statistics_Machine_Report = "MachineActionTypes/FETCH_Statistics_Machine_Report",
  FETCH_Statistics_Machine_Place = "MachineActionTypes/FETCH_Statistics_Machine_Place",
  FETCH_Statistics_Machine_WorkType = "MachineActionTypes/FETCH_Statistics_Machine_WorkType",
}

export interface MachineActions {
  [MachineActionTypes.BIND_Machine](
    { commit }: AugmentedActionContext,
    payload: { isFetchRelatedStat } | undefined
  ): void;
  [MachineActionTypes.FETCH_Machine]({
    commit,
  }: AugmentedActionContext): Promise<void>;
  [MachineActionTypes.SELECT_Machine](
    { commit }: AugmentedActionContext,
    payload: { machine: Machine }
  ): void;
  [MachineActionTypes.DE_SELECT_Machine]({
    commit,
  }: AugmentedActionContext): void;
  [MachineActionTypes.CREATE_Machine](
    { commit }: AugmentedActionContext,
    payload: { machineName: string }
  ): Promise<void>;
  [MachineActionTypes.CHANGE_NAME_Machine](
    { commit }: AugmentedActionContext,
    payload: { machineId: string; newName: string }
  ): Promise<void>;
  [MachineActionTypes.FETCH_Statistics_Machine_Report](
    { commit }: AugmentedActionContext,
    payload: { machineId: string }
  ): void;
  [MachineActionTypes.FETCH_Statistics_Machine_Place](
    { commit }: AugmentedActionContext,
    payload: { machineId: string }
  ): void;
  [MachineActionTypes.FETCH_Statistics_Machine_WorkType](
    { commit }: AugmentedActionContext,
    payload: { machineId: string }
  ): void;
}

const obj2keyArray = (obj: any): string[] => {
  const array: string[] = [];
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== null && obj[key] !== undefined) array.push(key);
  });
  return array;
};

const obj2valueArray = (obj: any): number[] => {
  const array: number[] = [];
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== null && obj[key] !== undefined)
      array.push(Number.parseFloat(obj[key]));
  });
  return array;
};

const Dao = {
  findMachineByName: (
    organizationId: string,
    machineName: string
  ): Promise<Machine[]> =>
    db
      .collection(`organizations/${organizationId}/machines`)
      .where("name", "==", machineName)
      .get({ source: "server" })
      .then((snapshot) => snapshot.docs.map((doc) => new Machine(doc.data()))),
};

export const machineAction: ActionTree<State, State> & MachineActions = {
  [MachineActionTypes.DE_SELECT_Machine]({ commit }): void {
    commit(MachineMutationTypes.SET_SELECTED_Machine, { machine: null });
  },
  [MachineActionTypes.SELECT_Machine]({ commit }, { machine }): void {
    commit(MachineMutationTypes.SET_SELECTED_Machine, { machine });
  },
  [MachineActionTypes.BIND_Machine]({ state, commit, dispatch }, payload) {
    const organizationId = state.organizationId;
    const fetchStat = (newMachines: Machine[]): void => {
      newMachines.forEach((m) => {
        const machineId: string = m.id;
        dispatch(MachineActionTypes.FETCH_Statistics_Machine_Report, {
          machineId,
        });
        dispatch(MachineActionTypes.FETCH_Statistics_Machine_Place, {
          machineId,
        });
        dispatch(MachineActionTypes.FETCH_Statistics_Machine_WorkType, {
          machineId,
        });
      });
    };
    if (!organizationId) return;
    if (state.unsubscribeMachines) {
      if (payload?.isFetchRelatedStat === true) fetchStat(state.machines);
      return;
    }
    const unSubscribe = db
      .collection("organizations")
      .doc(organizationId)
      .collection("machines")
      .onSnapshot(async (result) => {
        const newMachines = result.docs.map((d) => new Machine(d.data()));
        fetchStat(newMachines);
        commit(MachineMutationTypes.SET_MACHINE, { machines: newMachines });
      });
    commit(MachineMutationTypes.SET_UN_SUBSCRIBE, { unSubscribe });
    return;
  },
  async [MachineActionTypes.FETCH_Machine]({ state, commit, dispatch }) {
    const organizationId = state.organizationId;
    if (!organizationId) return;
    return db
      .collection("organizations")
      .doc(organizationId)
      .collection("machines")
      .get()
      .then((result) => {
        const newMachines = result.docs.map((d) => new Machine(d.data()));
        commit(MachineMutationTypes.SET_MACHINE, {
          machines: newMachines,
        });
      });
  },
  async [MachineActionTypes.CREATE_Machine](
    { state, commit },
    { machineName }
  ) {
    const organizationId = state.organizationId;
    if (!organizationId) return;
    if (!machineName) return;

    if (state.machines.some((m) => m.name === machineName))
      return Promise.reject("duplicated.name");

    const existsPlace = await Dao.findMachineByName(
      organizationId,
      machineName
    );

    if (existsPlace.length > 0) return Promise.reject("duplicated.name");

    const collection = db
      .collection("organizations")
      .doc(organizationId)
      .collection("machines");
    const tmpId = collection.doc().id;
    const newMachine = {
      id: tmpId,
      name: machineName,
    };
    return collection.doc(tmpId).set(newMachine, { merge: true });
  },
  async [MachineActionTypes.CHANGE_NAME_Machine](
    { state, commit },
    { machineId, newName }
  ): Promise<void> {
    const organizationId = state.organizationId;
    if (!organizationId) return;

    if (state.machines.some((m) => m.name === newName && m.id !== machineId))
      return Promise.reject("duplicated.name");

    const existsList = (
      await Dao.findMachineByName(organizationId, newName)
    ).filter((p) => p.id !== machineId);
    if (existsList.length > 0) {
      return Promise.reject("duplicated.name");
    } else {
      await db
        .doc(`organizations/${organizationId}/machines/${machineId}`)
        .set({ name: newName }, { merge: true });
      return;
    }
  },
  [MachineActionTypes.FETCH_Statistics_Machine_Report](
    { commit, state },
    { machineId }
  ) {
    if (!state.organizationId) return;
    database
      .ref(`machine/${state.organizationId}/${machineId}/reports`)
      .once("value")
      .then((snapshot): void => {
        state.machine_RelatedReportIds[machineId] = obj2keyArray(
          snapshot.val() ?? {}
        );
        state.machine_RelatedReportWorkingTimes[machineId] = obj2valueArray(
          snapshot.val() ?? {}
        );
      });
  },
  [MachineActionTypes.FETCH_Statistics_Machine_Place](
    { commit, state },
    { machineId }
  ) {
    if (!state.organizationId) return;
    database
      .ref(`machine/${state.organizationId}/${machineId}/places`)
      .once("value")
      .then((snapshot): void => {
        state.machine_RelatedPlaceIds[machineId] = obj2keyArray(
          snapshot.val() ?? {}
        );
      });
  },
  [MachineActionTypes.FETCH_Statistics_Machine_WorkType](
    { commit, state },
    { machineId }
  ) {
    if (!state.organizationId) return;
    database
      .ref(`machine/${state.organizationId}/${machineId}/workTypes`)
      .once("value")
      .then((snapshot): void => {
        state.machine_RelatedWorkTypeIds[machineId] = obj2keyArray(
          snapshot.val() ?? {}
        );
      });
  },
};
