<template>
  <h2>作業員操作</h2>

  <ul v-show="false" class="radio-list">
    <li class="mb-20">
      作業員名：
      <input type="text" name="" id="" />
    </li>
  </ul>

  <button v-show="false" class="submit-button" @click="onClickAdd">追加</button>

  <ul class="checkbox-list mt-40">
    <li>
      <input
        type="checkbox"
        name=""
        id="onlyVerified"
        v-model="onlyVerified"
        @change="onChangeFilter(onlyVerified)"
      />
      <label for="onlyVerified" class="checkbox">稼働中の作業員のみ追加</label>
    </li>
  </ul>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  props: {
    onChangeFilter: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const onlyVerified = ref(false);
    return {
      onlyVerified,
      onClickAdd: () => {
        // store.dispatch(WorkerActionTypes.CREATE_Worker);
      },
    };
  },
});
</script>
