<template>
  <header class="navbar">
    <nav>
      <div class="nav-left">
        <div class="company-name">
          <router-link class="icon-dashboard" to="/">WOODAY</router-link>
        </div>

        <ul class="menu-item">
          <li>
            <router-link class="icon-dashboard" to="/dashboard">
              ダッシュボード
            </router-link>
          </li>
          <li>
            <router-link class="icon-diary" to="/reports">日報一覧</router-link>
          </li>
          <li>
            <router-link class="icon-manage" to="/work">作業管理</router-link>
          </li>
          <li>
            <router-link class="icon-member" to="/members">作業員</router-link>
          </li>
        </ul>
      </div>
      <div class="nav-right">
        <p class="nav-right-properties">
          現在の作業員数
          <br />
          {{ countOfWorkers }}名 / {{ maxOfWorkers ?? "-" }}名
        </p>
        <div class="nav-right-account dropdown">
          <a
            href=""
            target="_blank"
            class="icon-account"
            id="openMenu"
            v-on:click.prevent.stop="toggleAccountDropdown"
          >
            アカウント設定
          </a>
          <div class="dropdown-body" v-if="isShownAccountDropdown">
            <account-setting :dismiss="dismissDropdown" />
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script lang="ts">
import { useStore } from "@/store";
import { WorkerActionTypes } from "@/store/worker";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import AccountSetting from "./AccountSetting.vue";
import { AccountActionTypes } from "@/store/account";
import { useRoute } from "vue-router";

export default defineComponent({
  components: { AccountSetting },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const isShownAccountDropdown = ref(false);
    watch(
      () => route.fullPath,
      () => (isShownAccountDropdown.value = false)
    );
    onMounted(() => {
      store.dispatch(AccountActionTypes.FETCH_Account, undefined);
      store.dispatch(WorkerActionTypes.BIND_Worker, undefined);
    });

    return {
      isShownAccountDropdown,
      maxOfWorkers: computed(() => store.state.account?.maxWorkers),
      countOfWorkers: computed(
        () => store.state.workers.filter((w) => w.isVerified).length
      ),
      dismissDropdown: () => (isShownAccountDropdown.value = false),
      toggleAccountDropdown: () =>
        (isShownAccountDropdown.value = !isShownAccountDropdown.value),
    };
  },
});
</script>
