<template>
  <div id="closeModal" class="closeIconModal closeModal" v-on:click="hideModal">
    ×
  </div>
  <div class="modalContents">
    <h3>日報詳細：{{ format(report.workedDate) }}</h3>

    <div class="diary-content">
      <div class="photo-area report-image-scroll-container" v-show="hasImages">
        <!--   docs: https://antoniandre.github.io/vueper-slides/     -->
        <!--        slideImageInside-->
        <!--        A <div class="vueperslide__image"> will be created inside each slide.-->
        <!--        This will allow you to CSS transform the slides images with no impact on slideshow behavior.-->
        <vueper-slides
          ref="vueperslides1"
          :visible-slides="1"
          :slideRatio="3 / 2"
          :infinite="false"
          :bullets="false"
          :bulletsOutside="false"
          :arrows="true"
          :arrowsOutside="true"
          :touchable="true"
          :autoplay="false"
          :slideImageInside="true"
          fixedHeight="300px"
          @slide="
            $refs.vueperslides2.goToSlide($event.currentSlide.index, {
              emit: false,
            })
          "
        >
          <vueper-slide
            class="report-image-slider"
            v-for="(url, i) in imageUrls"
            :key="i"
            :image="url"
            :link="url"
          />
        </vueper-slides>
        <vueper-slides
          v-show="imageUrls.length > 1"
          class="no-shadow thumbnails"
          ref="vueperslides2"
          @slide="
            $refs.vueperslides1.goToSlide($event.currentSlide.index, {
              emit: false,
            })
          "
          :visible-slides="imageUrls.length"
          fixed-height="50px"
          :bullets="false"
          :touchable="false"
          :gap="1.5"
          :arrows="false"
        >
          <vueper-slide
            v-for="(url, i) in imageUrls"
            :key="i"
            :image="url"
            @click="$refs.vueperslides2.goToSlide(i)"
          ></vueper-slide>
        </vueper-slides>
        <div class="light-text-body mb-60">
          ※画像が表示されない場合、作業員の端末からアップロード完了していない可能性があります
        </div>
      </div>
      <ul class="diary-txt">
        <li>
          <span>報告者</span>

          {{ report.workerName }}
        </li>
        <li>
          <span>天気</span>
          {{ report.weatherAsJapanese }}
          <img
            src="../../assets/img/icon-sun.svg"
            class="ml-10"
            v-if="report.weather === 'sunny'"
          />
          <img
            src="../../assets/img/icon-cloud.svg"
            class="ml-10"
            v-if="report.weather === 'cloudy'"
          />
          <img
            src="../../assets/img/icon-rain.svg"
            class="ml-10"
            v-if="report.weather === 'rainy'"
          />
          <img
            src="../../assets/img/icon-snow.svg"
            class="ml-10"
            v-if="report.weather === 'snow'"
          />
        </li>
        <li>
          <span>現場</span>
          {{ report.workPlace.name }}
        </li>
        <li>
          <span>作業種別</span>
          {{ report.workOutPut?.workType?.name ?? "-" }}
        </li>
        <li>
          <span>成果</span>
          {{ report.workOutPut?.outcome ?? "-" }}
          {{
            report.workOutPut?.workType?.unit
              ? ` ${report.workOutPut?.workType?.unit}`
              : ""
          }}
        </li>
        <li>
          <span>作業時間</span>
          {{ report.workingTime }} 時間
        </li>
        <li>
          <span>機材</span>
          {{ report.machinesTextFull }}
        </li>
        <li>
          <span>共同作業者</span>
          {{ report.workersTextFull }}
        </li>
        <li>
          <span>メモ</span>
          {{ report.body }}
        </li>
      </ul>
    </div>
    <button class="closeModal mt-40 submit-button" v-on:click="hideModal">
      閉じる
    </button>
  </div>
</template>

<script lang="ts">
import { useStore } from "@/store";
import { useModal } from "../shared/useModal";
import { computed, defineComponent, onMounted, Ref, ref } from "vue";
import { ReportActionTypes } from "@/store/report";
import { storage } from "@/api/firebase";
import formatDate from "../shared/DateFormatter";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default defineComponent({
  components: { VueperSlides, VueperSlide },
  setup(props) {
    const store = useStore();
    const { hideModal } = useModal();
    const imageUrls: Ref<Array<string>> = ref([]);
    const imageUrlErrors: Ref<Array<string>> = ref([]);
    const hasImages = ref(false);
    onMounted(async () => {
      Promise.all(
        store.state.selectedReports?.imagePathes.map((path) =>
          storage
            .ref(path)
            .getDownloadURL()
            .catch((error) => imageUrlErrors.value.push(path))
        )
      ).then((urls) => {
        imageUrls.value = urls;
        hasImages.value = urls.length > 0;
        return;
      });
    });
    return {
      imageUrls,
      imageUrlErrors,
      hasImages,
      report: computed(() => store.state.selectedReports),
      format: (date: Date) => formatDate(date, "YYYY/MM/DD"),
      hideModal: () =>
        store.dispatch(ReportActionTypes.DE_SELECT_REPORT, undefined),
    };
  },
});
</script>

<style lang="scss" scoped></style>
