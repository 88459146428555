
import { useStore } from "@/store";
import { useModal } from "../shared/useModal";
import { WorkerActionTypes } from "@/store/worker";
import { defineComponent, onBeforeUpdate, onMounted, watch } from "vue";
import { AuthActionTypes } from "@/store/auth";

export default defineComponent({
  components: {},
  props: {
    dismiss: {
      type: Function,
    },
  },
  setup(props) {
    const store = useStore();
    const { showModal, hideModal, isVisible } = useModal();
    onBeforeUpdate(() => {});
    onMounted(() => {
      store.dispatch(WorkerActionTypes.BIND_Worker, undefined);
    });
    watch(isVisible, (newValue) => {
      if (!newValue) props.dismiss();
    });
    return {
      hideModal: () => hideModal(),
      onClickContract: () => showModal("account-contract-modal"),
      onClickSupport: () => showModal("account-support-modal"),
      onClickTerms: () => showModal("account-terms-modal"),
      onClickContact: () => showModal("account-contact-modal"),
      onClickCompany: () => window.open("https://www.hyakumori.com/"), //showModal("account-company-modal"),
      onClickLogout: () => store.dispatch(AuthActionTypes.LOGOUT, undefined),
    };
  },
});
