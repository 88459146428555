<template>
  <div id="closeModal" class="closeIconModal closeModal" v-on:click="hideModal">
    ×
  </div>

  <div class="modalContents">
    <h3>サポート案内</h3>
    <p>
      サービスをご利用いただき誠にありがとうございます。
      <br />
      ご不明点やお気づきの点がございましたら、
      <br />
      下記メールアドレスまでご連絡ください。
      <br />
      <br />
      <br />
      問い合わせ先：appdev@hyakumori.com
      <br />
      運営会社：株式会社百森
      <br />
    </p>
  </div>
</template>

<script lang="ts">
import { useStore } from "@/store";
import { useModal } from "../shared/useModal";
import { defineComponent, onMounted } from "vue";

export default defineComponent({
  setup(props) {
    const store = useStore();
    const { hideModal } = useModal();
    onMounted(() => {});
    return {
      hideModal: () => hideModal(),
    };
  },
});
</script>
