
import { useStore } from "@/store";
import { useModal } from "../shared/useModal";
import { computed, defineComponent, onMounted, ref } from "vue";
import { AccountActionTypes } from "@/store/account";
import { AuthActionTypes } from "@/store/auth";

export default defineComponent({
  setup(props) {
    const store = useStore();
    const { hideModal } = useModal();
    const isConfirmedMode = ref(true);
    const emailInput = ref();
    onMounted(async () => {
      await store.dispatch(AccountActionTypes.FETCH_Account, undefined);
    });
    return {
      isConfirmedMode,
      email: computed(() => store.state.currentUser?.email),
      emailInput,
      onClickPasswordReset: async () => {
        if (emailInput.value || store.state.currentUser?.email) {
          await store.dispatch(AuthActionTypes.RESET_PASSWORD, {
            email: emailInput.value,
          });
          isConfirmedMode.value = false;
        }
      },
      hideModal: () => hideModal(),
    };
  },
});
