import Dashboard from "./components/dashboard/Dashboard.vue";
import Login from "./components/login/Login.vue";
import WorkManagementTabLayout from "./components/management/WorkManagementTabLayout.vue";
import ReportList from "./components/report/ReportList.vue";
import Member from "./components/member/Member.vue";
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { ActionTypes, useStore } from "@/store";
import { AuthActionTypes } from "@/store/auth";
import firebase from "firebase";

const routes: RouteRecordRaw[] = [
  // { path: "/", component: Dashboard, meta: { requiresAuth: true } },
  { path: "/", redirect: "/dashboard" },
  { path: "/dashboard", component: Dashboard, meta: { requiresAuth: true } },
  { path: "/reports", component: ReportList, meta: { requiresAuth: true } },
  {
    path: "/work/:tabId",
    component: WorkManagementTabLayout,
    meta: { requiresAuth: true },
  },
  {
    path: "/work",
    component: WorkManagementTabLayout,
    meta: { requiresAuth: true },
    redirect: "/work/places",
  },
  { path: "/members", component: Member, meta: { requiresAuth: true } },
  { path: "/login", component: Login, meta: { requiresAuth: false } },
];

export const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// castしたいだけ
function isFirebaseUser(user: any): user is firebase.User {
  return user !== null && user !== undefined;
}

router.beforeEach(async (to, from, next) => {
  const store = useStore();
  const currentUser = store.state.currentUser;
  const redirectToLogin = () => {
    store.dispatch(ActionTypes.RECORD_REDIRECT_PATH, {
      path: to.fullPath ?? "/",
      query: to.query,
    });
    next({ path: "/login" });
  };
  store.dispatch(ActionTypes.HIDE_MODAL, undefined);
  // store.dispatch(ActionTypes.UN_SUBSCRIBE_ALL, undefined);
  await store.state.initialLoadingAsync.promise;

  let adminAuth = store.state.adminAuth;
  if (!currentUser)
    adminAuth = await store.dispatch(
      AuthActionTypes.REFRESH_ADMIN_AUTH,
      undefined
    );

  if (!to.meta.requiresAuth) next();
  else if (adminAuth) next();
  else if (!currentUser) redirectToLogin();
  else {
    return next();
  }
});

router.beforeEach((to) => {
  const store = useStore();
  const adminAuth = store.state.adminAuth;
  if (to.meta.requiresAuth && !adminAuth) return false;
  if (!to.meta.requiresAuth && adminAuth) return false;
  return true;
});
router.afterEach((to, from) => {});
