<template>
  <div class="data-header">
    <h2>作業機材別一覧</h2>
  </div>
  <table class="data-table" cellspacing="0" cellpadding="0">
    <thead>
      <tr>
        <th>機材</th>
        <th>作業時間</th>
        <th>日報数</th>
        <th>使用現場</th>
        <th>実施作業</th>
        <!--    登録日時はデータに残していない    -->
        <!--        <th>登録日時</th>-->
        <th>操作</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="machine in visibleItems" :key="machine.id">
        <td>{{ machine.name }}</td>
        <td>
          {{
            relatedReportWorkingTime[machine.id]?.reduce(
              (acc, x) => acc + x,
              0
            ) ?? 0
          }}時間
        </td>
        <td>
          <a href="" v-on:click.prevent.stop="moveToReport(machine.id)">
            {{ relatedReport[machine.id]?.length ?? 0 }} 件
          </a>
        </td>
        <td>
          <a href="" v-on:click.prevent.stop="moveToPlace(machine.id)">
            {{ relatedPlace[machine.id]?.length ?? 0 }} 現場
          </a>
        </td>
        <td>
          <a href="" v-on:click.prevent.stop="moveToWorkType(machine.id)">
            {{ relatedWorkType[machine.id]?.length ?? 0 }} 項目
          </a>
        </td>
        <!--        <td>2021/3/{{ k + 1 }}</td>-->
        <td>
          <a
            href="#"
            class="openModal"
            v-on:click.prevent.stop="onClickDetail(machine)"
          >
            編集
          </a>
        </td>
      </tr>
    </tbody>
  </table>
  <pagination
    v-show="isNeededPagination"
    :max-length="maxLength"
    :items-limit-per-page="itemsLimitPerPage"
    :on-change-page-index="onChangePageIndex"
  />
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "@/store";
import { MachineActionTypes } from "@/store/machine";
import { useRouter } from "vue-router";
import { Machine } from "@/models/Machine";
import { useModal } from "../shared/useModal";
import Pagination, {
  DEFAULT_ITEMS_LIMIT_PER_PAGE,
} from "@/components/shared/Pagination.vue";
import { REPORT_DEFAULT_ITEMS_LIMIT_PER_PAGE } from "@/store/report";

export default defineComponent({
  components: { Pagination },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const { showModal } = useModal();

    const currentPage = ref(0);
    const visibleFirstOffset = ref(0);
    const visibleItems = computed(() =>
      store.state.machines.slice(
        visibleFirstOffset.value,
        visibleFirstOffset.value + REPORT_DEFAULT_ITEMS_LIMIT_PER_PAGE
      )
    );
    const isNeededPagination = computed(
      () => store.state.machines.length > REPORT_DEFAULT_ITEMS_LIMIT_PER_PAGE
    );
    onMounted(() => {
      store.dispatch(MachineActionTypes.BIND_Machine, {
        isFetchRelatedStat: true,
      });
    });
    return {
      visibleItems,
      isNeededPagination,
      maxLength: computed(() => store.state.machines.length),
      itemsLimitPerPage: computed(() => DEFAULT_ITEMS_LIMIT_PER_PAGE),
      onChangePageIndex: (newIndex, newOffset) => {
        currentPage.value = newIndex;
        visibleFirstOffset.value = newOffset;
      },

      relatedReportWorkingTime: computed(
        () => store.state.machine_RelatedReportWorkingTimes
      ),
      relatedReport: computed(() => store.state.machine_RelatedReportIds),
      relatedPlace: computed(() => store.state.machine_RelatedPlaceIds),
      relatedWorkType: computed(() => store.state.machine_RelatedWorkTypeIds),

      moveToReport: (machineId: string) =>
        router.push({ path: "/reports", query: { machineIds: [machineId] } }),
      moveToPlace: (machineId: string) =>
        router.push({
          path: "/reports",
          query: {
            machineIds: [machineId],
            placeIds: store.state.machine_RelatedPlaceIds[machineId],
          },
        }),
      moveToWorkType: (machineId: string) =>
        router.push({
          path: "/reports",
          query: {
            machineIds: [machineId],
            workTypeIds:
              store.state.machine_RelatedWorkTypeIds[machineId] ?? [],
          },
        }),
      onClickDetail: (machine: Machine) => {
        store.dispatch(MachineActionTypes.SELECT_Machine, { machine });
        showModal("MachineDetailModal");
      },
    };
  },
});
</script>
