
import { defineComponent, ref } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  props: {
    onChangeFilter: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const onlyVerified = ref(false);
    return {
      onlyVerified,
      onClickAdd: () => {
        // store.dispatch(WorkerActionTypes.CREATE_Worker);
      },
    };
  },
});
