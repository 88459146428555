<template>
  <div class="modalContents">
    <h3>利用規約</h3>
  </div>
</template>

<script lang="ts">
import { useStore } from "@/store";
import { useModal } from "../shared/useModal";
import { defineComponent, onMounted } from "vue";

export default defineComponent({
  setup(props) {
    const store = useStore();
    const { hideModal } = useModal();
    onMounted(() => {});
    return {
      hideModal: () => hideModal(),
    };
  },
});
</script>
