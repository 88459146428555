/** @format */

import { Machine } from "./Machine";
import { WorkPlace } from "./WorkPlace";
import { WorkType } from "./WorkType";

export class WorkOutPut {
  outcome?: number;
  workType: WorkType;
  constructor(obj: any) {
    this.outcome = obj.outcome;
    this.workType = new WorkType(obj.workType.id, obj.workType);
  }
}

export class CoWorker {
  uid: string;
  name: string;
  constructor(obj: any) {
    this.uid = obj.uid;
    this.name = obj.name;
  }

  get asJson() {
    return {
      uid: this.uid,
      name: this.name,
    };
  }
}

export class Report {
  reportId: string;
  body: string;
  workedDate: Date;
  createdTime: string;
  workOutPut: WorkOutPut;
  workPlace: WorkPlace;
  workingTime: string;
  workerName: string;
  workerId: string;
  weather: string;
  machines: Machine[] | null;
  workers: CoWorker[] | null;
  imagePathes: string[];

  get machinesCount(): number {
    return this.machines === null ? 0 : this.machines.length;
  }
  get machinesText() {
    if (this.machinesCount === 0) {
      return "未登録";
    } else if (this.machinesCount < 3) {
      return `${this.machines?.map((w) => w.name) ?? "未登録"}`;
    } else {
      const sliced = this.machines?.slice(0, 2);
      return sliced === undefined
        ? "未登録"
        : `${sliced.map((m) => m.name).join(", ")} +(${
            this.machinesCount - sliced.length
          }台)`;
    }
  }
  get machinesTextFull() {
    if (this.machinesCount === 0) {
      return "-";
    } else {
      return `${this.machines?.map((w) => w.name) ?? "未登録"}`;
    }
  }
  get workersCount(): number {
    return this.workers === null ? 0 : this.workers.length;
  }
  get workersText() {
    if (this.workersCount === 0) {
      return "未登録";
    } else if (this.workersCount < 3) {
      return `${this.workers?.map((w) => w.name) ?? "未登録"}`;
    } else {
      const sliced = this.workers?.slice(0, 2);
      return sliced === undefined
        ? "未登録"
        : `${sliced.map((w) => w.name).join(", ")} +(${
            this.workersCount - sliced.length
          }名)`;
    }
  }
  get workersTextFull() {
    if (this.workersCount === 0) {
      return "未登録";
    } else if (this.workersCount < 3) {
      return `${this.workers?.map((w) => w.name) ?? "未登録"}`;
    }
  }
  constructor(obj: any) {
    this.reportId = obj.reportId;
    this.body = obj.body;
    this.workedDate = new Date(obj.workedDate.seconds * 1000);
    this.createdTime = obj.createdTime;
    this.workOutPut = new WorkOutPut(obj.workOutPut);
    this.workPlace = new WorkPlace(obj.workPlace.id, obj.workPlace);
    this.workingTime = obj.workingTime;
    this.workerName = obj.workerName;
    this.workerId = obj.workerId;
    this.weather = obj.weather ?? "";
    this.machines = obj.machines
      ? (obj.machines as Object[]).map((json) => new Machine(json))
      : null;
    this.workers = obj.workers
      ? (obj.workers as Object[]).map((json) => new CoWorker(json))
      : null;
    this.imagePathes = obj.imagePathes
      ? (obj.imagePathes as Object[]).map((json: any) => {
          return json.storegePath;
        })
      : [];
    // this.imagePathes = obj.imagePathes ? (obj.imagePathes as string[]) : [];
    // console.log(`imagePathes: ${this.imagePathes})`);
  }

  get weatherAsJapanese(): string {
    switch (this.weather) {
      case "cloudy":
        return "曇り";
      case "snow":
        return "雪";
      case "rainy":
        return "雨";
      case "sunny":
        return "晴れ";
      default:
        return "未入力";
    }
  }
}
