import { ActionTree } from "vuex";
import { db } from "../api/firebase";
import { OrganizationWorker } from "@/models/OrganizationWorker";
import { AugmentedActionContext } from "./types/actions";
import { State } from ".";
import { FunctionsAPI } from "@/api/functions";
import { ReportMutationTypes } from "@/store/report";

export interface WorkerState {
  workers: OrganizationWorker[];
  // 承認待ちのユーザー
  preWorkers: OrganizationWorker[];
  selectedWorker: OrganizationWorker | null;
  unsubscribeWorkers: () => void | null;
  unsubscribePreWorkers: () => void | null;
}

/** Mutations */

export enum WorkerMutationTypes {
  SET_SELECTED_WORKER = "WorkerMutationTypes/SET_SELECTED_WORKER",
  SET_WORKERS = "WorkerMutationTypes/SET_WORKERs",
  SET_PRE_WORKERS = "WorkerMutationTypes/SET_PRE_WORKERS",
  SET_UN_SUBSCRIBE = "WorkerMutationTypes/SET_UN_SUBSCRIBE",
  SET_UN_SUBSCRIBE_PRE = "WorkerMutationTypes/SET_UN_SUBSCRIBE_PRE",
}

export type WorkerMutations<S = State> = {
  [WorkerMutationTypes.SET_SELECTED_WORKER](
    state: S,
    payload: { worker: OrganizationWorker | null }
  ): void;
  [WorkerMutationTypes.SET_WORKERS](
    state: S,
    payload: { workers: OrganizationWorker[] }
  ): void;
  [WorkerMutationTypes.SET_PRE_WORKERS](
    state: S,
    payload: { preWorkers: OrganizationWorker[] }
  ): void;
  [WorkerMutationTypes.SET_UN_SUBSCRIBE](
    state: S,
    payload: { unSubscribe: () => void }
  ): void;
  [WorkerMutationTypes.SET_UN_SUBSCRIBE_PRE](
    state: S,
    payload: { unSubscribe: () => void }
  ): void;
};

export const workerMutation: WorkerMutations = {
  [WorkerMutationTypes.SET_SELECTED_WORKER](state, { worker }): void {
    state.selectedWorker = worker;
  },
  [WorkerMutationTypes.SET_WORKERS](state, { workers }): void {
    state.workers = workers;
  },
  [WorkerMutationTypes.SET_PRE_WORKERS](state, { preWorkers }): void {
    state.preWorkers = preWorkers;
  },
  [WorkerMutationTypes.SET_UN_SUBSCRIBE](state, { unSubscribe }): void {
    state.unsubscribeWorkers = unSubscribe;
  },
  [WorkerMutationTypes.SET_UN_SUBSCRIBE_PRE](state, { unSubscribe }): void {
    state.unsubscribePreWorkers = unSubscribe;
  },
};

/** Actions  */

export enum WorkerActionTypes {
  BIND_Worker = "WorkerActionTypes/BIND_Worker",
  FETCH_Worker = "WorkerActionTypes/FETCH_Worker",
  FETCH_Worker_IF_NEEDED = "WorkerActionTypes/FETCH_Worker_IF_NEEDED",
  BIND_PreWorker = "WorkerActionTypes/BIND_PreWorker",
  FETCH_PreWorker = "WorkerActionTypes/FETCH_PreWorker",
  SELECT_Worker = "WorkerActionTypes/SELECT_Worker",
  DE_SELECT_Worker = "WorkerActionTypes/DE_SELECT_Worker",
  CREATE_Worker = "WorkerActionTypes/CREATE_Worker",
  ENABLE_Worker = "WorkerActionTypes/ENABLE_Worker",
  DISABLE_Worker = "WorkerActionTypes/DISABLE_Worker",
}

export interface WorkerActions {
  [WorkerActionTypes.BIND_Worker]({ commit }: AugmentedActionContext): void;
  [WorkerActionTypes.FETCH_Worker]({
    commit,
  }: AugmentedActionContext): Promise<void>;
  [WorkerActionTypes.FETCH_Worker_IF_NEEDED]({
    commit,
  }: AugmentedActionContext): Promise<void>;
  [WorkerActionTypes.BIND_PreWorker]({ commit }: AugmentedActionContext): void;
  [WorkerActionTypes.FETCH_PreWorker]({
    commit,
  }: AugmentedActionContext): Promise<void>;

  [WorkerActionTypes.SELECT_Worker](
    { commit }: AugmentedActionContext,
    payload: { worker: OrganizationWorker }
  ): void;
  [WorkerActionTypes.DE_SELECT_Worker]({
    commit,
  }: AugmentedActionContext): void;
  [WorkerActionTypes.ENABLE_Worker](
    { commit }: AugmentedActionContext,
    payload: { workerId: string }
  ): Promise<void>;
  [WorkerActionTypes.DISABLE_Worker](
    { commit }: AugmentedActionContext,
    payload: { workerId: string }
  ): Promise<void>;
}

export const workerAction: ActionTree<State, State> & WorkerActions = {
  [WorkerActionTypes.SELECT_Worker]({ commit }, { worker }) {
    commit(WorkerMutationTypes.SET_SELECTED_WORKER, { worker });
  },
  [WorkerActionTypes.DE_SELECT_Worker]({
    commit,
  }: AugmentedActionContext): void {
    commit(WorkerMutationTypes.SET_SELECTED_WORKER, { worker: null });
  },
  async [WorkerActionTypes.ENABLE_Worker]({ commit }, { workerId }) {
    return FunctionsAPI.enableWorker(workerId);
  },
  async [WorkerActionTypes.DISABLE_Worker]({ commit }, { workerId }) {
    return FunctionsAPI.disableWorker(workerId);
  },
  async [WorkerActionTypes.BIND_Worker]({ state, commit }) {
    const organizationId = state.organizationId;
    if (!organizationId) return;
    if (state.unsubscribeWorkers) return;
    const unSubscribe = db
      .collection("organizations")
      .doc(organizationId)
      .collection("workers")
      .orderBy("name")
      .onSnapshot((result) => {
        state.workers = result.docs.map(
          (d) => new OrganizationWorker(d.data())
        );
      });
    commit(WorkerMutationTypes.SET_UN_SUBSCRIBE, { unSubscribe });
  },
  async [WorkerActionTypes.FETCH_Worker]({ state, commit }) {
    const organizationId = state.organizationId;
    if (!organizationId) return;
    return db
      .collection("organizations")
      .doc(organizationId)
      .collection("workers")
      .orderBy("name")
      .get()
      .then((result) => {
        state.workers = result.docs.map(
          (d) => new OrganizationWorker(d.data())
        );
      });
  },
  async [WorkerActionTypes.FETCH_Worker_IF_NEEDED]({ state, dispatch }) {
    const organizationId = state.organizationId;
    if (!organizationId) return;
    if (state.workers.length > 0) return;
    dispatch(WorkerActionTypes.FETCH_Worker, undefined);
  },
  async [WorkerActionTypes.BIND_PreWorker]({ state, commit }) {
    const organizationId = state.organizationId;
    if (!organizationId) return;
    if (state.unsubscribePreWorkers) return;
    // TODO: 権限設定
    return;
    const unSubscribe = db
      .collection("organizations")
      .doc(organizationId)
      .collection("preworkers")
      .onSnapshot((result) => {
        const preWorkers = result.docs.map(
          (d) => new OrganizationWorker(d.data())
        );
        commit(WorkerMutationTypes.SET_PRE_WORKERS, { preWorkers });
      });
    commit(WorkerMutationTypes.SET_UN_SUBSCRIBE_PRE, { unSubscribe });
  },
  async [WorkerActionTypes.FETCH_PreWorker]({ state, commit }) {
    const organizationId = state.organizationId;
    if (!organizationId) return;
    return db
      .collection("organizations")
      .doc(organizationId)
      .collection("preworkers")
      .get()
      .then((result) => {
        const preWorkers = result.docs.map(
          (d) => new OrganizationWorker(d.data())
        );
        commit(WorkerMutationTypes.SET_PRE_WORKERS, { preWorkers });
      });
  },
};
