<template>
  <div id="closeModal" class="closeIconModal closeModal" v-on:click="hideModal">
    ×
  </div>
  <div class="modalContents" v-if="deletingMode">
    <div class="modalContents">
      <h3>削除する：{{ selectedWorkTypeName }}</h3>
      <!--      <ul class="radio-list-modal">-->
      <!--        <li>-->
      <!--          <input-->
      <!--            type="radio"-->
      <!--            v-model="isMoveToOther"-->
      <!--            id="radiotrue"-->
      <!--            :value="true"-->
      <!--          />-->
      <!--      <label for="radiotrue" class="radio">-->
      <div class="target-select-container">
        <span>他の現場に振り分けて削除する</span>
        <select
          class="target-select"
          v-model="selectedMoveTo"
          :disabled="!isMoveToOther"
          id="select"
        >
          <option disabled value="">選択して下さい</option>
          <option
            v-for="workType in workTypes"
            :value="workType"
            :key="workType.id"
          >
            {{ workType.name }}
          </option>
        </select>
      </div>
      <!--        <input-->
      <!--          type="text"-->
      <!--          name=""-->
      <!--          id="newWorkTypeName"-->
      <!--          v-model="newWorkTypeName"-->
      <!--          class="ml-20"-->
      <!--        />-->
      <!--      </label>-->
      <!--        </li>-->
      <!--        <li>-->
      <!--          <input-->
      <!--            type="radio"-->
      <!--            class="radio"-->
      <!--            v-model="isMoveToOther"-->
      <!--            :value="false"-->
      <!--            id="radiofalse"-->
      <!--          />-->
      <!--          <label for="radiofalse" class="radio">-->
      <!--            他の作業に振り分けない（作業をクローズする）-->
      <!--          </label>-->
      <!--        </li>-->
      <!--      </ul>-->
      <div v-show="errorMessage" class="form-error-left">
        {{ errorMessage }}
      </div>
      <button
        v-if="!isLoading"
        class="closeModal mt-40 submit-button"
        @click="onClickDelete"
      >
        {{ isMoveToOther ? "削除" : "クローズ" }}
      </button>
      <div v-else class="loader-small">Loading...</div>
    </div>
  </div>

  <div class="modalContents" v-else>
    <div class="modalContents">
      <h3>編集：{{ selectedWorkTypeName }}</h3>
      <p>
        作業名：
        <input
          type="text"
          name=""
          id="newWorkTypeName"
          v-model="editingNewWorkTypeName"
        />
      </p>
      <p>
        単位：
        <input
          type="text"
          name=""
          id="newWorkTypeUnit"
          v-model="editingNewWorkTypeUnit"
        />
      </p>
      <div v-show="errorMessage" class="form-error-left">
        {{ errorMessage }}
      </div>
      <button
        v-if="!isLoading"
        class="closeModal mt-40 submit-button"
        @click="applyChanges"
      >
        変更
      </button>
      <div v-else class="loader-small">Loading...</div>
      <p>
        <a
          href="#"
          class="deleteModal"
          v-on:click.prevent.stop="toDeletingMode"
        >
          削除
        </a>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from "@/store";
import { useModal } from "../shared/useModal";
import { computed, defineComponent, onMounted, ref } from "vue";
import logger from "@/loogger";
import { FunctionsAPI } from "@/api/functions";
import { WorkTypeActionTypes } from "@/store/workType";

export default defineComponent({
  setup(props) {
    const store = useStore();
    const isLoading = ref(false);
    const errorMessage = ref();
    const deletingMode = ref(false);
    const isMoveToOther = ref(true);
    const selectedMoveTo = ref();
    const editingNewWorkTypeName = ref();
    const editingNewWorkTypeUnit = ref();
    const { hideModal } = useModal();
    const execCloseWorkType = () => {
      // TODO: Close処理
    };
    const execBindOtherWorkType = async () => {
      if (!selectedMoveTo.value || !selectedMoveTo.value.id) {
        errorMessage.value = "選択されていません";
        return;
      }
      errorMessage.value = "";
      isLoading.value = true;
      const selectedWorkTypeId = store.state.selectedWorkType.id;
      const moveToWorkTypeId = selectedMoveTo.value.id;
      await FunctionsAPI.mergeWorkType(selectedWorkTypeId, moveToWorkTypeId)
        .then(() => {
          isLoading.value = false;
          hideModal();
        })
        .catch(() => {
          isLoading.value = false;
          errorMessage.value = "紐付けに失敗しました。";
        });
    };
    onMounted(() => {
      editingNewWorkTypeName.value = store.state.selectedWorkType?.name;
      editingNewWorkTypeUnit.value = store.state.selectedWorkType?.unit;
    });
    return {
      deletingMode,
      errorMessage,
      isLoading,
      selectedMoveTo,
      editingNewWorkTypeName,
      editingNewWorkTypeUnit,
      isMoveToOther,
      selectedWorkTypeName: computed(() => store.state.selectedWorkType?.name),
      workTypes: computed(() =>
        store.state.workTypes.filter(
          (w) => w.id !== store.state.selectedWorkType?.id
        )
      ),
      toDeletingMode: () => {
        deletingMode.value = true;
      },
      onClickDelete: () => {
        if (isMoveToOther.value) {
          execBindOtherWorkType();
        } else {
          execCloseWorkType();
        }
      },
      applyChanges: async () => {
        if (
          !editingNewWorkTypeName.value ||
          editingNewWorkTypeName.value.length < 1
        ) {
          errorMessage.value = "入力されていません。";
          return;
        }
        errorMessage.value = "";
        isLoading.value = true;
        const workTypeId = store.state.selectedWorkType.id;
        const newName = editingNewWorkTypeName.value;
        const newUnit = editingNewWorkTypeUnit.value;
        try {
          await store.dispatch(WorkTypeActionTypes.CHANGE_NAME_WorkType, {
            id: workTypeId,
            newName,
            newUnit,
          });
          isLoading.value = false;
          hideModal();
        } catch (error) {
          logger.error("名前の変更に失敗しました", error);
          isLoading.value = false;
          if (error === "duplicated.name") {
            errorMessage.value = "名前が重複しています。";
          } else {
            errorMessage.value = "名前の変更に失敗しました。";
          }
        }
      },
      hideModal: () => hideModal(),
    };
  },
});
</script>
