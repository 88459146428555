import { ActionTypes, useStore } from "@/store";
import { InjectionKey } from "@vue/runtime-core";
import { computed, Ref } from "vue";

interface ModalAction {
  isVisible: Ref<boolean>;
  componentName: Ref<string>;
  showModal(componentName: string): void;
  hideModal(): void;
}

export function useModal(): ModalAction {
  const store = useStore();
  return {
    isVisible: computed(() => store.state.modalComponent !== null),
    componentName: computed(() => store.state.modalComponent ?? ""),
    showModal: (componentName) => {
      store.dispatch(ActionTypes.HIDE_MODAL, undefined);
      store.dispatch(ActionTypes.SHOW_MODAL, { modalComponent: componentName });
    },
    hideModal: () => {
      store.dispatch(ActionTypes.HIDE_MODAL, undefined);
    },
  };
}
