<template>
  <span class="container" id="rightside">
    <header>
      <section class="content-title">
        <h1>作業員管理</h1>
      </section>
    </header>
    <aside>
      <create-worker-form :on-change-filter="onChangeFilter" />
    </aside>
    <main>
      <div class="main-inner">
        <div class="data-header">
          <h2>作業員一覧</h2>
        </div>
        <table class="data-table" cellspacing="0" cellpadding="0">
          <thead>
            <tr>
              <th>ID</th>
              <th>名前</th>
              <!-- <th>総作業時間</th> -->
              <!-- <th>日報数</th> -->
              <th>状態</th>
              <!-- <th>登録日時</th> -->
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in visibleItems" :key="item.workerId">
              <td>{{ item.workerId }}</td>
              <td>{{ item.name }}</td>
              <td>
                {{
                  isPreWorker(item)
                    ? "承認待ち"
                    : item.isVerified
                    ? "稼働中"
                    : "無効"
                }}
              </td>
              <!-- <td>{{ format(item.workedDate) }}</td> -->
              <td>
                <a
                  href="#"
                  class="openModal"
                  v-on:click.prevent.stop="onClickDetail(item)"
                >
                  編集
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination
          v-show="isNeededPagination"
          :max-length="maxLength"
          :items-limit-per-page="itemsLimitPerPage"
          :on-change-page-index="onChangePageIndex"
        />
      </div>
    </main>
  </span>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "@/store";
import { WorkerActionTypes } from "@/store/worker";
import { useModal } from "../shared/useModal";
import { OrganizationWorker } from "@/models/OrganizationWorker";
import CreateWorkerForm from "@/components/member/CreateWorkerForm.vue";
import Pagination, {
  DEFAULT_ITEMS_LIMIT_PER_PAGE,
} from "@/components/shared/Pagination.vue";

export default defineComponent({
  components: { CreateWorkerForm, Pagination },
  setup(props) {
    const store = useStore();
    const { showModal } = useModal();
    const onlyVerifiedWorkers = ref(false);
    const currentPage = ref(0);
    const visibleFirstOffset = ref(0);
    const visibleItems = computed(() =>
      store.state.preWorkers
        .filter((w) => !onlyVerifiedWorkers.value)
        .concat(
          store.state.workers.filter((w) =>
            onlyVerifiedWorkers.value ? w.isVerified : true
          )
        )
        .slice(
          visibleFirstOffset.value,
          visibleFirstOffset.value + DEFAULT_ITEMS_LIMIT_PER_PAGE
        )
    );
    const isNeededPagination = computed(
      () => store.state.workers.length > DEFAULT_ITEMS_LIMIT_PER_PAGE
    );

    onMounted(() => {
      store.dispatch(WorkerActionTypes.BIND_Worker, undefined);
    });
    return {
      visibleItems,
      isNeededPagination,
      maxLength: computed(() => store.state.workers.length),
      itemsLimitPerPage: computed(() => DEFAULT_ITEMS_LIMIT_PER_PAGE),
      onChangePageIndex: (newIndex, newOffset) => {
        currentPage.value = newIndex;
        visibleFirstOffset.value = newOffset;
      },

      isPreWorker: (worker: OrganizationWorker) =>
        store.state.preWorkers.some((x) => x.workerId === worker.workerId),
      onChangeFilter: (onlyVerified: boolean) => {
        onlyVerifiedWorkers.value = onlyVerified;
      },
      onClickDetail: (worker: OrganizationWorker) => {
        store.dispatch(WorkerActionTypes.SELECT_Worker, { worker: worker });
        showModal("MemberDetailModal");
      },
    };
  },
});
</script>
