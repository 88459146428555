export class OrganizationWorker {
  uid: string;
  workerId: string;
  name: string;
  email: string;
  isVerified: boolean;
  constructor(obj: any) {
    this.uid = obj.uid;
    this.workerId = obj.workerId ?? obj.uid;
    this.name = obj.name;
    this.email = obj.email;
    this.isVerified = obj.isVerified;
  }
}
