import { ActionTree } from "vuex";
import { db } from "@/api/firebase";
import { CoWorker, Report } from "@/models/Report";
import { AugmentedActionContext } from "./types/actions";
import { ActionTypes, State } from ".";
import "firebase/firestore";
import { LocationQuery } from "vue-router";
import formatDate from "@/components/shared/DateFormatter";
import firebase from "firebase";
import DocumentData = firebase.firestore.DocumentData;
import Query = firebase.firestore.Query;
import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;
import logger from "@/loogger";

export interface ReportState {
  reports: Report[];
  existsNextReport: boolean;
  nextReportLoading: boolean;
  nextQuery: (() => Query<DocumentData>) | null;
  nextStartAtObj: QueryDocumentSnapshot<DocumentData> | null;
  rangedReport: Report[] | null; // 日付範囲で指定されたReport。存在しないときはnull
  selectedReports: Report | null; // 一つ指定されたReport。モーダル表示されている
  unsubscribeReports: () => void | null;
  unsubscribeRangedReports: () => void | null;
}

/** Mutations */

export enum ReportMutationTypes {
  SET_REPORT = "ReportMutationTypes/SET_REPORT",
  SET_SELECTED_REPORT = "ReportMutationTypes/SET_SELECTED_REPORT",
  SET_UN_SUBSCRIBE = "ReportMutationTypes/BIND_AUTH_SUBSCRIBE",
}

export type ReportMutations<S = State> = {
  [ReportMutationTypes.SET_REPORT](
    state: S,
    payload: { reports: Report[] }
  ): void;
  [ReportMutationTypes.SET_SELECTED_REPORT](
    state: S,
    payload: { report: Report }
  ): void;
  [ReportMutationTypes.SET_UN_SUBSCRIBE](
    state: S,
    payload: { unSubscribe: () => void }
  ): void;
};

export const reportMutations: ReportMutations = {
  [ReportMutationTypes.SET_REPORT](state, { reports }): void {
    state.reports = reports;
  },
  [ReportMutationTypes.SET_SELECTED_REPORT](state, { report }): void {
    state.selectedReports = report;
  },
  [ReportMutationTypes.SET_UN_SUBSCRIBE](state, { unSubscribe }): void {
    state.unsubscribeReports = unSubscribe;
  },
};

/** Actions  */

export enum ReportActionTypes {
  BIND_Report = "Report/BIND_Report",
  FETCH_Next_Report = "Report/FETCH_Next_Report",
  FETCH_Report = "Report/FETCH_Report",
  SELECT_REPORT = "Report/SELECT_REPORT",
  DE_SELECT_REPORT = "Report/DE_SELECT_REPORT",
  CHANGE_WORKER_NAME = "Report/CHANGE_WORKER_NAME",
  CHANGE_CO_WORKER_NAME = "Report/CHANGE_CO_WORKER_NAME",
}

export interface ReportActions {
  [ReportActionTypes.SELECT_REPORT](
    { commit }: AugmentedActionContext,
    payload: { report: Report }
  ): void;
  [ReportActionTypes.DE_SELECT_REPORT]({
    commit,
  }: AugmentedActionContext): void;
  [ReportActionTypes.BIND_Report](
    { commit }: AugmentedActionContext,
    payload: ReportSearchQuery | undefined
  ): Promise<void>;
  [ReportActionTypes.FETCH_Report](
    { commit }: AugmentedActionContext,
    payload: ReportSearchQuery | undefined
  ): Promise<Report[]>;
  [ReportActionTypes.FETCH_Next_Report](
    { commit }: AugmentedActionContext,
    payload: ReportSearchQuery | undefined
  ): Promise<void>;
  [ReportActionTypes.CHANGE_WORKER_NAME](
    { commit }: AugmentedActionContext,
    payload: { reportId: string; workerName: string }
  );
  [ReportActionTypes.CHANGE_CO_WORKER_NAME](
    { commit }: AugmentedActionContext,
    payload: { report: Report; workerId: string; workerName: string }
  );
}

export class ReportSearchQuery {
  start: Date | null;
  end: Date | null;
  workerIds: string[] | null;
  placeIds: string[] | null;
  machineIds: string[] | null;
  workTypeIds: string[] | null;
  next: string | null;
  constructor(
    query: LocationQuery,
    workerIds: string[] | null,
    placeIds: string[] | null,
    machineIds: string[] | null,
    workTypeIds: string[] | null
  ) {
    this.start = query.start ? new Date(`${query.start}`) : null;
    this.end = query.end ? new Date(`${query.end}`) : null;
    this.workerIds = workerIds;
    this.placeIds = placeIds;
    this.machineIds = machineIds;
    this.workTypeIds = workTypeIds;
    this.next = query.nextId ? (query.nextId as string) : null;
  }
  existElem = (arr: any[] | undefined | null): boolean =>
    arr ? arr.length > 0 : false;

  get asQueryObj(): any {
    return {
      ...(this.start ? { start: formatDate(this.start, "YYYY-MM-DD") } : {}),
      ...(this.end ? { end: formatDate(this.end, "YYYY-MM-DD") } : {}),
      ...(this.existElem(this.workerIds) ? { workerIds: this.workerIds } : {}),
      ...(this.existElem(this.placeIds) ? { placeIds: this.placeIds } : {}),
      ...(this.existElem(this.machineIds)
        ? { machineIds: this.machineIds }
        : {}),
      ...(this.existElem(this.workTypeIds)
        ? { workTypeIds: this.workTypeIds }
        : {}),
    };
  }
}

export const REPORT_DEFAULT_ITEMS_LIMIT_PER_PAGE = 40;
export const NEEDED_MINIMUM_ITEM_SIZE_PER_REQUEST = 3;

const reportFilterBy = (
  query: ReportSearchQuery,
  state: State
): ((report: Report) => boolean) => (report) => {
  const findWorkPlaceId = (name: string): string =>
    state.workPlaces.find((p) => p.name === name)?.id ?? "";

  if (!query) return true;

  let isAccepted = true;
  // workerはqueryで絞り込む
  if (query.workerIds && query.workerIds.length > 0)
    isAccepted = query.workerIds.includes(report.workerId);

  if (isAccepted && query.placeIds) {
    const placeId = findWorkPlaceId(report.workPlace.name);
    isAccepted = query.placeIds.includes(placeId);
  }

  if (isAccepted && query.workTypeIds)
    isAccepted = report.workOutPut?.workType?.id
      ? query.workTypeIds.includes(report.workOutPut.workType.id)
      : false;

  if (isAccepted && query.machineIds)
    isAccepted = query.machineIds.some((m) =>
      report.machines?.map((x) => x.id).includes(m)
    );

  return isAccepted;
};

export const reportAction: ActionTree<State, State> & ReportActions = {
  [ReportActionTypes.SELECT_REPORT]({ commit, dispatch }, { report }) {
    commit(ReportMutationTypes.SET_SELECTED_REPORT, { report });
    dispatch(ActionTypes.SHOW_MODAL, { modalComponent: "report-detail-modal" });
  },
  [ReportActionTypes.DE_SELECT_REPORT]({ commit, dispatch }) {
    commit(ReportMutationTypes.SET_SELECTED_REPORT, { report: null });
    dispatch(ActionTypes.HIDE_MODAL, { modalComponent: "report-detail-modal" });
  },

  async [ReportActionTypes.BIND_Report]({ state, commit, dispatch }, query) {
    const organizationId = state.organizationId;
    if (!organizationId) return;

    state.nextReportLoading = true;
    if (query) {
      // if (state.unsubscribeRangedReports) state.unsubscribeRangedReports();
      logger.log("#onQuery", query.asQueryObj);
      const collectionBuilder = () => {
        let collection = db
          .collection("organizations")
          .doc(organizationId)
          .collection("reports")
          .where("workedDate", ">=", query.start ?? new Date(0))
          .where("workedDate", "<=", query.end ?? new Date(8640000000000));
        if (query.workerIds && query.workerIds.length > 0)
          collection = collection.where("workerId", "in", query.workerIds);
        return collection.orderBy("workedDate", "desc").orderBy("reportId");
      };

      // const unSubscribe =
      await collectionBuilder()
        .limit(REPORT_DEFAULT_ITEMS_LIMIT_PER_PAGE + 1)
        .get()
        .then((result) => {
          const newReports = result.docs
            .slice(0, REPORT_DEFAULT_ITEMS_LIMIT_PER_PAGE)
            .map((d) => new Report(d.data()))
            .filter(reportFilterBy(query, state));
          state.existsNextReport =
            result.size > REPORT_DEFAULT_ITEMS_LIMIT_PER_PAGE;
          if (state.existsNextReport) {
            state.nextQuery = collectionBuilder;
            state.nextStartAtObj = result.docs[result.docs.length - 1];
          } else {
            state.nextQuery = null;
            state.nextStartAtObj = null;
          }
          state.rangedReport = newReports;
          if (
            state.existsNextReport &&
            newReports.length <= NEEDED_MINIMUM_ITEM_SIZE_PER_REQUEST
          ) {
            return dispatch(ReportActionTypes.FETCH_Next_Report, query);
          }
        })
        .finally(() => (state.nextReportLoading = false));
      // commit(ReportMutationTypes.SET_UN_SUBSCRIBE, { unSubscribe });
    } else {
      // if (state.unsubscribeReports != null) state.unsubscribeReports();
      const collection = () =>
        db
          .collection("organizations")
          .doc(organizationId)
          .collection("reports")
          .orderBy("workedDate", "desc")
          .orderBy("reportId");
      // const unSubscribe =
      await collection()
        .limit(REPORT_DEFAULT_ITEMS_LIMIT_PER_PAGE + 1)
        .get()
        .then((result) => {
          state.existsNextReport =
            result.size > REPORT_DEFAULT_ITEMS_LIMIT_PER_PAGE;
          if (state.existsNextReport) {
            state.nextQuery = collection;
            state.nextStartAtObj = result.docs[result.docs.length - 1];
          } else {
            state.nextQuery = null;
            state.nextStartAtObj = null;
          }
          const newReports = result.docs
            .slice(0, REPORT_DEFAULT_ITEMS_LIMIT_PER_PAGE)
            .map((d) => new Report(d.data()))
            .filter(reportFilterBy(query, state));
          state.reports = newReports;
          if (
            state.existsNextReport &&
            newReports.length <= NEEDED_MINIMUM_ITEM_SIZE_PER_REQUEST
          ) {
            return dispatch(ReportActionTypes.FETCH_Next_Report, query);
          }
        })
        .finally(() => (state.nextReportLoading = false));
      // commit(ReportMutationTypes.SET_UN_SUBSCRIBE, { unSubscribe });
    }
  },
  async [ReportActionTypes.FETCH_Next_Report](
    { state, dispatch },
    query
  ): Promise<void> {
    if (!state.nextQuery) return;
    if (!state.nextStartAtObj) return;
    // if (!sharedNextObj) return;
    // if (state.unsubscribeReports) state.unsubscribeReports();
    state.nextReportLoading = true;

    const data = state.nextStartAtObj.data();
    await state
      .nextQuery()
      .startAt(data.workedDate, data.reportId)
      .limit(REPORT_DEFAULT_ITEMS_LIMIT_PER_PAGE + 1)
      .get()
      .then((result) => {
        state.existsNextReport =
          result.size > REPORT_DEFAULT_ITEMS_LIMIT_PER_PAGE;
        if (state.existsNextReport) {
          state.nextStartAtObj = result.docs[result.docs.length - 1];
        } else {
          state.nextStartAtObj = null;
        }
        const newReports = result.docs
          .slice(0, REPORT_DEFAULT_ITEMS_LIMIT_PER_PAGE)
          .map((d) => new Report(d.data()))
          .filter(reportFilterBy(query, state));

        state.rangedReport = [
          ...(state.rangedReport ?? state.reports),
          ...newReports,
        ];

        if (
          state.existsNextReport &&
          newReports.length <= NEEDED_MINIMUM_ITEM_SIZE_PER_REQUEST
        ) {
          return dispatch(ReportActionTypes.FETCH_Next_Report, query);
        }
      })
      .finally(() => (state.nextReportLoading = false));
  },

  async [ReportActionTypes.FETCH_Report]({ state, commit }, query) {
    const organizationId = state.organizationId;
    if (!organizationId) return;

    if (query) {
      let collection = db
        .collection("organizations")
        .doc(organizationId)
        .collection("reports")
        .where("workedDate", ">=", query.start ?? new Date(0))
        .where("workedDate", "<=", query.end ?? new Date(8640000000000));

      if (query.workerIds && query.workerIds.length > 0)
        collection = collection.where("workerId", "in", query.workerIds);

      return collection
        .orderBy("workedDate", "desc")
        .get()
        .then((result): Report[] =>
          result.docs.map((d) => new Report(d.data()))
        )
        .then((newReports) => newReports.filter(reportFilterBy(query, state)));
    } else {
      if (state.unsubscribeReports) state.unsubscribeReports();
      return db
        .collection("organizations")
        .doc(organizationId)
        .collection("reports")
        .orderBy("workedDate", "desc")
        .get()
        .then((result) => {
          return result.docs.map((d) => new Report(d.data()));
        });
    }
  },
  [ReportActionTypes.CHANGE_WORKER_NAME](
    { state, commit },
    { reportId, workerName }
  ): Promise<void> {
    const organizationId = state.organizationId;
    if (!organizationId) return;
    logger.log("start#CHANGE_WORKER_NAME", reportId, workerName);
    return db
      .doc(`organizations/${organizationId}/reports/${reportId}`)
      .set({ workerName: workerName }, { merge: true });
  },
  [ReportActionTypes.CHANGE_CO_WORKER_NAME](
    { state, commit },
    { report, workerId, workerName }
  ): Promise<void> {
    const organizationId = state.organizationId;
    if (!organizationId) return;
    if (!report.workers) return;
    const newWorkers = report.workers.map((w) =>
      w.uid !== workerId
        ? w.asJson
        : new CoWorker({ uid: workerId, name: workerName }).asJson
    );
    return db
      .doc(`organizations/${organizationId}/reports/${report.reportId}`)
      .set({ workers: newWorkers }, { merge: true });
  },
};
