
import { useStore } from "@/store";
import { useModal } from "../shared/useModal";
import { computed, defineComponent, onMounted, ref } from "vue";
import logger from "@/loogger";
import { FunctionsAPI } from "@/api/functions";
import { WorkTypeActionTypes } from "@/store/workType";

export default defineComponent({
  setup(props) {
    const store = useStore();
    const isLoading = ref(false);
    const errorMessage = ref();
    const deletingMode = ref(false);
    const isMoveToOther = ref(true);
    const selectedMoveTo = ref();
    const editingNewWorkTypeName = ref();
    const editingNewWorkTypeUnit = ref();
    const { hideModal } = useModal();
    const execCloseWorkType = () => {
      // TODO: Close処理
    };
    const execBindOtherWorkType = async () => {
      if (!selectedMoveTo.value || !selectedMoveTo.value.id) {
        errorMessage.value = "選択されていません";
        return;
      }
      errorMessage.value = "";
      isLoading.value = true;
      const selectedWorkTypeId = store.state.selectedWorkType.id;
      const moveToWorkTypeId = selectedMoveTo.value.id;
      await FunctionsAPI.mergeWorkType(selectedWorkTypeId, moveToWorkTypeId)
        .then(() => {
          isLoading.value = false;
          hideModal();
        })
        .catch(() => {
          isLoading.value = false;
          errorMessage.value = "紐付けに失敗しました。";
        });
    };
    onMounted(() => {
      editingNewWorkTypeName.value = store.state.selectedWorkType?.name;
      editingNewWorkTypeUnit.value = store.state.selectedWorkType?.unit;
    });
    return {
      deletingMode,
      errorMessage,
      isLoading,
      selectedMoveTo,
      editingNewWorkTypeName,
      editingNewWorkTypeUnit,
      isMoveToOther,
      selectedWorkTypeName: computed(() => store.state.selectedWorkType?.name),
      workTypes: computed(() =>
        store.state.workTypes.filter(
          (w) => w.id !== store.state.selectedWorkType?.id
        )
      ),
      toDeletingMode: () => {
        deletingMode.value = true;
      },
      onClickDelete: () => {
        if (isMoveToOther.value) {
          execBindOtherWorkType();
        } else {
          execCloseWorkType();
        }
      },
      applyChanges: async () => {
        if (
          !editingNewWorkTypeName.value ||
          editingNewWorkTypeName.value.length < 1
        ) {
          errorMessage.value = "入力されていません。";
          return;
        }
        errorMessage.value = "";
        isLoading.value = true;
        const workTypeId = store.state.selectedWorkType.id;
        const newName = editingNewWorkTypeName.value;
        const newUnit = editingNewWorkTypeUnit.value;
        try {
          await store.dispatch(WorkTypeActionTypes.CHANGE_NAME_WorkType, {
            id: workTypeId,
            newName,
            newUnit,
          });
          isLoading.value = false;
          hideModal();
        } catch (error) {
          logger.error("名前の変更に失敗しました", error);
          isLoading.value = false;
          if (error === "duplicated.name") {
            errorMessage.value = "名前が重複しています。";
          } else {
            errorMessage.value = "名前の変更に失敗しました。";
          }
        }
      },
      hideModal: () => hideModal(),
    };
  },
});
