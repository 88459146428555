
import { useStore } from "@/store";
import { useModal } from "../shared/useModal";
import { computed, defineComponent, onMounted, ref } from "vue";
import { AccountActionTypes } from "@/store/account";
import formatDate from "../shared/DateFormatter";
import { AuthActionTypes } from "@/store/auth";

export default defineComponent({
  setup(props) {
    const store = useStore();
    const { hideModal, showModal } = useModal();
    const isChangePasswordMode = ref(false);
    const formErrorMessage = ref<string>();
    const form = ref({
      currentPassword: null,
      password: null,
      passwordConfirmed: null,
    });
    const checkEqualPassword = (
      newPass: string | null,
      confirm: string | null
    ): boolean => {
      if (newPass === null || newPass.length < 1) return false;
      if (confirm === null || confirm.length < 1) return false;
      return newPass === confirm;
    };
    onMounted(async () => {
      await store.dispatch(AccountActionTypes.FETCH_Account, undefined);
    });
    return {
      form,
      formErrorMessage,
      isChangePasswordMode,
      organizationId: computed(() => store.state.organizationId),
      organizationName: computed(() => store.state.account?.organizationName),
      expiredDate: computed(() =>
        formatDate(store.state.account?.expiredDate, "YYYY/MM/DD")
      ),
      loginId: computed(() => store.state.account?.loginId),
      maxWorkers: computed(() => store.state.account?.maxWorkers),
      toChangePasswordMode: () => (isChangePasswordMode.value = true),
      onClickPasswordReset: () => showModal("reset-password-modal"),
      onClickSubmit: () => {
        const currentPassword = form.value.currentPassword;
        const newPassword = form.value.password;
        if (
          checkEqualPassword(form.value.password, form.value.passwordConfirmed)
        ) {
          store
            .dispatch(AuthActionTypes.CHANGE_PASSWORD, {
              currentPassword,
              newPassword,
            })
            .then(() => {
              formErrorMessage.value = null;
              hideModal();
            })
            .catch((error) => {
              if (error.code === "auth/wrong-password") {
                formErrorMessage.value = "現在のパスワードが間違っています";
              } else {
                formErrorMessage.value = "パスワード再設定に失敗しました";
              }
            });
        } else {
          formErrorMessage.value = "確認用パスワードが一致しません";
        }
      },
      hideModal: () => hideModal(),
    };
  },
});
