
import { useStore } from "@/store";
import { useModal } from "../shared/useModal";
import { computed, defineComponent, onMounted, Ref, ref } from "vue";
import { ReportActionTypes } from "@/store/report";
import { storage } from "@/api/firebase";
import formatDate from "../shared/DateFormatter";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default defineComponent({
  components: { VueperSlides, VueperSlide },
  setup(props) {
    const store = useStore();
    const { hideModal } = useModal();
    const imageUrls: Ref<Array<string>> = ref([]);
    const imageUrlErrors: Ref<Array<string>> = ref([]);
    const hasImages = ref(false);
    onMounted(async () => {
      Promise.all(
        store.state.selectedReports?.imagePathes.map((path) =>
          storage
            .ref(path)
            .getDownloadURL()
            .catch((error) => imageUrlErrors.value.push(path))
        )
      ).then((urls) => {
        imageUrls.value = urls;
        hasImages.value = urls.length > 0;
        return;
      });
    });
    return {
      imageUrls,
      imageUrlErrors,
      hasImages,
      report: computed(() => store.state.selectedReports),
      format: (date: Date) => formatDate(date, "YYYY/MM/DD"),
      hideModal: () =>
        store.dispatch(ReportActionTypes.DE_SELECT_REPORT, undefined),
    };
  },
});
