import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import "firebase/auth";
import "firebase/functions";
import "firebase/analytics";
import "firebase/storage";
import logger from "@/loogger";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};
export const firebaseApp = firebase.initializeApp(firebaseConfig);
// firebase.analytics();
// firebase.functions();
// firebase.firestore().settings({
//   cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
// });
// firebase
//   .firestore()
//   .enablePersistence({ synchronizeTabs: true })
//   .catch(function (err) {
//     if (err.code === "failed-precondition") {
//       console.log("firestore persistence error", err);
//       // Multiple tabs open, persistence can only be enabled
//       // in one tab at a a time.
//       // ...
//     } else if (err.code === "unimplemented") {
//       console.log("firestore persistence error", err);
//       // The current browser does not support all of the
//       // features required to enable persistence
//       // ...
//     } else {
//       console.log("firestore enablepersistence", err);
//     }
//   });

firebaseApp
  .firestore()
  .enablePersistence({ experimentalForceOwningTab: true })
  .then(() => logger.log("enabled persistence"))
  .catch((error) => logger.error("failed persistence", error))
  .finally(() => logger.log("finish setting for persistence"));

export const functions = firebaseApp.functions("asia-northeast1");
export const auth = firebaseApp.auth();
export const db = firebaseApp.firestore();
export const database = firebaseApp.database();
export const storage = firebaseApp.storage();
const { Timestamp, GeoPoint } = firebase.firestore;
export { Timestamp, GeoPoint };
