import { ActionTree } from "vuex";
import { db } from "@/api/firebase";
import { AugmentedActionContext } from "./types/actions";
import { State } from ".";
import { Account } from "@/models/Account";

export interface AccountState {
  account: Account | null;
}

/** Mutations */

export enum AccountMutationTypes {
  SET_ACCOUNT = "AccountMutationTypes/SET_ACCOUNT",
}
export type AccountMutations<S = State> = {
  [AccountMutationTypes.SET_ACCOUNT](
    state: S,
    payload: { account: Account }
  ): void;
};

export const accountMutation: AccountMutations = {
  [AccountMutationTypes.SET_ACCOUNT](state, { account }): void {
    state.account = account;
  },
};

/** Actions  */

export enum AccountActionTypes {
  FETCH_Account = "OrganizationAccount/FETCH_Account",
}

export interface AccountActions {
  [AccountActionTypes.FETCH_Account]({
    commit,
  }: AugmentedActionContext): Promise<void>;
}

export const accountAction: ActionTree<State, State> & AccountActions = {
  async [AccountActionTypes.FETCH_Account]({ state, commit }) {
    const organizationId = state.organizationId;
    if (!organizationId) return;
    return db
      .collection("organizations")
      .doc(organizationId)
      .get()
      .then((result) => {
        commit(AccountMutationTypes.SET_ACCOUNT, {
          account: new Account(result.data()),
        });
      });
  },
};
