
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "@/store";
import { MachineActionTypes } from "@/store/machine";
import { useRouter } from "vue-router";
import { Machine } from "@/models/Machine";
import { useModal } from "../shared/useModal";
import Pagination, {
  DEFAULT_ITEMS_LIMIT_PER_PAGE,
} from "@/components/shared/Pagination.vue";
import { REPORT_DEFAULT_ITEMS_LIMIT_PER_PAGE } from "@/store/report";

export default defineComponent({
  components: { Pagination },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const { showModal } = useModal();

    const currentPage = ref(0);
    const visibleFirstOffset = ref(0);
    const visibleItems = computed(() =>
      store.state.machines.slice(
        visibleFirstOffset.value,
        visibleFirstOffset.value + REPORT_DEFAULT_ITEMS_LIMIT_PER_PAGE
      )
    );
    const isNeededPagination = computed(
      () => store.state.machines.length > REPORT_DEFAULT_ITEMS_LIMIT_PER_PAGE
    );
    onMounted(() => {
      store.dispatch(MachineActionTypes.BIND_Machine, {
        isFetchRelatedStat: true,
      });
    });
    return {
      visibleItems,
      isNeededPagination,
      maxLength: computed(() => store.state.machines.length),
      itemsLimitPerPage: computed(() => DEFAULT_ITEMS_LIMIT_PER_PAGE),
      onChangePageIndex: (newIndex, newOffset) => {
        currentPage.value = newIndex;
        visibleFirstOffset.value = newOffset;
      },

      relatedReportWorkingTime: computed(
        () => store.state.machine_RelatedReportWorkingTimes
      ),
      relatedReport: computed(() => store.state.machine_RelatedReportIds),
      relatedPlace: computed(() => store.state.machine_RelatedPlaceIds),
      relatedWorkType: computed(() => store.state.machine_RelatedWorkTypeIds),

      moveToReport: (machineId: string) =>
        router.push({ path: "/reports", query: { machineIds: [machineId] } }),
      moveToPlace: (machineId: string) =>
        router.push({
          path: "/reports",
          query: {
            machineIds: [machineId],
            placeIds: store.state.machine_RelatedPlaceIds[machineId],
          },
        }),
      moveToWorkType: (machineId: string) =>
        router.push({
          path: "/reports",
          query: {
            machineIds: [machineId],
            workTypeIds:
              store.state.machine_RelatedWorkTypeIds[machineId] ?? [],
          },
        }),
      onClickDetail: (machine: Machine) => {
        store.dispatch(MachineActionTypes.SELECT_Machine, { machine });
        showModal("MachineDetailModal");
      },
    };
  },
});
