<template>
  <csv-downloader
    :async-data="selectedReports"
    :fields="fields"
    :fileName="fileName"
  >
    <button class="form-btn ml-20">CSVダウンロード</button>
  </csv-downloader>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import CsvDownloader from "../shared/CsvDownloader.vue";

export default defineComponent({
  components: { CsvDownloader },
  props: {
    selectedReports: {
      type: Function as PropType<() => Promise<Object[]>>,
      required: true,
      default: () => [],
    },
    fileName: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      required: false,
      default: () => [],
    },
    onClick: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {},
});
</script>
