import { ActionTree } from "vuex";
import { database, db } from "@/api/firebase";
import { WorkType } from "@/models/WorkType";
import { AugmentedActionContext } from "./types/actions";
import { State } from ".";
import firebase from "firebase";
import QuerySnapshot = firebase.firestore.QuerySnapshot;
import DocumentData = firebase.firestore.DocumentData;
import logger from "@/loogger";

export interface WorkTypeState {
  workTypes: WorkType[];
  selectedWorkType: WorkType | null;
  unsubscribeWorkTypes: () => void | null;

  workType_RelatedReportOutPutAmount: { [workTypeId: string]: number[] };
  workType_RelatedReportWorkingTimes: { [workTypeId: string]: number[] };
  workType_RelatedReportIds: { [workTypeId: string]: string[] };
  workType_RelatedMachineIds: { [workTypeId: string]: string[] };
  workType_RelatedPlaceIds: { [workTypeId: string]: string[] };
}

/** Mutations */

export enum WorkTypeMutationTypes {
  SET_WORK_TYPE = "WorkTypeMutationTypes/SET_WORK_TYPE",
  SET_SELECTED_WORK_TYPE = "WorkTypeMutationTypes/SET_SELECTED_WORK_TYPE",
  SET_UN_SUBSCRIBE = "WorkTypeMutationTypes/SET_UN_SUBSCRIBE",
}
export type WorkTypeMutations<S = State> = {
  [WorkTypeMutationTypes.SET_WORK_TYPE](
    state: S,
    payload: { workTypes: WorkType[] }
  ): void;
  [WorkTypeMutationTypes.SET_SELECTED_WORK_TYPE](
    state: S,
    payload: { workType: WorkType | null }
  ): void;
  [WorkTypeMutationTypes.SET_UN_SUBSCRIBE](
    state: S,
    payload: { unSubscribe: () => void }
  ): void;
};

export const workTypeMutations: WorkTypeMutations = {
  [WorkTypeMutationTypes.SET_WORK_TYPE](state, { workTypes }): void {
    state.workTypes = workTypes;
  },
  [WorkTypeMutationTypes.SET_SELECTED_WORK_TYPE](state, { workType }): void {
    state.selectedWorkType = workType;
  },
  [WorkTypeMutationTypes.SET_UN_SUBSCRIBE](state, { unSubscribe }): void {
    state.unsubscribeWorkTypes = unSubscribe;
  },
};

/** Actions  */

export enum WorkTypeActionTypes {
  BIND_WorkType = "WorkType/BIND_WorkType",
  FETCH_WorkType = "WorkType/FETCH_WorkType",
  SELECT_WorkType = "WorkType/SELECT_WorkType",
  DE_SELECT_WorkType = "WorkType/DE_SELECT_WorkType",
  CREATE_WorkType = "WorkType/CREATE_WorkType",
  CHANGE_NAME_WorkType = "WorkType/CHANGE_NAME_WorkType",

  FETCH_Statistics_Report = "WorkTypeActionTypes/FETCH_Statistics_Report",
  FETCH_Statistics_Machine = "WorkTypeActionTypes/FETCH_Statistics_Machine",
  FETCH_Statistics_Place = "WorkTypeActionTypes/FETCH_Statistics_Place",
}

export interface WorkTypeActions {
  [WorkTypeActionTypes.BIND_WorkType](
    { commit }: AugmentedActionContext,
    payload: { isFetchRelatedStat: boolean }
  ): Promise<void>;
  [WorkTypeActionTypes.FETCH_WorkType]({
    commit,
  }: AugmentedActionContext): Promise<void>;
  [WorkTypeActionTypes.CREATE_WorkType](
    { commit }: AugmentedActionContext,
    payload: { name: string; unit: string | null }
  ): Promise<void>;
  [WorkTypeActionTypes.CHANGE_NAME_WorkType](
    { commit }: AugmentedActionContext,
    payload: { id: string; newName: string; newUnit: string }
  ): Promise<void>;
  [WorkTypeActionTypes.SELECT_WorkType](
    { commit }: AugmentedActionContext,
    payload: { workType: WorkType }
  ): Promise<void>;
  [WorkTypeActionTypes.DE_SELECT_WorkType]({
    commit,
  }: AugmentedActionContext): Promise<void>;

  [WorkTypeActionTypes.FETCH_Statistics_Report](
    { commit }: AugmentedActionContext,
    payload: { workTypeId: string }
  ): void;
  [WorkTypeActionTypes.FETCH_Statistics_Machine](
    { commit }: AugmentedActionContext,
    payload: { workTypeId: string }
  ): void;
  [WorkTypeActionTypes.FETCH_Statistics_Place](
    { commit }: AugmentedActionContext,
    payload: { workTypeId: string }
  ): void;
}

const obj2keyArray = (obj: any): string[] => {
  const array: string[] = [];
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== null && obj[key] !== undefined) array.push(key);
  });
  return array;
};

const obj2valueArray = (obj: any): number[] => {
  const array: number[] = [];
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== null && obj[key] !== undefined)
      array.push(Number.parseFloat(obj[key]));
  });
  return array;
};

const Dao = {
  findByName: (
    organizationId: string,
    newName: string
  ): Promise<QuerySnapshot<DocumentData>> =>
    db
      .collection(`organizations/${organizationId}/worktypes`)
      .where("name", "==", newName)
      .get({ source: "server" }),
};

export const workTypeAction: ActionTree<State, State> & WorkTypeActions = {
  async [WorkTypeActionTypes.CREATE_WorkType](
    { state, commit },
    { name, unit }
  ) {
    const organizationId = state.organizationId;
    if (!organizationId) return;

    if (state.workTypes.some((m) => m.name === name))
      return Promise.reject("duplicated.name");

    const existsList = await Dao.findByName(organizationId, name);
    if (existsList.size !== 0) return Promise.reject("duplicated.name");

    const collection = db
      .collection("organizations")
      .doc(organizationId)
      .collection("worktypes");
    const tmpId = collection.doc().id;
    const newWorkType = {
      id: tmpId,
      name: name,
      unit: unit,
    };
    return collection.doc(tmpId).set(newWorkType, { merge: true });
  },
  async [WorkTypeActionTypes.CHANGE_NAME_WorkType](
    { state, commit },
    { id, newName, newUnit }
  ) {
    const organizationId = state.organizationId;
    if (!organizationId) return;

    if (state.workTypes.some((m) => m.name === newName && m.id !== id))
      return Promise.reject("duplicated.name");

    const existsList = (
      await Dao.findByName(organizationId, newName)
    ).docs.filter((d) => d.id !== id);
    if (existsList.length > 0) {
      return Promise.reject("duplicated.name");
    } else {
      const newWorkType = {
        name: newName,
        unit: newUnit,
      };
      await db
        .doc(`organizations/${organizationId}/worktypes/${id}`)
        .set(newWorkType, { merge: true });
      return;
    }
  },
  async [WorkTypeActionTypes.SELECT_WorkType]({ state, commit }, { workType }) {
    commit(WorkTypeMutationTypes.SET_SELECTED_WORK_TYPE, { workType });
  },
  async [WorkTypeActionTypes.DE_SELECT_WorkType]({ state, commit }) {
    commit(WorkTypeMutationTypes.SET_SELECTED_WORK_TYPE, undefined);
  },
  async [WorkTypeActionTypes.BIND_WorkType](
    { state, commit, dispatch },
    payload
  ) {
    logger.log(WorkTypeActionTypes.BIND_WorkType, "called");
    const organizationId = state.organizationId;
    const fetchStat = (newWorkTypes: WorkType[]): void => {
      logger.log(WorkTypeActionTypes.BIND_WorkType, "fetchStat");
      newWorkTypes.forEach((m) => {
        const workTypeId: string = m.id;
        dispatch(WorkTypeActionTypes.FETCH_Statistics_Report, { workTypeId });
        dispatch(WorkTypeActionTypes.FETCH_Statistics_Machine, { workTypeId });
        dispatch(WorkTypeActionTypes.FETCH_Statistics_Place, { workTypeId });
      });
    };
    if (!organizationId) return;
    if (state.unsubscribeWorkTypes) {
      logger.log(WorkTypeActionTypes.BIND_WorkType, "found subscribing");
      if (payload?.isFetchRelatedStat === true) fetchStat(state.workTypes);
      return;
    }
    logger.log(WorkTypeActionTypes.BIND_WorkType, "start fetch");
    const unSubscribe = db
      .collection("organizations")
      .doc(organizationId)
      .collection("worktypes")
      .orderBy("name")
      .onSnapshot((result) => {
        logger.log(WorkTypeActionTypes.BIND_WorkType, "found", result.size);
        const newWorkTypes = result.docs.map(
          (d) => new WorkType(d.id, d.data())
        );
        fetchStat(newWorkTypes);
        commit(WorkTypeMutationTypes.SET_WORK_TYPE, {
          workTypes: newWorkTypes,
        });
      });
    commit(WorkTypeMutationTypes.SET_UN_SUBSCRIBE, { unSubscribe });
  },
  async [WorkTypeActionTypes.FETCH_WorkType]({ state, commit }) {
    const organizationId = state.organizationId;
    if (!organizationId) return;
    return db
      .collection("organizations")
      .doc(organizationId)
      .collection("worktypes")
      .orderBy("name")
      .get()
      .then((result) => {
        const workTypes = result.docs.map((d) => new WorkType(d.id, d.data()));
        commit(WorkTypeMutationTypes.SET_WORK_TYPE, { workTypes });
      });
  },

  [WorkTypeActionTypes.FETCH_Statistics_Report](
    { commit, state },
    { workTypeId }
  ) {
    const organizationId = state.organizationId;
    if (!organizationId) return;

    database
      .ref(`worktype/${organizationId}/${workTypeId}/reports`)
      .once("value")
      .then((snapshot): void => {
        state.workType_RelatedReportIds[workTypeId] = obj2keyArray(
          snapshot.val() ?? {}
        );
        state.workType_RelatedReportWorkingTimes[workTypeId] = obj2valueArray(
          snapshot.val() ?? {}
        );
      });
    database
      .ref(`worktype/${organizationId}/${workTypeId}/outputs`)
      .once("value")
      .then((snapshot): void => {
        state.workType_RelatedReportOutPutAmount[workTypeId] = obj2valueArray(
          snapshot.val() ?? {}
        );
      });
  },
  [WorkTypeActionTypes.FETCH_Statistics_Machine](
    { commit, state },
    { workTypeId }
  ) {
    if (!state.organizationId) return;
    database
      .ref(`worktype/${state.organizationId}/${workTypeId}/machines`)
      .once("value")
      .then((snapshot): void => {
        state.workType_RelatedMachineIds[workTypeId] = obj2keyArray(
          snapshot.val() ?? {}
        );
      });
  },
  [WorkTypeActionTypes.FETCH_Statistics_Place](
    { commit, state },
    { workTypeId }
  ) {
    if (!state.organizationId) return;
    database
      .ref(`worktype/${state.organizationId}/${workTypeId}/places`)
      .once("value")
      .then((snapshot): void => {
        state.workType_RelatedPlaceIds[workTypeId] = obj2keyArray(
          snapshot.val() ?? {}
        );
      });
  },
};
