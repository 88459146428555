<template>
  <ul class="data-pager">
    <li>
      <a href="#" @click.prevent.stop="onFirstPage">＜</a>
    </li>
    <li v-show="currentPage > 0">
      <a href="#" @click.prevent.stop="onPrevPage">{{ currentPage }}</a>
    </li>
    <li>
      <a href="#" class="current" @click.prevent.stop>{{ currentPage + 1 }}</a>
    </li>
    <li v-show="hasNextPage">
      <a href="#" @click.prevent.stop="onNextPage">{{ currentPage + 2 }}</a>
    </li>
    <li v-show="hasNextPage">
      <a href="#" @click.prevent.stop="onNextPage">＞</a>
    </li>
  </ul>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "vue";

export const DEFAULT_ITEMS_LIMIT_PER_PAGE = 30;
export default defineComponent({
  props: {
    itemsLimitPerPage: { type: Number, required: true },
    maxLength: { type: Number, required: true },
    onChangePageIndex: {
      type: Function as PropType<
        (newPageIndex: number, newFirstLineOffset: number) => void
      >,
      required: true,
    },
  },
  setup(props) {
    const currentPage = ref(0);
    const hasNextPage = computed(
      () =>
        props.maxLength >
        props.itemsLimitPerPage * currentPage.value + props.itemsLimitPerPage
    );
    const visibleFirstLineOffset = computed(
      () => currentPage.value * props.itemsLimitPerPage
    );
    const onFirstPage = () => {
      currentPage.value = 0;
      props.onChangePageIndex(currentPage.value, visibleFirstLineOffset.value);
    };
    const onPrevPage = () => {
      currentPage.value--;
      props.onChangePageIndex(currentPage.value, visibleFirstLineOffset.value);
    };
    const onNextPage = () => {
      currentPage.value++;
      props.onChangePageIndex(currentPage.value, visibleFirstLineOffset.value);
    };
    return {
      props,
      hasNextPage,
      currentPage,
      clear: () => {
        currentPage.value = 0;
        props.onChangePageIndex(0, 0);
      },
      onFirstPage,
      onPrevPage,
      onNextPage,
    };
  },
});
</script>

<style scoped></style>
