import { createApp } from "vue";
import { store } from "./store";
import App from "./App.vue";
import { router } from "./router";
import VCalendar from "v-calendar";

const app = createApp(App);
// app.config.productionTip = false;
// pass the injection key
app.use(store);
app.use(router);
app.use(VCalendar, {});

app.mount("#app");
