<template>
  <span id="login" v-show="!isAdminAuth">
    <main class="content">
      <div class="login-company-name">
        <router-link class="icon-dashboard" to="/">WOODAY</router-link>
      </div>

      <form @submit.prevent="onSubmit" class="form-box">
        <h2 class="form-title">ログイン</h2>
        <div class="form-body">
          <p v-show="errorMessage" class="text-error">
            {{ errorMessage }}
          </p>
          <div class="input-group">
            <div class="input-label">メールアドレス</div>
            <input
              v-model="state.email"
              type="text"
              class="input-long"
              required
            />
          </div>
          <div class="input-group">
            <div class="input-label">パスワード</div>
            <input
              v-model="state.password"
              type="password"
              class="input-long"
              required
            />
          </div>

          <div class="input-group">
            <div class="input-label">
              <label for="check01">
                <input
                  class="checkbox"
                  v-model="state.isPersistence"
                  type="checkbox"
                  id="check01"
                  name="check01"
                  value=""
                />
                ログイン状態を保存する
              </label>
            </div>
          </div>
        </div>

        <button type="submit" class="closeModal mt-20 submit-button">
          ログイン
        </button>
        <div class="light-text mt-20">
          パスワードを忘れた場合は
          <a href="#" v-on:click.prevent.stop="onClickPasswordReset">こちら</a>
        </div>
      </form>
    </main>
  </span>
</template>

<script lang="ts">
import { AuthActionTypes } from "@/store/auth";
import {
  defineComponent,
  onMounted,
  computed,
  reactive,
  onBeforeUpdate,
  ref,
} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "../../store/index";
import { useModal } from "@/components/shared/useModal";
import logger from "@/loogger";

interface State {
  email: string;
  password: string;
  isPersistence: boolean;
}

export default defineComponent({
  setup(props, { emit }) {
    const { showModal } = useModal();
    const store = useStore();
    const router = useRouter();
    const errorMessage = ref();
    const state = reactive<State>({
      email: null,
      password: null,
      isPersistence: false,
    });

    onMounted(() => {
      // const redirectPath = store.state.redirectToPath;
      // if (redirectPath === null) {
      //   console.log("setted redirect to / in Login.vue 1", redirectPath);
      //   store.dispatch(ActionTypes.RECORD_REDIRECT_PATH, {
      //     path: "/",
      //     query: store.state.redirectQuery,
      //   });
      // }
      if (store.state.adminAuth) {
        router.replace({
          path: store.state.redirectToPath ?? "/",
          query: store.state.redirectQuery,
        });
      }
    });
    return {
      state,
      errorMessage,
      isAdminAuth: computed(() => store.state.adminAuth),
      onClickPasswordReset: () => {
        showModal("reset-password-modal");
      },
      onSubmit: async () => {
        errorMessage.value = "";
        try {
          await store.dispatch(AuthActionTypes.LOGIN, {
            email: state.email,
            password: state.password,
            isPersistence: state.isPersistence,
          });
        } catch (error) {
          logger.error("onError", error);
          errorMessage.value = "メールアドレスもしくはパスワードが一致しません";
        }
      },
    };
  },
});
</script>
