<template>
  <div id="closeModal" class="closeIconModal closeModal" v-on:click="hideModal">
    ×
  </div>
  <div class="modalContents" v-if="editingMode">
    <div class="modalContents">
      <h3>編集：{{ selectedMachineName }}</h3>
      <p>
        機材名：
        <input
          type="text"
          name=""
          id="machineName"
          v-model="editingMachineName"
        />
      </p>
      <div v-show="errorMessage" class="form-error-left">
        {{ errorMessage }}
      </div>
      <button class="closeModal mt-40 submit-button" @click="applyEditing">
        変更
      </button>
      <p>
        <a href="" class="deleteModal" v-on:click.prevent.stop="toDeletingMode">
          削除
        </a>
      </p>
    </div>
  </div>

  <div class="modalContents" v-else-if="deletingMode">
    <div class="modalContents">
      <h3>削除する：{{ selectedMachineName }}</h3>
      <!--      <ul class="radio-list-modal">-->
      <!--        <li>-->
      <!--      <input type="radio" id="check01" v-model="isMoveToOther" :value="true" />-->
      <!--      <label for="check01" class="radio">他の機材に変更する</label>-->
      <!--      <input type="text" name="" id="" class="" />-->
      <div class="target-select-container">
        <span>他の機材に振り分けて削除する</span>
        <select
          class="target-select"
          v-model="selectedMoveToMachine"
          :disabled="!isMoveToOther"
          id="select"
        >
          <option disabled value="">選択して下さい</option>
          <option
            v-for="machine in machines"
            :value="machine"
            :key="machine.id"
          >
            {{ machine.name }}
          </option>
        </select>
      </div>
      <!--        </li>-->
      <!--        <li>-->
      <!--          <input-->
      <!--            type="radio"-->
      <!--            id="check02"-->
      <!--            v-model="isMoveToOther"-->
      <!--            :value="false"-->
      <!--          />-->
      <!--          <label for="check02" class="radio">-->
      <!--            他の機材に振り分けない（機材をクローズする）-->
      <!--          </label>-->
      <!--        </li>-->
      <!--      </ul>-->
      <div v-show="errorMessage" class="form-error-left">
        {{ errorMessage }}
      </div>

      <button
        v-if="!isLoading"
        class="closeModal mt-40 submit-button"
        @click="onClickDeleteButton"
      >
        {{ isMoveToOther ? "削除" : "クローズ" }}
      </button>
      <div v-else class="loader-small">Loading...</div>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from "@/store";
import { useModal } from "../shared/useModal";
import { computed, defineComponent, onMounted, ref } from "vue";
import { MachineActionTypes } from "@/store/machine";
import { WorkPlaceActionTypes } from "@/store/workplace";
import logger from "@/loogger";
import { FunctionsAPI } from "@/api/functions";

export default defineComponent({
  setup(props) {
    const store = useStore();
    const editingMode = ref(true);
    const deletingMode = ref(false);
    const isLoading = ref(false);
    const errorMessage = ref();
    const isMoveToOther = ref(true);
    const selectedMoveToMachine = ref();
    const editingMachineName = ref();
    const { hideModal } = useModal();

    const execClosePlace = () => {
      // TODO: Close処理
    };
    const execBindOtherPlace = async () => {
      if (!selectedMoveToMachine.value || !selectedMoveToMachine.value.id) {
        errorMessage.value = "選択されていません";
        return;
      }
      errorMessage.value = "";
      isLoading.value = true;
      const selectedMachineId = store.state.selectedMachine?.id;
      const moveToMachineId = selectedMoveToMachine.value.id;
      try {
        await FunctionsAPI.mergeMachine(selectedMachineId, moveToMachineId);
        isLoading.value = false;
        hideModal();
      } catch (error) {
        errorMessage.value = "紐付けに失敗しました。";
        isLoading.value = false;
      }
    };
    onMounted(() => {
      editingMachineName.value = store.state.selectedMachine?.name;
    });
    return {
      editingMode,
      deletingMode,
      isLoading,
      isMoveToOther,
      editingMachineName,
      selectedMoveToMachine,
      errorMessage,
      toDeletingMode: () => {
        errorMessage.value = "";
        deletingMode.value = true;
        editingMode.value = false;
      },
      selectedMachineName: computed(() => store.state.selectedMachine?.name),
      machines: computed(() =>
        store.state.machines.filter(
          (w) => w.id !== store.state.selectedMachine.id
        )
      ),
      onClickDeleteButton: () => {
        if (isMoveToOther.value) {
          execBindOtherPlace();
        } else {
          execClosePlace();
        }
      },
      applyEditing: async () => {
        if (!editingMachineName.value || editingMachineName.value.length < 1) {
          errorMessage.value = "入力されていません。";
          return;
        }
        errorMessage.value = "";
        isLoading.value = true;
        const machineId = store.state.selectedMachine.id;
        const newName = editingMachineName.value;
        try {
          await store.dispatch(MachineActionTypes.CHANGE_NAME_Machine, {
            machineId,
            newName,
          });
          isLoading.value = false;
          hideModal();
        } catch (error) {
          logger.error("名前の変更に失敗しました", error);
          isLoading.value = false;
          if (error === "duplicated.name") {
            errorMessage.value = "名前が重複しています。";
          } else {
            errorMessage.value = "名前の変更に失敗しました。";
          }
        }
      },
      hideModal: () => {
        store.dispatch(MachineActionTypes.DE_SELECT_Machine, undefined);
        hideModal();
      },
    };
  },
});
</script>
