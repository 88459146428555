
import { useStore } from "@/store";
import { MachineActionTypes } from "@/store/machine";
import { WorkerActionTypes } from "@/store/worker";
import { WorkPlaceActionTypes } from "@/store/workplace";
import { WorkTypeActionTypes } from "@/store/workType";
import {
  computed,
  defineComponent,
  onMounted,
  onUpdated,
  PropType,
  ref,
  toRefs,
  watch,
} from "vue";
import { useRoute } from "vue-router";
import { ReportActionTypes, ReportSearchQuery } from "@/store/report";
import logger from "@/loogger";

interface CheckedValues {
  workerIds: string[] | null;
  placeIds: string[] | null;
  machineIds: string[] | null;
  workTypeIds: string[] | null;
}

export default defineComponent({
  props: {
    filteredWorkers: {
      type: Array as PropType<string[]>,
    },
    checkedValues: {
      type: Object as () => CheckedValues,
      require: true,
    },
    applyFilters: {
      type: Function as PropType<
        (
          workerIds: string[],
          placeIds: string[],
          machineIds: string[],
          workTypeIds: string[]
        ) => void
      >,
      required: true,
    },
  },
  setup(props, context) {
    const store = useStore();
    const route = useRoute();
    // const checkedWorkers = ref(store.state.workers.map((w) => w.uid));
    // const checkedPlaces = ref(store.state.workPlaces.map((w) => w.id));
    // const checkedMachines = ref(store.state.machines.map((w) => w.id));
    // const checkedWorkTypes = ref(store.state.workTypes.map((w) => w.id));
    const asArrStr = (
      arr: string[] | string | undefined | null
    ): string[] | null => {
      if (!arr) return null;
      if (typeof arr === "string") return [arr];
      if (Array.isArray(arr) && arr.length > 0) return arr;
      return null;
    };
    const isLoading = ref(false);
    // const workers = checkedValues.value.workerIds;
    // const workPlaces = checkedValues.value.placeIds;
    // const workTypes = checkedValues.value.workTypeIds;
    // const machines = checkedValues.value.workerIds;
    // const workers = computed(() => store.state.workers);
    // const workPlaces = computed(() => store.state.workPlaces);
    // const machines = computed(() => store.state.machines);
    // const workTypes = computed(() => store.state.workTypes);
    const workers = computed(() => store.state.workers);
    const workPlaces = computed(() => store.state.workPlaces);
    const machines = computed(() => store.state.machines);
    const workTypes = computed(() => store.state.workTypes);
    const initialCheckedWorkers = computed(
      () =>
        props.checkedValues.workerIds ??
        store.state.workers.map((w) => w.workerId)
    );
    const initialCheckedPlaces = computed(
      () =>
        props.checkedValues.placeIds ?? store.state.workPlaces.map((w) => w.id)
    );
    const initialCheckedMachines = computed(
      () =>
        props.checkedValues.machineIds ?? store.state.machines.map((w) => w.id)
    );
    const initialCheckedWorkTypes = computed(
      () =>
        props.checkedValues.workTypeIds ??
        store.state.workTypes.map((w) => w.id)
    );
    const checkedWorkers = ref<string[] | null>(initialCheckedWorkers.value);
    const checkedPlaces = ref<string[] | null>(initialCheckedPlaces.value);
    const checkedMachines = ref<string[] | null>(initialCheckedMachines.value);
    const checkedWorkTypes = ref<string[] | null>(
      initialCheckedWorkTypes.value
    );

    const allSelector = computed(() => ({
      workers: !checkedWorkers.value
        ? true
        : checkedWorkers.value.length === store.state.workers.length,
      places: !checkedPlaces.value
        ? true
        : checkedPlaces.value.length === store.state.workPlaces.length,
      machines: !checkedMachines.value
        ? true
        : checkedMachines.value.length === store.state.machines.length,
      workTypes: !checkedWorkTypes.value
        ? true
        : checkedWorkTypes.value.length === store.state.workTypes.length,
    }));
    const onClickFilter = () => {
      props.applyFilters(
        checkedWorkers.value,
        checkedPlaces.value,
        checkedMachines.value,
        checkedWorkTypes.value
      );
    };
    onMounted(async () => {
      isLoading.value = true;
      await store.dispatch(WorkerActionTypes.FETCH_Worker, undefined);
      await store.dispatch(WorkPlaceActionTypes.FETCH_WorkPlace, undefined);
      await store.dispatch(WorkTypeActionTypes.FETCH_WorkType, undefined);
      await store.dispatch(MachineActionTypes.FETCH_Machine, undefined);
      isLoading.value = false;
      // checkedWorkers.value = store.state.workers.map((w) => w.uid);
      // checkedPlaces.value = store.state.workPlaces.map((w) => w.id);
      // checkedMachines.value = store.state.machines.map((w) => w.id);
      // checkedWorkTypes.value = store.state.workTypes.map((w) => w.id);
    });

    watch(
      () => route.query,
      (newQuery) => {
        const workerIds = asArrStr(route.query.workerIds);
        const placeIds = asArrStr(route.query.placeIds);
        const machineIds = asArrStr(route.query.machineIds);
        const workTypeIds = asArrStr(route.query.workTypeIds);
        checkedWorkers.value =
          workerIds ?? store.state.workers.map((w) => w.workerId);
        checkedPlaces.value =
          placeIds ?? store.state.workPlaces.map((w) => w.id);
        checkedMachines.value =
          machineIds ?? store.state.machines.map((w) => w.id);
        checkedWorkTypes.value =
          workTypeIds ?? store.state.workTypes.map((w) => w.id);

        // checkedValues.value = {
        //   workerIds,
        //   placeIds,
        //   machineIds,
        //   workTypeIds,
        // };
      }
    );
    // watch(
    //   () => route.query,
    //   (newQuery) => {
    //     checkedWorkers.value =
    //       asArrStr(newQuery.workerIds) ??
    //       store.state.workers.map((w) => w.workerId);
    //     checkedPlaces.value =
    //       asArrStr(newQuery.placeIds) ??
    //       store.state.workPlaces.map((w) => w.id);
    //     checkedMachines.value =
    //       asArrStr(newQuery.machineIds) ??
    //       store.state.machines.map((w) => w.id);
    //     checkedWorkTypes.value =
    //       asArrStr(newQuery.workTypeIds) ??
    //       store.state.workTypes.map((w) => w.id);
    //   }
    // );
    const toUnique = (base: any[]) => {
      let a = base.concat();
      for (let i = 0; i < a.length; ++i) {
        for (let j = i + 1; j < a.length; ++j) {
          if (a[i] === a[j]) a.splice(j--, 1);
        }
      }
      return a;
    };
    watch(
      () => workers.value,
      (values) => {
        checkedWorkers.value =
          asArrStr(route.query.workerIds) ??
          toUnique([...checkedWorkers.value, ...values.map((w) => w.workerId)]);
      }
    );
    watch(
      () => workPlaces.value,
      (values) => {
        checkedPlaces.value =
          asArrStr(route.query.placeIds) ??
          toUnique([...checkedPlaces.value, ...values.map((w) => w.id)]);
      }
    );
    watch(
      () => store.state.machines,
      (values) => {
        checkedMachines.value =
          asArrStr(route.query.machineIds) ??
          toUnique([...checkedMachines.value, ...values.map((w) => w.id)]);
      }
    );
    watch(
      () => store.state.workTypes,
      (values) => {
        checkedWorkTypes.value = toUnique([
          ...checkedWorkTypes.value,
          ...values.map((w) => w.id),
        ]);
      }
    );
    return {
      isLoading,
      workers,
      workPlaces,
      workTypes,
      machines,
      checkedWorkers,
      checkedPlaces,
      checkedMachines,
      checkedWorkTypes,
      allSelector,
      onClickFilter,
      onChangedWorkersAll: (checked: boolean) =>
        (checkedWorkers.value = checked
          ? store.state.workers.map((w) => w.uid)
          : []),
      onChangedPlacesAll: (checked: boolean) =>
        (checkedPlaces.value = checked
          ? store.state.workPlaces.map((w) => w.id)
          : []),
      onChangedMachinesAll: (checked: boolean) =>
        (checkedMachines.value = checked
          ? store.state.machines.map((w) => w.id)
          : []),
      onChangedWorkTypesAll: (checked: boolean) =>
        (checkedWorkTypes.value = checked
          ? store.state.workTypes.map((w) => w.id)
          : []),
    };
  },
});
