import {
  statisticsAction,
  StatisticsActions,
  statisticsMutation,
  StatisticsMutations,
  StatisticsState,
} from "./statistics";
import {
  workPlaceAction,
  WorkPlaceActions,
  WorkPlaceMutations,
  workPlaceMutations,
  WorkPlaceState,
} from "./workplace";
import {
  workerAction,
  WorkerActions,
  workerMutation,
  WorkerMutations,
  WorkerState,
} from "./worker";
// store.ts
import { Store } from "./types/store";
import { ActionTree, createStore, MutationTree } from "vuex";
import { getters } from "./types/getters";
import {
  authAction,
  AuthActions,
  authMutations,
  AuthMutations,
  AuthMutationTypes,
  AuthState,
} from "./auth";
import {
  reportAction,
  ReportActions,
  ReportMutations,
  reportMutations,
  ReportMutationTypes,
  ReportState,
} from "./report";
import {
  machineAction,
  MachineActions,
  machineMutations,
  MachineMutations,
  MachineState,
} from "./machine";
import { AugmentedActionContext } from "./types/actions";
import { LocationQuery } from "vue-router";
import {
  workTypeAction,
  WorkTypeActions,
  workTypeMutations,
  WorkTypeMutations,
  WorkTypeState,
} from "./workType";
import {
  accountAction,
  AccountActions,
  accountMutation,
  AccountMutations,
  AccountState,
} from "./account";

/** store */

interface StateType {
  initialLoadingAsync: LazyPromise<boolean> | null;
  initialLoading: boolean;
  redirectToPath: string | null | undefined;
  redirectQuery: LocationQuery | null | undefined;
  modalComponent: string | null;
}

class LazyPromise<T> {
  isResolved: boolean = false;
  resolve: (value: T) => void;
  reject: (reason: any) => void;
  promise: Promise<T>;
  constructor() {
    const self = this;
    this.promise = new Promise<T>((resolver, reject) => {
      self.resolve = resolver;
      self.reject = reject;
    });
    this.promise.then(() => (self.isResolved = true));
  }
}

export const state: StateType &
  AuthState &
  AccountState &
  ReportState &
  WorkerState &
  MachineState &
  WorkTypeState &
  WorkPlaceState &
  StatisticsState = {
  initialLoadingAsync: new LazyPromise<boolean>(),
  initialLoading: true,
  account: null,
  redirectToPath: null,
  redirectQuery: null,
  modalComponent: null,

  reports: [],
  rangedReport: null,
  selectedReports: null,
  existsNextReport: false,
  nextQuery: null,
  nextReportLoading: false,
  nextStartAtObj: null,

  // worker
  workers: [],
  preWorkers: [],
  selectedWorker: null,

  // 現場
  workPlaces: [],
  selectedWorkPlace: null,
  place_RelatedReportWorkingTimes: {},
  place_RelatedMachineIds: {},
  place_RelatedReportIds: {},
  place_RelatedWorkTypeIds: {},

  // machine
  machines: [],
  selectedMachine: null,
  machine_RelatedReportWorkingTimes: {},
  machine_RelatedReportIds: {},
  machine_RelatedPlaceIds: {},
  machine_RelatedWorkTypeIds: {},

  // worktype
  workTypes: [],
  selectedWorkType: null,
  workType_RelatedMachineIds: {},
  workType_RelatedReportIds: {},
  workType_RelatedReportWorkingTimes: {},
  workType_RelatedReportOutPutAmount: {},
  workType_RelatedPlaceIds: {},

  currentUser: null,
  organizationId: null,
  adminAuth: false,

  // 統計
  totalReportCount: null,
  totalWorkingTime: null,
  snowDaysCount: null,
  rainyDaysCount: null,
  cloudyDaysCount: null,
  sunnyDaysCount: null,

  unsubscribeMachines: null,
  unsubscribeReports: null,
  unsubscribeRangedReports: null,
  unsubscribeUser: null,
  unsubscribeWorkers: null,
  unsubscribePreWorkers: null,
  unsubscribeWorkPlaces: null,
  unsubscribeWorkTypes: null,
  unsubscribeStatistics: null,
  unSubscribeTotalReportCount: null,
  unSubscribeTotalWorkingTime: null,
  unSubscribeSnowDaysCount: null,
  unSubscribeRainyDaysCount: null,
  unSubscribeSunnyDaysCount: null,
  unSubscribeCloudyDaysCount: null,
};

export type State = typeof state;

/** actions */

export enum ActionTypes {
  SHOW_MODAL = "ActionTypes/SHOW_MODAL",
  HIDE_MODAL = "ActionTypes/HIDE_MODAL",
  RECORD_REDIRECT_PATH = "ActionTypes/RECORD_REDIRECT_PATH",
  CLEAR_REDIRECT_PATH = "ActionTypes/CLEAR_REDIRECT_PATH",
  UN_SUBSCRIBE_ALL = "ActionTypes/UN_SUBSCRIBE_ALL",
}

export type Actions = AuthActions &
  AccountActions &
  ReportActions &
  WorkerActions &
  MachineActions &
  WorkTypeActions &
  WorkPlaceActions &
  StatisticsActions & {
    [ActionTypes.RECORD_REDIRECT_PATH](
      { commit }: AugmentedActionContext,
      payload: { path: string; query: LocationQuery }
    ): void;
    [ActionTypes.CLEAR_REDIRECT_PATH]({ commit }: AugmentedActionContext): void;
    [ActionTypes.SHOW_MODAL](
      { commit }: AugmentedActionContext,
      payload: { modalComponent: string }
    ): void;
    [ActionTypes.HIDE_MODAL]({ commit }: AugmentedActionContext): void;
    [ActionTypes.UN_SUBSCRIBE_ALL]({ commit }: AugmentedActionContext): void;
  };
export const actions: ActionTree<State, State> & Actions = {
  ...authAction,
  ...accountAction,
  ...reportAction,
  ...workPlaceAction,
  ...machineAction,
  ...workTypeAction,
  ...workerAction,
  ...statisticsAction,
  [ActionTypes.RECORD_REDIRECT_PATH]({ commit }, { path, query }): void {
    commit(MutationTypes.SET_REDIRECT_PATH, { path, query });
  },
  [ActionTypes.CLEAR_REDIRECT_PATH]({ commit }): void {
    commit(MutationTypes.SET_REDIRECT_PATH, { path: null, query: null });
  },
  [ActionTypes.HIDE_MODAL]({ commit }: AugmentedActionContext): void {
    commit(ReportMutationTypes.SET_SELECTED_REPORT, { report: null });
    commit(MutationTypes.SET_SHOW_MODAL, { modalComponent: null });
  },
  [ActionTypes.SHOW_MODAL](
    { commit }: AugmentedActionContext,
    { modalComponent }
  ): void {
    commit(MutationTypes.SET_SHOW_MODAL, { modalComponent });
  },
  [ActionTypes.UN_SUBSCRIBE_ALL]({ state, commit }) {
    // state.unsubscribeUser();
    // state.unsubscribeMachines();
    // state.unsubscribeReports();
    // state.unsubscribeWorkers();
    // state.unsubscribePreWorkers();
    // state.unsubscribeWorkPlaces();
    // state.unsubscribeWorkTypes();
  },
};

/** mutaions */

export enum MutationTypes {
  SET_INITIAL_LOADING = "Mutation/SET_INITIAL_LOADING",
  SET_REDIRECT_PATH = "Mutation/SET_REDIRECT_PATH",
  SET_SHOW_MODAL = "Mutation/SET_SHOW_MODAL",
}

export type Mutations<S = State> = AuthMutations &
  AccountMutations &
  ReportMutations &
  WorkPlaceMutations &
  MachineMutations &
  WorkTypeMutations &
  WorkerMutations &
  StatisticsMutations & {
    [MutationTypes.SET_INITIAL_LOADING](
      state: S,
      payload: { initialLoading: boolean }
    ): void;
    [MutationTypes.SET_REDIRECT_PATH](
      state: S,
      payload: {
        path: string | null | undefined;
        query: LocationQuery | null | undefined;
      }
    ): void;
    [MutationTypes.SET_SHOW_MODAL](
      state: S,
      payload: { modalComponent: string | null }
    ): void;
  };

export const mutations: MutationTree<State> & Mutations = {
  ...authMutations,
  ...accountMutation,
  ...reportMutations,
  ...workPlaceMutations,
  ...machineMutations,
  ...workTypeMutations,
  ...workerMutation,
  ...statisticsMutation,
  [MutationTypes.SET_INITIAL_LOADING](state, { initialLoading }): void {
    // if (!initialLoading) state.doneInitializd(initialLoading);
    state.initialLoading = initialLoading;
    if (!state.initialLoadingAsync.isResolved)
      state.initialLoadingAsync.resolve(initialLoading);
  },
  [MutationTypes.SET_REDIRECT_PATH](state, { path, query }): void {
    state.redirectToPath = path;
    state.redirectQuery = query;
  },
  [MutationTypes.SET_SHOW_MODAL](state, { modalComponent }): void {
    state.modalComponent = modalComponent;
  },
};

/** store */

export const store = createStore({
  state,
  getters,
  mutations,
  actions,
});

// define your own `useStore` composition function
export function useStore(): Store {
  return store;
}
