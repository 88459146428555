
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "@/store";
import { WorkPlaceActionTypes } from "@/store/workplace";
import { useRouter } from "vue-router";
import { WorkPlace } from "@/models/WorkPlace";
import { useModal } from "../shared/useModal";
import Pagination, {
  DEFAULT_ITEMS_LIMIT_PER_PAGE,
} from "@/components/shared/Pagination.vue";

export default defineComponent({
  components: { Pagination },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const { showModal } = useModal();

    const currentPage = ref(0);
    const visibleFirstOffset = ref(0);
    const visibleItems = computed(() =>
      store.state.workPlaces.slice(
        visibleFirstOffset.value,
        visibleFirstOffset.value + DEFAULT_ITEMS_LIMIT_PER_PAGE
      )
    );
    const isNeededPagination = computed(
      () => store.state.workPlaces.length > DEFAULT_ITEMS_LIMIT_PER_PAGE
    );

    onMounted(() => {
      store.dispatch(WorkPlaceActionTypes.BIND_WorkPlace, {
        isFetchRelatedStat: true,
      });
    });
    return {
      visibleItems,
      isNeededPagination,
      maxLength: computed(() => store.state.workPlaces.length),
      itemsLimitPerPage: computed(() => DEFAULT_ITEMS_LIMIT_PER_PAGE),
      onChangePageIndex: (newIndex, newOffset) => {
        currentPage.value = newIndex;
        visibleFirstOffset.value = newOffset;
      },

      relatedReportWorkingTime: computed(
        () => store.state.place_RelatedReportWorkingTimes
      ),
      relatedReport: computed(() => store.state.place_RelatedReportIds),
      relatedMachine: computed(() => store.state.place_RelatedMachineIds),
      relatedWorkType: computed(() => store.state.place_RelatedWorkTypeIds),

      moveToReport: (placeId: string) =>
        router.push({ path: "/reports", query: { placeIds: [placeId] } }),
      moveToMachine: (placeId: string) =>
        router.push({
          path: "/reports",
          query: {
            placeIds: [placeId],
            machineIds: store.state.place_RelatedMachineIds[placeId] ?? [],
          },
        }),
      moveToWorkType: (placeId: string) =>
        router.push({
          path: "/reports",
          query: {
            placeIds: [placeId],
            workTypeIds: store.state.place_RelatedWorkTypeIds[placeId] ?? [],
          },
        }),
      onClickDetail: (place: WorkPlace) => {
        store.dispatch(WorkPlaceActionTypes.SELECT_WorkPlace, {
          workPlace: place,
        });
        showModal("WorkPlaceDetailModal");
      },
    };
  },
});
