// https://github.com/dnrsm/vue-blob-json-csv を参考に実装
<template>
  <component :is="tagName" ref="download" @click="handleDownload()">
    <template v-if="title === ''">
      <slot></slot>
    </template>
    <template>
      {{ title }}
    </template>
  </component>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    tagName: {
      type: String,
      required: false,
      default: "span",
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    fileType: {
      type: String,
      required: false,
      default: "csv",
    },
    fileName: {
      type: String,
      required: false,
      default: "data",
    },
    asyncData: {
      type: Function as PropType<() => Promise<Object[]>>,
      required: true,
      default: () => [],
    },
    fields: {
      type: Array,
      required: false,
      default: () => [],
    },
    confirm: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    // エスケープ処理
    const escapeForCSV = (s: string) => {
      return s ? `"${s.replace(/"/g, '""')}"` : "";
    };

    const createContent = async (): Promise<string | null> => {
      const data: any[] = await props.asyncData();
      if (!data || data.length < 1) return;
      const keys =
        props.fields.length > 0
          ? props.fields
          : Object.keys(data[0] as string[]);
      let csv: string = `\ufeff${keys.join()}\n`;
      for (let index = 0; index < data.length; index++) {
        const item: any = data[index];
        let line = keys
          .map((key: any) => {
            if (item[key] === null) {
              return null;
            } else if (typeof item[key] === "object") {
              return escapeForCSV(JSON.stringify([item[key]]));
            } else {
              return escapeForCSV(`${[item[key]]}`);
            }
          })
          .join(",");
        csv += `${line}\n`;
      }
      return csv;
    };

    const handleDownload = async (): Promise<void> => {
      let content = await createContent();
      if (!content) return;
      if (props.confirm !== null) {
        const result = confirm(props.confirm);
        if (!result) return;
      }
      const blob = new Blob([content], {
        type: `application/${props.fileType}`,
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${props.fileName}.${props.fileType}`;
      link.click();
    };
    return {
      handleDownload,
      //   tagName: computed(() => props.tagName),
      //   title: computed(() => props.title),
    };
  },
});
</script>
