
import { computed, defineComponent, onBeforeUpdate, onMounted } from "vue";
import Navbar from "./components/navigation/Navbar.vue";
import Login from "./components/login/Login.vue";
import ReportDetailModal from "./components/report/ReportDetailModal.vue";
import MemberDetailModal from "./components/member/MemberDetailModal.vue";
import MachineDetailModal from "./components/management/MachineDetailModal.vue";
import WorkTypeDetailModal from "./components/management/WorkTypeDetailModal.vue";
import WorkPlaceDetailModal from "./components/management/WorkPlaceDetailModal.vue";
import AccountContractModal from "./components/navigation/AccountContractModal.vue";
import AccountTermsModal from "./components/navigation/AccountTermsModal.vue";
import AccountContactModal from "./components/navigation/AccountContactModal.vue";
import AccountSupportModal from "./components/navigation/AccountSupportModal.vue";
import AccountCompanyModal from "./components/navigation/AccountCompanyModal.vue";
import { AuthActionTypes } from "@/store/auth";
import { ActionTypes, useStore } from "@/store";
import { useRoute, useRouter } from "vue-router";
import { useModal } from "./components/shared/useModal";
import ResetPasswordModal from "@/components/navigation/ResetPasswordModal.vue";

export default defineComponent({
  components: {
    Navbar,
    Login,
    ReportDetailModal,
    MemberDetailModal,
    MachineDetailModal,
    WorkTypeDetailModal,
    WorkPlaceDetailModal,
    AccountContractModal,
    AccountContactModal,
    AccountCompanyModal,
    AccountSupportModal,
    AccountTermsModal,
    ResetPasswordModal,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { componentName, hideModal, isVisible } = useModal();

    onBeforeUpdate(() => {
      const redirectPath = store.state.redirectToPath;
      if (
        redirectPath &&
        store.state.adminAuth &&
        route.fullPath === "/login"
      ) {
        router.replace(`${redirectPath ?? "/"}`);
        store.dispatch(ActionTypes.CLEAR_REDIRECT_PATH, undefined);
      }
    });
    onMounted(() => {
      store.dispatch(AuthActionTypes.BIND_AUTH_SUBSCRIBE, undefined);
    });
    return {
      isInitialLoading: computed(() => store.state.initialLoading),
      isAdminAuth: computed(() => store.state.adminAuth),
      modalComponentName: componentName,
      showModal: isVisible,
      hideModal: () => hideModal(),
    };
  },
});
