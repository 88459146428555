export class Account {
  organizationId: string;
  organizationName: string;
  loginId: string;
  expiredDate: Date;
  maxWorkers: number;

  constructor(obj: any) {
    this.organizationId = obj.organizationId;
    this.organizationName = obj.organizationName;
    this.loginId = obj.adminUserEmail;
    this.expiredDate = new Date(obj.expiredDate.seconds * 1000);
    this.maxWorkers = obj.maxWorkers;
  }
}
