<template>
  <div id="closeModal" class="closeIconModal closeModal" v-on:click="hideModal">
    ×
  </div>

  <!-- 編集モード -->
  <div class="modalContents" v-if="editingMode">
    <h3>作業員編集</h3>
    <ul class="form-area">
      <li>
        <span>名前：</span>
        {{ selectedWorker.name }}
        <!--   名前は本人がスマホアプリより変更する   -->
        <!--        <input-->
        <!--          type="text"-->
        <!--          name=""-->
        <!--          id="workerName"-->
        <!--          v-model="editingWorkerName"-->
        <!--        />-->
      </li>

      <li>
        <span>状態：</span>
        <select name="" id="workerState" v-model="editingWorkerVerified">
          <option :value="true">有効</option>
          <option :value="false">無効</option>
        </select>
      </li>
      <!--   メアド登録は今は行っていない   -->
      <!--      <li>-->
      <!--        <span>アドレス：</span>-->
      <!--        aaa@aaaa.com-->
      <!--      </li>-->
      <li>
        <span>ログインID：</span>
        {{ selectedWorker.workerId }}
      </li>
    </ul>
    <button class="closeModal mt-40 submit-button" @click="onClickApplyChanges">
      変更する
    </button>
  </div>

  <!-- 情報表示 -->
  <div class="modalContents" v-else>
    <h3>作業員詳細</h3>
    <ul class="form-area">
      <li>
        <span>名前：</span>
        {{ selectedWorker.name }}
      </li>
      <li>
        <span>状態：</span>
        {{ selectedWorker.isVerified ? "有効" : "無効" }}
      </li>
      <!--      <li>-->
      <!--        <span>：</span>-->
      <!--        aaa@aaaa.com-->
      <!--      </li>-->
      <li>
        <span>ログインID：</span>
        {{ selectedWorker.workerId }}
      </li>
    </ul>
    <button class="closeModal mt-40 submit-button" @click="toEditingMode">
      編集
    </button>
  </div>
</template>

<script lang="ts">
import { useStore } from "@/store";
import { useModal } from "../shared/useModal";
import { defineComponent, onMounted, ref } from "vue";
import { WorkerActionTypes } from "@/store/worker";

export default defineComponent({
  setup(props) {
    const store = useStore();
    const editingMode = ref(false);
    const editingWorkerName = ref(store.state.selectedWorker.name);
    const editingWorkerVerified = ref(store.state.selectedWorker.isVerified);
    const { hideModal } = useModal();
    onMounted(() => {});
    return {
      editingMode,
      editingWorkerName,
      editingWorkerVerified,
      selectedWorker: store.state.selectedWorker,
      toEditingMode: () => (editingMode.value = true),
      hideModal: () => {
        store.dispatch(WorkerActionTypes.DE_SELECT_Worker, undefined);
        hideModal();
      },
      onClickApplyChanges: async () => {
        if (
          editingWorkerVerified.value === store.state.selectedWorker.isVerified
        )
          return (editingMode.value = false);
        const workerId = store.state.selectedWorker.workerId;
        if (editingWorkerVerified.value) {
          await store.dispatch(WorkerActionTypes.ENABLE_Worker, { workerId });
        } else {
          await store.dispatch(WorkerActionTypes.DISABLE_Worker, { workerId });
        }
        hideModal();
      },
    };
  },
});
</script>
