<template>
  <h2>新規項目追加</h2>

  <form @submit.prevent="onClickAdd">
    <ul class="radio-list">
      <li class="mb-20">
        名称：
        <input
          type="text"
          name=""
          id="inputValue"
          v-model="inputValue"
          required
        />
        <div class="form-error-left">{{ errorMessage }}</div>
      </li>
      <li class="mb-20" v-show="showUnitForm">
        単位（任意）：
        <input type="text" name="" id="unitValue" v-model="unitValue" />
      </li>
      <li>
        <input
          type="radio"
          name="radio"
          id="workPlace"
          value="workPlace"
          v-model="selected"
        />
        <label for="workPlace" class="radio">現場</label>
      </li>
      <li>
        <input
          type="radio"
          name="radio"
          id="machine"
          value="machine"
          v-model="selected"
        />
        <label for="machine" class="radio">機材</label>
      </li>
      <li>
        <input
          type="radio"
          name="radio"
          id="workType"
          value="workType"
          v-model="selected"
        />
        <label for="workType" class="radio">作業種別</label>
      </li>
    </ul>
    <button type="submit" class="submit-button">追加</button>
  </form>
</template>

<script lang="ts">
import { useStore } from "@/store";
import { computed, defineComponent, onMounted, ref } from "vue";
import { MachineActionTypes } from "@/store/machine";
import { WorkPlaceActionTypes } from "@/store/workplace";
import { WorkTypeActionTypes } from "@/store/workType";
import logger from "@/loogger";

export default defineComponent({
  setup(props) {
    const store = useStore();
    const selected = ref();
    const errorMessage = ref<string>();
    const inputValue = ref<string>("");
    const unitValue = ref<string>("");
    onMounted(() => {});
    return {
      selected,
      inputValue,
      unitValue,
      showUnitForm: computed(() => selected.value === "workType"),
      errorMessage,
      onClickAdd: async () => {
        errorMessage.value = "";
        if (!inputValue.value || inputValue.value === "") return;
        try {
          switch (selected.value) {
            case "workType":
              await store.dispatch(WorkTypeActionTypes.CREATE_WorkType, {
                name: inputValue.value,
                unit:
                  !unitValue.value || unitValue.value === ""
                    ? null
                    : unitValue.value,
              });
              break;
            case "workPlace":
              await store.dispatch(WorkPlaceActionTypes.CREATE_WorkPlace, {
                workPlaceName: inputValue.value,
              });
              break;
            case "machine":
              await store.dispatch(MachineActionTypes.CREATE_Machine, {
                machineName: inputValue.value,
              });
              break;
            default:
              break;
          }
          inputValue.value = "";
          unitValue.value = "";
        } catch (error) {
          if (error == "duplicated.name") {
            errorMessage.value = "名前が重複しています。";
          } else {
            errorMessage.value = "作成に失敗しました。";
          }
        }
      },
    };
  },
});
</script>
