
import { useStore } from "@/store";
import { useModal } from "../shared/useModal";
import { defineComponent, onMounted, ref } from "vue";
import { WorkerActionTypes } from "@/store/worker";

export default defineComponent({
  setup(props) {
    const store = useStore();
    const editingMode = ref(false);
    const editingWorkerName = ref(store.state.selectedWorker.name);
    const editingWorkerVerified = ref(store.state.selectedWorker.isVerified);
    const { hideModal } = useModal();
    onMounted(() => {});
    return {
      editingMode,
      editingWorkerName,
      editingWorkerVerified,
      selectedWorker: store.state.selectedWorker,
      toEditingMode: () => (editingMode.value = true),
      hideModal: () => {
        store.dispatch(WorkerActionTypes.DE_SELECT_Worker, undefined);
        hideModal();
      },
      onClickApplyChanges: async () => {
        if (
          editingWorkerVerified.value === store.state.selectedWorker.isVerified
        )
          return (editingMode.value = false);
        const workerId = store.state.selectedWorker.workerId;
        if (editingWorkerVerified.value) {
          await store.dispatch(WorkerActionTypes.ENABLE_Worker, { workerId });
        } else {
          await store.dispatch(WorkerActionTypes.DISABLE_Worker, { workerId });
        }
        hideModal();
      },
    };
  },
});
